import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Plus } from "@phosphor-icons/react";
import TopBarDropdown, { LabeledOption } from "../../components/TopBarDropdown";
import { switchBusinessRefetchQueries } from "../../lib/query/refetchQueries";
import {
  UpdateMeInput,
  useBusinessesQuery,
  useUpdateMeMutation,
} from "../../generated/graphql";
import Separator from "../../components/icons/Separator";
import NewBusinessModal from "./team/NewBusinessModal";
import { lighterGreyHex } from "../../lib/constants";
import BusinessIcon from "./BusinessIcon";

export default function BusinessSelector(): React.ReactElement {
  const navigate = useNavigate();
  const { selectedProjectId } = useParams();
  const { data, loading } = useBusinessesQuery();
  const primaryBusiness =
    data && data.primaryBusiness ? data.primaryBusiness.business : null;

  useEffect(() => {
    if (
      primaryBusiness &&
      selectedProjectId &&
      !primaryBusiness.projects.some(
        (project) => project.id === selectedProjectId
      )
    ) {
      // When team changes unselect project.
      navigate({ pathname: "/" })?.catch((error) =>
        console.error("failed to navigate", { error })
      );
    }
  }, [navigate, primaryBusiness, selectedProjectId]);

  const [updateMe] = useUpdateMeMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const typeLookup =
    data && !isLoading
      ? Object.fromEntries(
          data.businesses.map((business) => [business.id, business.type])
        )
      : {};
  const allOptions: LabeledOption<string>[] =
    data && !isLoading
      ? data.businesses.map((business) => ({
          label: business.name,
          value: business.id,
          icon: (
            <BusinessIcon
              businessType={business.type}
              isSelected={false}
              personalImageURL={data.me.imageUrl}
            />
          ),
          selectedIcon: (
            <BusinessIcon
              businessType={business.type}
              isSelected
              personalImageURL={data.me.imageUrl}
            />
          ),
          // TODO: business profile pictures
          showIconWhenSelected:
            business.type === "Personal" && !!data.me.imageUrl,
        }))
      : [];

  const newTeamValue = "new";
  const value =
    primaryBusiness && !isLoading
      ? allOptions.find((option) => option.value === primaryBusiness.id) || null
      : null;

  const businessOptions = allOptions.filter(
    (option) => typeLookup[option.value] === "Business"
  );
  const personalOptions = allOptions.filter(
    (option) => typeLookup[option.value] === "Personal"
  );

  return (
    <>
      {isModalVisible ? (
        <NewBusinessModal
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      ) : null}
      <Separator />
      <TopBarDropdown
        value={value}
        placeholder={isLoading ? "Switching team..." : "Select a team..."}
        isLoading={loading || data === undefined}
        options={{
          type: "groups",
          groups: [
            ...(businessOptions.length > 0
              ? [
                  {
                    label: "Teams",
                    options: businessOptions,
                    newOption: () => {
                      setIsModalVisible(true);
                    },
                  },
                ]
              : []),
            ...(personalOptions.length > 0
              ? [
                  {
                    label: "Personal account",
                    options: personalOptions,
                  },
                ]
              : []),
            ...(businessOptions.length === 0
              ? [
                  {
                    label: "Teams",
                    options: [
                      {
                        label: "New team",
                        value: newTeamValue,
                        icon: (
                          <Plus
                            color={lighterGreyHex}
                            size={16}
                            weight="bold"
                          />
                        ),
                      },
                    ],
                  },
                ]
              : []),
          ],
        }}
        onChange={(option) => {
          if (!option || isLoading) {
            return;
          }
          if (option.value === newTeamValue) {
            setIsModalVisible(true);
            return;
          }
          setIsLoading(true);
          const input: UpdateMeInput = { primaryBusinessId: option.value };
          updateMe({ variables: { input } })
            .then(() => {
              setIsLoading(false);
            })
            .catch((error) => {
              setIsLoading(false);
              console.error("updateMe error:", { error });
            });
        }}
        dropdownStyle={{
          sectionMaxHeight: 151,
          muted: selectedProjectId ? "button" : "none",
        }}
      />
    </>
  );
}
