import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
  pointerWithin,
  CollisionDetection,
  closestCorners,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback, useMemo } from "react";
import { CSS } from "@dnd-kit/utilities";
import { DotsSixVertical } from "@phosphor-icons/react";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

export default function SortableObjectList({
  object,
  setObject,
  includeId,
  renderItemComponent,
  disabled,
}: {
  object: { [id: string]: any };
  setObject: (newObject: { [id: string]: any }) => void;
  includeId?: (id: string) => boolean;
  renderItemComponent: React.FC<{ id: string; dragHandle: React.ReactNode }>;
  disabled?: boolean;
}): React.ReactElement | null {
  const sensors = useSensors(useSensor(PointerSensor));
  const allIds = useMemo(() => Object.keys(object), [object]);
  const ids = useMemo(
    () => (!includeId ? allIds : allIds.filter((item) => includeId(item))),
    [allIds, includeId]
  );

  const isDisabled = disabled || allIds.length !== ids.length || ids.length < 2;

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      document.body.style.cursor = "default";

      if (isDisabled) {
        return;
      }
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = allIds.indexOf(active.id as string);
        const newIndex = allIds.indexOf(over.id as string);

        const newItems = arrayMove(allIds, oldIndex, newIndex);
        setObject(
          Object.fromEntries(newItems.map((key) => [key, object[key]]))
        );
      }
    },
    [setObject, isDisabled, object, allIds]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionAlgorithm}
      onDragStart={() => {
        document.body.style.cursor = "grabbing";
      }}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={ids}
        strategy={verticalListSortingStrategy}
        disabled={isDisabled}
      >
        {ids.map((id, index) => (
          <SortableItem
            key={id}
            id={id}
            index={index}
            renderChildren={renderItemComponent}
            disabled={isDisabled}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export function SortableItem({
  id,
  index,
  renderChildren,
  disabled,
}: {
  id: string;
  index: number;
  renderChildren: React.FC<{
    id: string;
    index: number;
    dragHandle: React.ReactNode;
    firstIndex: number;
  }>;
  disabled?: boolean;
}): React.ReactElement {
  const {
    activeIndex,
    overIndex,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const firstIndex =
    activeIndex === -1 || overIndex === -1 || activeIndex === overIndex
      ? 0
      : activeIndex === 0
        ? 1
        : overIndex === 0
          ? activeIndex
          : 0;

  return (
    <div
      id={id}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      className={
        activeIndex === index ? "z-100 rounded-lg shadow-dropdown" : ""
      }
    >
      {renderChildren({
        id,
        index,
        dragHandle: (
          <span
            {...attributes}
            {...listeners}
            className={
              disabled
                ? "-m-2 p-2"
                : "-m-2 cursor-grab p-2 outline-none active:cursor-grabbing"
            }
            onMouseDown={
              disabled ? undefined : (event) => event.stopPropagation()
            }
          >
            <DotsSixVertical weight="regular" />
          </span>
        ),
        firstIndex,
      })}
    </div>
  );
}

// eslint-disable-next-line func-style
export const collisionDetectionAlgorithm: CollisionDetection = (args) => {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return closestCorners(args);
};
