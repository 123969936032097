import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FunnelStep } from "@hypertune/shared-internal";
import { hash, stableStringify } from "@hypertune/sdk/src/shared";

export const countVariants = ["Unique", "Total"] as const;
export type CountsVariant = (typeof countVariants)[number];

export const hypothesisVariants = ["One-sided", "Two-sided"] as const;
export type HypothesisVariant = (typeof hypothesisVariants)[number];

export type SelectedAnalyticsView = {
  steps?: FunnelStep[];
  funnelStepsJson?: string;
};

export type AnalyticsEditorState = {
  activeStepsHash: string;
  stepsHash: string;
  steps: FunnelStep[];
  hasChanges: boolean;
  showNewViewModal: boolean;
};

const initialState: AnalyticsEditorState = {
  steps: [],
  activeStepsHash: "",
  stepsHash: "",
  hasChanges: false,
  showNewViewModal: false,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setActiveAnalyticsView: (
      draftState,
      action: PayloadAction<FunnelStep[]>
    ) => {
      draftState.activeStepsHash = getStepsHash(action.payload);
      updateSteps(draftState, action.payload);
    },
    resetAnalyticsEditor: (draftState) => {
      draftState.steps = [];
      draftState.activeStepsHash = "";
      draftState.hasChanges = false;
    },
    setFunnelSteps: (draftState, action: PayloadAction<FunnelStep[]>) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updateSteps(draftState, action.payload),
    setShowNewViewModal: (draftState, action: PayloadAction<boolean>) => {
      draftState.showNewViewModal = action.payload;
    },
  },
});

export const {
  setActiveAnalyticsView,
  resetAnalyticsEditor,
  setFunnelSteps,
  setShowNewViewModal,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

function updateSteps(
  draftState: AnalyticsEditorState,
  newSteps: FunnelStep[]
): void {
  const newStepsHash = getStepsHash(newSteps);

  if (newStepsHash !== draftState.stepsHash) {
    console.log("Updating steps", { newSteps });
    draftState.steps = newSteps;
    draftState.stepsHash = newStepsHash;
  }
  draftState.hasChanges = draftState.activeStepsHash !== newStepsHash;
}

function getStepsHash(steps: FunnelStep[]): string {
  return hash(stableStringify(steps)).toString();
}
