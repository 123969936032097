import React, { useCallback, useEffect, useMemo } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import {
  addDays,
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DateTime } from "luxon";
import Skeleton from "react-loading-skeleton";
import { Gauge } from "@phosphor-icons/react";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import {
  BusinessUsageQuery,
  Plan,
  ProjectUsage,
  UsageType,
  useBusinessesQuery,
  useBusinessUsageQuery,
} from "../../generated/graphql";
import BusinessPage from "./BusinessPage";

import Label from "../../components/Label";
import ErrorMessageCard from "../../components/ErrorMessageCard";
import { CustomTimeRanges, TimeRange } from "../../lib/types";
import { useHypertune } from "../../generated/hypertune.react";
import Card from "../../components/Card";
import {
  chartColor1Hex,
  chartColor2Hex,
  chartColors,
  deepRedHex,
  intentDangerHex,
  intentPrimaryHex,
  intentWarningHex,
  mutedGreyHex,
  navyBlueHex,
  yellowHex,
} from "../../lib/constants";
import TimeRangePicker from "../../components/TimeRangePicker";
import TopBarDropdown from "../../components/TopBarDropdown";
import useSearchParamsState from "../../app/useSearchParamsState";

const fromKey = "usage_from";
const toKey = "usage_to";
const monthInMs = 31 * 24 * 60 * 60 * 1000;

export default function UsagePage(): React.ReactElement {
  useEffect(() => {
    document.title = "Usage - Hypertune";
  }, []);

  const hypertune = useHypertune();
  const { loading, error, data } = useBusinessesQuery();
  const invoices = useMemo(
    () =>
      data?.primaryBusiness?.business.plan !== Plan.Free
        ? (data?.primaryBusiness?.business.invoices ?? [])
        : [],
    [
      data?.primaryBusiness?.business.plan,
      data?.primaryBusiness?.business.invoices,
    ]
  );
  const previousBillingPeriodRange: TimeRange | null = useMemo(
    () =>
      invoices &&
      invoices.length > 0 &&
      Date.now() - new Date(invoices[0].end).getTime() < monthInMs
        ? {
            start: startOfDay(new Date(invoices[0].start)),
            end: endOfDay(subDays(new Date(invoices[0].end), 1)),
          }
        : null,
    [invoices]
  );
  const currentBillingPeriodRange: TimeRange | null = useMemo(
    () =>
      previousBillingPeriodRange
        ? {
            start: startOfDay(previousBillingPeriodRange.end),
            end: endOfDay(new Date()),
          }
        : null,
    [previousBillingPeriodRange]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const timeRange: TimeRange = useMemo(
    () => ({
      start: new Date(
        searchParams.get(fromKey) ??
          currentBillingPeriodRange?.start ??
          startOfMonth(new Date())
      ),
      end: new Date(
        searchParams.get(toKey) ??
          currentBillingPeriodRange?.end ??
          endOfDay(new Date())
      ),
    }),
    [searchParams, currentBillingPeriodRange]
  );
  const setTimeRange = useCallback(
    (newTimeRange: TimeRange) => {
      setSearchParams((currentSearchParams) => ({
        ...Object.fromEntries(currentSearchParams),
        [fromKey]: newTimeRange.start.toJSON(),
        [toKey]: newTimeRange.end.toJSON(),
      }));
    },
    [setSearchParams]
  );

  const [selectedProjectIdsString, setSelectedProjectIdsString] =
    useSearchParamsState("selected_projects", "");

  const selectedProjectIds = useMemo(
    () =>
      new Set(
        selectedProjectIdsString ? selectedProjectIdsString.split(",") : []
      ),
    [selectedProjectIdsString]
  );
  const setSelectedProjectIds = useCallback(
    (newIds: Set<string>) => {
      setSelectedProjectIdsString(newIds.values().toArray().join(","));
    },
    [setSelectedProjectIdsString]
  );

  const monthToDateStart = startOfMonth(new Date());
  const monthToDateEnd = endOfDay(new Date());
  const customDataRanges: CustomTimeRanges = useMemo(
    () => [
      ...((previousBillingPeriodRange && currentBillingPeriodRange
        ? [
            {
              label: "Current billing period",
              value: [
                currentBillingPeriodRange.start,
                currentBillingPeriodRange.end,
              ],
            },
            {
              label: "Previous billing period",
              value: [
                previousBillingPeriodRange.start,
                previousBillingPeriodRange.end,
              ],
            },
          ]
        : [
            {
              label: "Month to date",
              value: [monthToDateStart, monthToDateEnd],
            },
            {
              label: "Last month",
              value: [
                startOfMonth(subMonths(new Date(), 1)),
                endOfMonth(subMonths(new Date(), 1)),
              ],
            },
          ]) as CustomTimeRanges),
      {
        label: "Last 7 days",
        value: [subDays(new Date(), 6), new Date()],
      },
      {
        label: "Last 30 days",
        value: [subDays(new Date(), 29), new Date()],
      },
    ],
    [
      currentBillingPeriodRange,
      monthToDateEnd,
      monthToDateStart,
      previousBillingPeriodRange,
    ]
  );
  const showLimits =
    data?.primaryBusiness?.business.plan === "free" &&
    ((timeRange.start.getTime() === monthToDateStart.getTime() &&
      timeRange.end.getTime() === monthToDateEnd.getTime()) ||
      (timeRange.start.getTime() === startOfMonth(timeRange.end).getTime() &&
        timeRange.end.getTime() === endOfMonth(timeRange.start).getTime()));

  return (
    <BusinessPage>
      {loading ? (
        <UsageView
          showLimits={showLimits}
          projects={data?.primaryBusiness?.business.projects ?? null}
          selectedProjectIds={selectedProjectIds}
          setSelectedProjectIds={setSelectedProjectIds}
          customDataRanges={customDataRanges}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          chartData={null}
          plan={null}
        />
      ) : error ? (
        <ErrorMessageCard error={error} />
      ) : data ? (
        !hypertune.showUsagePage({ fallback: false }) ? (
          <Navigate to="/" />
        ) : !data.primaryBusiness ? (
          <Label type="small-body" className="pt-[9.5px] text-tx-muted">
            No team selected.
          </Label>
        ) : (
          <Usage
            showLimits={showLimits}
            businessId={data.primaryBusiness.id}
            plan={data.primaryBusiness.business.plan}
            projects={data.primaryBusiness.business.projects}
            selectedProjectIds={selectedProjectIds}
            setSelectedProjectIds={setSelectedProjectIds}
            customDataRanges={customDataRanges}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
          />
        )
      ) : null}
    </BusinessPage>
  );
}

function Usage({
  showLimits,
  businessId,
  plan,
  projects,
  selectedProjectIds,
  setSelectedProjectIds,
  customDataRanges,
  timeRange,
  setTimeRange,
}: {
  showLimits: boolean;
  businessId: string;
  plan: Plan;
  projects: { id: string; name: string }[];
  selectedProjectIds: Set<string>;
  setSelectedProjectIds: (newIds: Set<string>) => void;
  customDataRanges: CustomTimeRanges;
  timeRange: TimeRange;
  setTimeRange: (newTimeRange: TimeRange) => void;
}): React.ReactElement {
  const hypertune = useHypertune();
  const hashWeight = hypertune.features().hashRequestWeight({ fallback: 0.1 });
  const { data, loading, error } = useBusinessUsageQuery({
    variables: {
      input: {
        businessId,
        start: timeRange.start.toJSON(),
        end: timeRange.end.toJSON(),
      },
    },
  });

  if (error) {
    return <ErrorMessageCard error={error} />;
  }

  const chartData =
    data?.businessUsage && !loading
      ? usageToChartData(
          hashWeight,
          timeRange,
          selectedProjectIds,
          data.businessUsage
        )
      : null;

  console.debug("Usage data", { usage: data?.businessUsage ?? [], chartData });

  return (
    <UsageView
      showLimits={showLimits}
      plan={plan}
      projects={projects}
      selectedProjectIds={selectedProjectIds}
      setSelectedProjectIds={setSelectedProjectIds}
      customDataRanges={customDataRanges}
      timeRange={timeRange}
      setTimeRange={setTimeRange}
      chartData={chartData}
    />
  );
}

function UsageView({
  showLimits,
  projects,
  plan,
  selectedProjectIds,
  setSelectedProjectIds,
  customDataRanges,
  timeRange,
  setTimeRange,
  chartData: baseChartData,
}: {
  showLimits: boolean;
  plan: Plan | null;
  projects: { id: string; name: string }[] | null;
  selectedProjectIds: Set<string>;
  setSelectedProjectIds: (newIds: Set<string>) => void;
  customDataRanges: CustomTimeRanges;
  timeRange: TimeRange;
  setTimeRange: (newTimeRange: TimeRange) => void;
  chartData: ChartValue[] | null;
}): React.ReactElement {
  const hypertune = useHypertune();
  const content = hypertune.content().usage();

  const limits = hypertune.features().planLimits({ args: { plan } });
  const billedRequestsLimit = limits.billedRequestCount({ fallback: -1 });
  const analyticsEventsLimit = limits.analyticsEventsCount({ fallback: -1 });

  const chartData =
    baseChartData?.map((data) => ({
      ...data,
      billedRequestsLimit,
      analyticsEventsLimit,
    })) ?? null;

  const showBilledRequestsLimit =
    showLimits && billedRequestsLimit > 0 && chartData && chartData.length > 0;
  const showBilledRequestsLimitLine =
    showBilledRequestsLimit &&
    billedRequestsLimit / 2 < chartData[chartData.length - 1].requests;

  const showAnalyticsEventsLimit =
    showLimits && analyticsEventsLimit > 0 && chartData && chartData.length > 0;
  const showAnalyticsEventsLimitLine =
    showAnalyticsEventsLimit &&
    analyticsEventsLimit / 2 < chartData[chartData.length - 1].analyticsEvents;

  const requestsExtraBars = chartData
    ? (["js", "graphQL", "codegen"] as (keyof ChartValue)[]).filter((key) =>
        chartData.some((value) => value[key])
      )
    : [];
  const lastValue =
    chartData && chartData.length > 0 ? chartData[chartData.length - 1] : null;

  return (
    <>
      <div className="mb-5 flex flex-row justify-between">
        <Label type="title1">Usage</Label>
        <div className="flex flex-row items-center gap-2">
          <ProjectSelect
            projects={projects}
            selectedProjectIds={selectedProjectIds}
            setSelectedProjectIds={setSelectedProjectIds}
          />
          <div className="rounded-lg border bg-white">
            <TimeRangePicker
              range={timeRange}
              setRange={setTimeRange}
              placement="bottomEnd"
              customDateRanges={customDataRanges}
            />
          </div>
        </div>
      </div>

      <BarChartCard
        title={content.billedRequests().name({ fallback: "Billed requests" })}
        description={content.billedRequests().description({ fallback: "" })}
        data={chartData}
        total={lastValue ? lastValue.requests : undefined}
        limit={showBilledRequestsLimit ? billedRequestsLimit : undefined}
      >
        <Bar
          yAxisId="left"
          name="Inits"
          dataKey="init"
          stackId="a"
          fill={chartColor1Hex}
        />
        <Bar
          yAxisId="left"
          name="Hash"
          dataKey="hashValue"
          stackId="a"
          fill={chartColor2Hex}
        />
        {requestsExtraBars.map((key, index) => (
          <Bar
            yAxisId="left"
            name={toStartCase(key)}
            dataKey={key}
            stackId="a"
            fill={chartColors[index + 2]}
          />
        ))}
        <Line
          yAxisId="right"
          name="Total"
          type="monotone"
          dataKey="requests"
          stroke={navyBlueHex}
          dot={false}
        />
        {showBilledRequestsLimitLine && (
          <Line
            yAxisId="right"
            name="Limit"
            type="monotone"
            dataKey="billedRequestsLimit"
            stroke={deepRedHex}
            dot={false}
            tooltipType="none"
          />
        )}
      </BarChartCard>

      <BarChartCard
        title={content.analyticsEvents().name({ fallback: "Analytics events" })}
        description={content.analyticsEvents().description({ fallback: "" })}
        data={chartData}
        total={lastValue ? lastValue.analyticsEvents : undefined}
        limit={showAnalyticsEventsLimit ? analyticsEventsLimit : undefined}
      >
        <Bar
          yAxisId="left"
          name="Exposures"
          dataKey="exposures"
          stackId="a"
          fill={chartColor1Hex}
        />
        <Bar
          yAxisId="left"
          name="Events"
          dataKey="events"
          stackId="a"
          fill={chartColor2Hex}
        />
        <Line
          yAxisId="right"
          name="Total"
          type="monotone"
          dataKey="analyticsEvents"
          stroke={navyBlueHex}
          dot={false}
        />
        {showAnalyticsEventsLimitLine && (
          <Line
            yAxisId="right"
            name="Limit"
            type="monotone"
            dataKey="analyticsEventsLimit"
            stroke={deepRedHex}
            dot={false}
            tooltipType="none"
          />
        )}
      </BarChartCard>

      <BarChartCard
        title={content
          .initRequests()
          .name({ fallback: "Initialization requests" })}
        description={content.initRequests().description({ fallback: "" })}
        data={chartData}
        total={lastValue ? lastValue.allInitRequests : undefined}
      >
        <Bar
          yAxisId="left"
          name="Inits"
          dataKey="init"
          stackId="a"
          fill={intentPrimaryHex}
        />
        {requestsExtraBars.map((key, index) => (
          <Bar
            yAxisId="left"
            name={toStartCase(key)}
            dataKey={key}
            stackId="a"
            fill={chartColors[index + 1]}
          />
        ))}
        <Line
          yAxisId="right"
          name="Total"
          type="monotone"
          dataKey="allInitRequests"
          stroke={navyBlueHex}
          dot={false}
        />
      </BarChartCard>

      <BarChartCard
        title={content.hashRequests().name({ fallback: "Hash requests" })}
        description={content.hashRequests().description({ fallback: "" })}
        data={chartData}
        total={lastValue ? lastValue.allHashRequests : undefined}
      >
        <Bar
          yAxisId="left"
          name="Hash"
          dataKey="hash"
          stackId="a"
          fill={chartColor1Hex}
        />
        <Line
          yAxisId="right"
          name="Total"
          type="monotone"
          dataKey="allHashRequests"
          stroke={navyBlueHex}
          dot={false}
        />
      </BarChartCard>
    </>
  );
}

function ProjectSelect({
  projects,
  selectedProjectIds,
  setSelectedProjectIds,
}: {
  projects: { id: string; name: string }[] | null;
  selectedProjectIds: Set<string>;
  setSelectedProjectIds: (newIds: Set<string>) => void;
}): React.ReactElement | null {
  const projectIdToName = projects
    ? Object.fromEntries(projects.map((project) => [project.id, project.name]))
    : {};
  return (
    <TopBarDropdown
      value={{
        label:
          selectedProjectIds.size > 0
            ? selectedProjectIds
                .values()
                .map((id) => projectIdToName[id])
                .toArray()
                .join(", ")
            : "Filter by project",
        value: "",
      }}
      placeholder=""
      options={{
        type: "options",
        options:
          projects?.map((project) => ({
            value: project.id,
            label: project.name,
            isSelected: selectedProjectIds.has(project.id),
          })) ?? [],
      }}
      onChange={(selectedLabel) => {
        if (!selectedLabel) {
          return;
        }
        const updatedSet = new Set(selectedProjectIds);
        if (updatedSet.has(selectedLabel.value)) {
          updatedSet.delete(selectedLabel.value);
        } else {
          updatedSet.add(selectedLabel.value);
        }
        setSelectedProjectIds(updatedSet);
      }}
      dropdownStyle={{
        hideSearch: false,
        buttonClassName: `font-medium disableBgWhenSelected text-tx-default bg-white border rounded-lg py-[6.25px] max-w-[250px]`,
        panelClassName: "pt-1 data-top:pb-1 ml-3",
        muted: "all",
        caret: "filter",
        alignment: "end",
      }}
      multiSelect
      isLoading={projects === null}
    />
  );
}

function BarChartCard({
  title,
  description,
  data,
  children,
  total,
  limit,
}: {
  title: string;
  description: string;
  data: { name: string }[] | null;
  children: React.ReactNode;
  total?: number;
  limit?: number;
}): React.ReactElement {
  const hypertune = useHypertune();
  const content = hypertune.content().usage();
  const color =
    total !== undefined && limit
      ? total / limit >= content.errorThresholdPercentage({ fallback: 0 })
        ? intentDangerHex
        : total / limit >=
            hypertune.features().warningThresholdPercentage({ fallback: 0.75 })
          ? intentWarningHex
          : total / limit >=
              hypertune
                .features()
                .earlyWarningThresholdPercentage({ fallback: 0.5 })
            ? yellowHex
            : mutedGreyHex
      : mutedGreyHex;

  return (
    <Card className="mb-10" layout="none">
      <div className="mb-5">
        <Label type="title1" className={description ? "mb-1" : "mb-5"}>
          {title}
        </Label>
        {description && <p className="text-tx-muted">{description}</p>}
        {total !== undefined && (
          <div className="mt-2 flex flex-row items-center gap-[6px]">
            <Gauge color={color} className="mt-[2px]" />
            <p className="whitespace-nowrap text-md" style={{ color }}>
              {total !== undefined ? Math.floor(total).toLocaleString() : ""}
              {limit ? ` / ${limit.toLocaleString()}` : ""}
            </p>
          </div>
        )}
      </div>
      {data ? (
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              yAxisId="left"
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {children}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Skeleton height={400} />
      )}
    </Card>
  );
}

function CustomTooltip({
  active,
  payload,
  label,
}: {
  active?: boolean;
  payload?: {
    name: string;
    fill?: string;
    stroke?: string;
    value: number;
    type: undefined | "none";
  }[];
  label?: string;
}): React.ReactElement | null {
  if (!active || !payload) {
    return null;
  }

  return (
    <div className="rounded-lg border border-bd-darker bg-white p-3">
      <p>
        <strong>{label}</strong>
      </p>
      {payload
        .filter(({ type }) => type !== "none")
        .map(({ name, value, fill, stroke }) => (
          <p
            key={`tooltip-item-${name}-${value}`}
            style={{ color: fill === "#fff" ? stroke : fill }}
          >
            {name}: <strong>{Math.floor(value).toLocaleString()}</strong>
          </p>
        ))}
    </div>
  );
}

type ChartValue = {
  name: string;
  init: number;
  hash: number;
  hashValue: number;
  js: number;
  graphQL: number;
  codegen: number;

  // Totals
  requests: number;
  analyticsEvents: number;
  allHashRequests: number;
  allInitRequests: number;
};

function usageToChartData(
  hashWeight: number,
  timeRange: TimeRange,
  selectedProjectIds: Set<string>,
  usage: NonNullable<BusinessUsageQuery["businessUsage"]>
): ChartValue[] {
  const dateToProjectUsages = usage
    .filter(
      ({ projectId }) =>
        selectedProjectIds.size === 0 || selectedProjectIds.has(projectId)
    )
    .reduce<{
      [date: string]: ProjectUsage[];
    }>((previous, current) => {
      const date = getDateString(new Date(current.occurredOn));

      if (!previous[date]) {
        previous[date] = [];
      }
      previous[date].push(current);

      return previous;
    }, {});

  const showYear =
    timeRange.start.getFullYear() !== timeRange.end.getFullYear();
  const numDays = Math.round(
    (timeRange.end.getTime() - timeRange.start.getTime()) /
      (24 * 60 * 60 * 1000)
  );

  let requests = 0;
  let allHashRequests = 0;
  let allInitRequests = 0;
  let analyticsEvents = 0;

  return new Array(numDays).fill(0).map((_, index) => {
    const date = startOfDay(addDays(timeRange.start, index));
    const dateString = getDateString(date);
    const projectUsages = dateToProjectUsages[dateString];
    const name = DateTime.fromJSDate(date).toFormat(
      showYear ? "dd LLL yyyy" : "dd LLL"
    );

    if (!projectUsages) {
      console.warn(`No project usages found for date ${dateString}`);
      return {
        name,
        requests,
        analyticsEvents,
        allHashRequests,
        allInitRequests,
        events: 0,
        exposures: 0,
        hash: 0,
        hashValue: 0,
        init: 0,
        js: 0,
        graphQL: 0,
        codegen: 0,
      };
    }

    const init = sumUsageForType(projectUsages, UsageType.InitRequests);
    const js = sumUsageForType(projectUsages, UsageType.JsRequests);
    const graphQL = sumUsageForType(projectUsages, UsageType.GraphQlRequests);
    const codegen = sumUsageForType(projectUsages, UsageType.CodegenRequests);

    const hash = sumUsageForType(projectUsages, UsageType.HashRequests);
    const hashValue = hashWeight * hash;

    const events = sumUsageForType(projectUsages, UsageType.Events);
    const exposures = sumUsageForType(projectUsages, UsageType.Exposures);

    allHashRequests += hash;
    allInitRequests += init + js + graphQL + codegen;
    requests += init + js + graphQL + codegen + hashValue;
    analyticsEvents += events + exposures;

    return {
      name,
      requests,
      analyticsEvents,
      allHashRequests,
      allInitRequests,
      events,
      exposures,
      hash,
      hashValue,
      init,
      js,
      graphQL,
      codegen,
    };
  });
}

function getDateString(date: Date): string {
  return startOfDay(date).toJSON();
}

function sumUsageForType(data: ProjectUsage[], usageType: UsageType): number {
  return data.reduce((total, projectUsage) => {
    return (
      total +
      projectUsage.values.reduce((value, usage) => {
        if (usage.type !== usageType) {
          return value;
        }
        return value + usage.value;
      }, 0)
    );
  }, 0);
}
