import { ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BusinessesQuery } from "../../../generated/graphql";
import ProjectCardContent from "./ProjectCardContent";
import Label from "../../../components/Label";
import matchesSearch from "../../../lib/generic/matchesSearch";
import { showSetupModalParamName } from "../../project/setup/useShowSetupModal";
import CardGroup, { CardGroupItem } from "../../../components/CardGroup";
import { canEditBusiness } from "../../../lib/query/rolePermissions";
import { useHypertune } from "../../../generated/hypertune.react";
import { getProjectUrl } from "../../project/projectHooks";

export default function ProjectsList({
  searchText,
  showList,
  data,
  readOnly,
}: {
  searchText: string;
  showList: boolean;
  data?: BusinessesQuery;
  readOnly: boolean;
}): ReactElement {
  const content = useHypertune().content();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const canDeleteOrTransferProjects = canEditBusiness(
    data?.primaryBusiness?.role
  );

  if (data && !data.primaryBusiness) {
    return (
      <Label type="small-body" className="text-tx-muted">
        No team selected.
      </Label>
    );
  }

  const showShare =
    !!data &&
    !!data.primaryBusiness &&
    data.primaryBusiness.business.type === "Personal" &&
    data.businesses.length === 1;

  const projectCards = data?.primaryBusiness
    ? data.primaryBusiness?.business.projects
        .filter((project) => matchesSearch(searchText, [project.name]))
        .map(
          (project): CardGroupItem => ({
            key: project.id,
            onClick: (): void => {
              navigate(
                getProjectUrl({
                  projectId: project.id,
                  searchParams: new URLSearchParams({
                    ...Object.fromEntries(searchParams),
                    [showSetupModalParamName]:
                      searchParams.get(showSetupModalParamName) || "0",
                  }),
                  selectedView: "logic",
                })
              )?.catch((error) =>
                console.error("failed to navigate", { error })
              );
            },
            children: (
              <ProjectCardContent
                readOnly={readOnly}
                showShare={showShare}
                showDeleteAndTransfer={canDeleteOrTransferProjects}
                projectId={project.id}
                projectName={project.name}
                lastCommit={project.defaultBranch.activeCommit.createdAt}
              />
            ),
          })
        )
    : [];

  return (
    <>
      {data?.primaryBusiness?.business.projects.length === 0 ? (
        <Label type="small-body" className="text-tx-muted">
          {data && !data.primaryBusiness
            ? "No team selected."
            : data?.primaryBusiness?.business.projects.length === 0
              ? content.homepage().noProjectsMessage({ fallback: "" })
              : "No search matches."}
        </Label>
      ) : null}
      <CardGroup
        className={showList ? undefined : "grid-cols-1 gap-5 lg:grid-cols-2"}
        layout={showList ? "list" : "grid"}
        loadingCount={data?.primaryBusiness ? undefined : 4}
        cardLayout="horizontal-with-icon"
        cards={projectCards}
      />
    </>
  );
}
