import { LinkSimple } from "@phosphor-icons/react";
import { blueHex } from "../lib/constants";

export function Dot({
  className,
  selected,
  visible,
}: {
  className: string;
  selected: boolean;
  visible: boolean;
}): React.ReactElement | null {
  return (
    <div
      className={`z-10 ${
        !visible
          ? ""
          : selected
            ? "border-2 border-base-blue shadow-progress-dot"
            : "border border-base-grey-2-medium"
      } h-[10px] w-[10px] rounded-full bg-white ${className}`}
    />
  );
}

export function Line({
  className,
  selected,
}: {
  className: string;
  selected: boolean;
}): React.ReactElement | null {
  return (
    <div
      className={`border border-dashed ${
        selected ? "border-base-blue" : "border-base-grey-2-medium"
      } flex-grow bg-white ${className}`}
    />
  );
}

export function LinkIcon({
  className,
  visible,
}: {
  className: string;
  visible: boolean;
}): React.ReactElement | null {
  if (!visible) {
    return <div className="h-[34px] w-[34px]" />;
  }
  return (
    <div
      className={`z-20 rounded-xl border-2 border-base-blue bg-white p-2 shadow-inputs-lg ${className}`}
    >
      <LinkSimple weight="bold" color={blueHex} />
    </div>
  );
}
