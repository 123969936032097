import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import CollapseButton from "../../../components/buttons/CollapseButton";
import twMerge from "../../../lib/twMerge";

export default function DiffContainer({
  header,
  left,
  right,
  isSelected,
}: {
  header: React.ReactNode;
  left: React.ReactNode;
  right: React.ReactNode;
  isSelected?: boolean;
}): React.ReactElement | null {
  return (
    <DiffContainerBase
      header={header}
      contentClassName="grid grid-cols-2 divide-x"
      isSelected={isSelected}
    >
      <div className="col-span-1">
        <div className="flex h-full overflow-auto py-2 pr-2">{left}</div>
      </div>
      <div className="col-span-1">
        <div className="flex h-full overflow-auto py-2 pl-2">{right}</div>
      </div>
    </DiffContainerBase>
  );
}
export function DiffContainerBase({
  header,
  children,
  contentClassName,
  isSelected,
}: {
  header: React.ReactNode;
  children: React.ReactNode;
  contentClassName?: string;
  isSelected?: boolean;
}): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <DiffContainerBaseInternal
      headerClassName="cursor-pointer"
      headerOnClick={() => setIsOpen(!isOpen)}
      header={
        <>
          <CollapseButton isOpen={isOpen} setOpen={setIsOpen} />
          {header}
        </>
      }
      contentClassName={contentClassName}
      isSelected={isSelected}
      isOpen={isOpen}
    >
      {children}
    </DiffContainerBaseInternal>
  );
}

function DiffContainerBaseInternal({
  header,
  headerOnClick,
  headerClassName,
  children,
  contentClassName,
  isSelected,
  isOpen,
}: {
  header: React.ReactNode;
  headerOnClick?: () => void;
  headerClassName?: string;
  children: React.ReactNode;
  contentClassName?: string;
  isSelected?: boolean;
  isOpen: boolean;
}): React.ReactElement | null {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      // TODO: setting behavior to smooth breaks the scrolling for some reason.
      // So we should figure out why that's the case and switch then use it again.
      ref.current.scrollIntoView({
        block: "start",
        inline: "nearest",
      });
    }
  }, [isSelected]);
  return (
    <div
      ref={ref}
      className={`flex w-full scroll-mt-4 flex-col rounded-lg border border-bd-darker bg-white shadow-sm outline ${isSelected ? "outline-intent-primary" : "outline-transparent"}`}
    >
      <div
        className={twMerge(
          "flex cursor-pointer select-none flex-row items-center gap-2 p-2",
          headerClassName
        )}
        onClick={headerOnClick}
      >
        {header}
      </div>
      <div
        className={twMerge(
          "divide-x border-t border-bd-darker px-2",
          contentClassName,
          !isOpen && "hidden"
        )}
      >
        {children}
      </div>
    </div>
  );
}

DiffContainer.LoadingSkeleton = function (): React.ReactElement {
  return (
    <DiffContainerBaseInternal
      header={<Skeleton height={13} width={300} />}
      contentClassName="py-2"
      isOpen
    >
      <Skeleton height={40} width="100%" />
    </DiffContainerBaseInternal>
  );
};
