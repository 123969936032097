import { useCallback, useState } from "react";
import sanitizeBranchName from "@hypertune/shared-internal/src/sanitizeBranchName";
import Modal from "../../../components/Modal";
import {
  ProjectDocument,
  ProjectQuery,
  useCreateBranchMutation,
} from "../../../generated/graphql";
import TextInput from "../../../components/input/TextInput";
import StructuredErrorMessage from "../../../components/StructuredErrorMessage";

const nameRegex = /^[a-zA-Z0-9./_-]+$/;

export default function NewBranchModal({
  projectId,
  baseBranchName,
  branches,
  onClose,
}: {
  projectId: string;
  baseBranchName: string;
  branches: ProjectQuery["project"]["branches"];
  onClose: (newBranchName?: string) => void;
}): React.ReactElement | null {
  const [createBranch, { loading }] = useCreateBranchMutation();
  const [name, setName] = useState("");

  const nameIsValid = nameRegex.test(name);
  const sanitizedBranchName = sanitizeBranchName(name);
  const nameExists = branches.some(
    (branch) =>
      branch.name === name ||
      sanitizeBranchName(branch.name) === sanitizedBranchName
  );
  const checks = [
    {
      ok: !nameExists,
      message: "Branch name is unique.",
    },
    {
      ok: !name || nameIsValid,
      message:
        "No special characters except dots, forward slashes, hyphens and underscores.",
    },
  ];
  const hasError = checks.some((check) => !check.ok);

  const onSave = useCallback(async () => {
    if (!name || hasError || loading) {
      return;
    }
    await createBranch({
      variables: {
        input: {
          name,
          projectId,
          baseBranchName,
        },
      },
      refetchQueries: [ProjectDocument],
      awaitRefetchQueries: true,
    }).then(() => {
      onClose(name);
    });
  }, [
    createBranch,
    loading,
    onClose,
    projectId,
    baseBranchName,
    name,
    hasError,
  ]);

  return (
    <Modal
      closeOnEsc
      onClose={() => onClose()}
      title="Create new branch"
      saveIntent="primary"
      saveWeight="filled"
      saveText="Create"
      saveLoading={loading}
      onSave={onSave}
    >
      <div className="mb-2 text-sm font-semibold text-tx-default">
        Branch name
      </div>
      <TextInput
        placeholder="Enter a name for this branch"
        value={name}
        onChange={setName}
        trimOnBlur
        focusOnMount
        onEnter={onSave}
        readOnly={false}
        error={hasError && <StructuredErrorMessage checks={checks} />}
      />
    </Modal>
  );
}
