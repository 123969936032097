import { Expression, VariableExpression } from "@hypertune/sdk/src/shared";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import { normal } from "../../../../lib/constants";
import { ExpressionControlContext } from "../../../../lib/types";
import VariableNameControl from "./VariableNameControl";
import ExpressionControl from "./ExpressionControl";
import GoToArrowButton from "../../../../components/buttons/GoToArrowButton";

export default function VariableExpressionControl({
  context,
  variables,
  setVariableName,
  expression,
  parentExpression,
  optionsButton,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  expression: VariableExpression;
  parentExpression: Expression | null;
  optionsButton: React.ReactNode;
}): React.ReactElement {
  const variable = variables[expression.variableId];
  const groupIndex = context.variableGroupIndex ?? 0;
  const showGoToArrowButton = context.setSelectedFieldPath && variable.path;
  const showOptionsButton =
    optionsButton &&
    !(parentExpression && parentExpression.type === "GetFieldExpression");

  return (
    <div
      className={`flex h-[30px] flex-row items-center rounded-lg border border-base-pink/15 bg-white py-2 pl-[6px] ${showGoToArrowButton || showOptionsButton ? "pr-9" : "pr-2"} text-base-pink focus:border-base-pink focus:shadow-variable-inputs-sm`}
    >
      {variable ? (
        <>
          <VariableNameControl
            groupIndex={groupIndex}
            style={{ margin: "2.75px 0 2.75px 0" }}
            schema={context.commitContext.schema}
            variable={variable}
            variableValue={
              variable.value && (
                <ExpressionControl
                  context={{
                    ...context,
                    readOnly: true,
                    hideOptions: true,
                    variableGroupIndex: groupIndex + 1,
                    setSelectedFieldPath: undefined,
                  }}
                  variables={variables}
                  setVariableName={{}}
                  valueTypeConstraint={{ type: "AnyValueTypeConstraint" }}
                  expression={variable.value}
                  setExpression={() => {
                    // noop
                  }}
                  lift={() => {
                    // noop
                  }}
                  parentExpression={null}
                  includeExpressionOption={() => false}
                />
              )
            }
            setVariableName={setVariableName[variable.id]}
            // Always readonly, as being able to edit a variable reference
            // does not mean we can actually edit the variable definition
            readOnly
          />
          {showGoToArrowButton ? (
            <GoToArrowButton
              onClick={() => context.setSelectedFieldPath?.(variable.path!)}
              type="variable"
            />
          ) : showOptionsButton ? (
            <>
              <div style={{ flexGrow: 1, minWidth: normal }} />
              {optionsButton}
            </>
          ) : null}
        </>
      ) : (
        "Invalid Variable"
      )}
    </div>
  );
}
