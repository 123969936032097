import { ArrowClockwise } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import getErrorMessage from "../lib/query/getErrorMessage";
import Card from "./Card";
import Label from "./Label";
import Button from "./buttons/Button";
import { intentPrimaryHex } from "../lib/constants";

export default function ErrorMessageCard({
  error,
  action = "reload",
  className,
}: {
  error: Error | string;
  action?: "reload" | "home";
  className?: string;
}): React.ReactElement | null {
  const navigate = useNavigate();
  return (
    <Card layout="horizontal" className={className}>
      <Label type="title3">
        Error: {typeof error === "string" ? error : getErrorMessage(error)}
      </Label>
      <Button
        icon={
          action === "reload" ? (
            <ArrowClockwise color={intentPrimaryHex} weight="regular" />
          ) : undefined
        }
        text={action === "reload" ? "Reload" : "Go home"}
        intent="primary"
        weight="outlined"
        onClick={async () => {
          if (action === "reload") {
            window.location.reload();
            return;
          }
          await navigate("/");
        }}
      />
    </Card>
  );
}
