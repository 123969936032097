import { IconWeight, WarningCircle } from "@phosphor-icons/react";
import { Intent, intentToHexColor } from "../intent";

export default function ErrorCircle({
  size,
  weight = "regular",
  intent = "danger",
  style,
}: {
  size?: number;
  weight?: IconWeight;
  intent?: Intent;
  style?: React.CSSProperties;
}): React.ReactElement {
  return (
    <WarningCircle
      size={size || 14.5}
      color={intentToHexColor(intent)}
      weight={weight}
      style={style}
      className="flex-shrink-0"
    />
  );
}
