import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import { dotSymbol, draftCommitId } from "../../../lib/constants";
import { ProjectBranchQuery } from "../../../generated/graphql";
import { formatJsonTime } from "../../../lib/generic/formatDate";

export default function CommitSelector({
  muted,
  includeDraft,
  branch,
  selectedCommitId,
  setSelectedCommitId,
  buttonClassName,
}: {
  muted: boolean;
  includeDraft: boolean;
  branch?: ProjectBranchQuery["projectBranch"];
  selectedCommitId: string | null;
  setSelectedCommitId: (newCommitId: string) => void;
  buttonClassName?: string;
}): React.ReactElement | null {
  const commitOptions: LabeledOption<string>[] = [
    ...(includeDraft
      ? [
          {
            value: draftCommitId,
            label: "Draft",
          },
        ]
      : []),
    ...[...(branch?.commits || [])]
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .map((commit) => toDropdownOption(branch?.activeCommit.id, commit)),
  ];
  const value = !selectedCommitId
    ? null
    : commitOptions.find((option) => selectedCommitId === option.value) || null;

  return (
    <TopBarDropdown<string>
      options={{
        type: "options",
        options: commitOptions,
      }}
      value={value}
      isLoading={!branch}
      onChange={(option) => {
        if (option) {
          setSelectedCommitId(option.value);
        }
      }}
      placeholder="Select commit..."
      dropdownStyle={{
        muted: muted ? "button" : "none",
        sectionMaxHeight: 324,
        buttonClassName,
      }}
    />
  );
}

function toDropdownOption(
  activeCommitId: string | undefined,
  commit: ProjectBranchQuery["projectBranch"]["commits"][number]
): LabeledOption<string> {
  const isActive = commit.id === activeCommitId;
  const subtitle = `${
    isActive ? `Active ${dotSymbol} ` : ""
  }${formatJsonTime(commit.createdAt)} ${dotSymbol} ${commit.author.displayName}`;

  return { value: commit.id, label: commit.message, subtitle };
}
