import { format } from "date-fns";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import {
  LogLevel,
  LogsInput,
  LogsQuery,
  LogType,
  useLogsQuery,
} from "../../generated/graphql";
import { darkGreyHex, queryPollIntervalMs } from "../../lib/constants";
import { LogsEditorState } from "../../lib/types";
import CodeEditor from "../../components/CodeEditor";
import CloseButton from "../../components/buttons/CloseButton";
import ErrorCircle from "../../components/icons/ErrorCircle";
import SuccessCircle from "../../components/icons/SuccessCircle";

function LogsEditor({
  isVisible,
  projectId,
  commitId,
  logsEditorState,
  setLogsEditorState,
}: {
  isVisible: boolean;
  projectId: string;
  commitId: string | null;
  logsEditorState: LogsEditorState;
  setLogsEditorState: (newLogsEditorState: LogsEditorState) => void;
}): React.ReactElement {
  const { loading, data, fetchMore } = useLogsQuery({
    variables: { input: { projectId, commitId } },
    fetchPolicy: "no-cache",
  });

  const { selectedLogId } = logsEditorState;
  function setSelectedLogId(newSelectedLogId: string | null): void {
    setLogsEditorState({
      ...logsEditorState,
      selectedLogId: newSelectedLogId,
    });
  }

  const [hoveredLogId, setHoveredLogId] = React.useState<string | null>(null);

  const selectedLog = data
    ? data.logs.find((log) => log.id === selectedLogId)
    : undefined;

  useEffect(() => {
    // No need to poll for logs if not viewing them
    if (!isVisible) {
      return;
    }

    const intervalId = setInterval(async () => {
      if (!data) {
        return;
      }
      const fetchMoreInput: LogsInput = {
        projectId,
        commitId,
        afterLogId: data.logs.length > 0 ? data.logs[0].id : null,
      };
      await fetchMore({ variables: { input: fetchMoreInput } });
    }, queryPollIntervalMs);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [projectId, commitId, data, fetchMore, isVisible]);

  const detailPanel = selectedLog ? (
    <div className="col-span-1 flex-col border-l border-bd-darker px-3 py-2">
      <div className="mb-2 flex flex-row items-center">
        <CloseButton
          className="mr-2"
          onClick={() => {
            setSelectedLogId(null);
          }}
        />
        <div style={{ fontWeight: "bold" }}>Log Details</div>
      </div>
      <div className="flexGrow overflow-y-auto">
        <CodeEditor
          code={JSON.stringify(selectedLog, null, 2)}
          setCode={() => {
            // Dummy
          }}
          language="json"
          padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          readOnly
        />
      </div>
    </div>
  ) : null;

  return (
    <div
      className={`${isVisible ? "grid" : "hidden"} ${detailPanel ? "grid-cols-2" : "grid-cols-1"} min-h-0 items-start`}
      onMouseOut={() => {
        setHoveredLogId(null);
      }}
    >
      <div className="col-span-1 flex flex-col overflow-y-auto overflow-x-hidden">
        {loading ? (
          <LogsEditor.LoadingSkeleton />
        ) : data ? (
          data.logs.length === 0 ? (
            <div className="px-3 py-2">No logs.</div>
          ) : (
            data.logs.map((log) => (
              <LogRow
                key={log.id}
                log={log}
                selectedLogId={selectedLogId}
                setSelectedLogId={setSelectedLogId}
                hoveredLogId={hoveredLogId}
                setHoveredLogId={setHoveredLogId}
              />
            ))
          )
        ) : null}
      </div>
      {detailPanel}
    </div>
  );
}

function LogRow({
  log,
  selectedLogId,
  setSelectedLogId,
  hoveredLogId,
  setHoveredLogId,
}: {
  log: LogsQuery["logs"][number];
  selectedLogId: string | null;
  setSelectedLogId: (newSelectedLogId: string) => void;
  hoveredLogId: string | null;
  setHoveredLogId: (newHoveredLogId: string) => void;
}): React.ReactElement {
  const isSelected = log.id === selectedLogId;
  const isHovered = log.id === hoveredLogId;
  const createdAt = new Date(log.createdAt);

  return (
    <div
      className={`flex cursor-pointer flex-row items-center whitespace-nowrap px-[6px] py-1 ${
        isSelected ? "bg-bg-hover" : isHovered ? "bg-bg-pressed" : ""
      }`}
      onClick={() => {
        setSelectedLogId(log.id);
      }}
      onMouseOver={() => {
        setHoveredLogId(log.id);
      }}
    >
      {log.level === LogLevel.Error && (
        <ErrorCircle style={{ marginRight: 8 }} />
      )}
      {log.level !== LogLevel.Error && (
        <SuccessCircle style={{ marginRight: 8 }} />
      )}
      <div style={{ minWidth: 70 + 8, color: darkGreyHex }}>
        {format(createdAt, "yyyy-MM-dd")}
      </div>
      <div style={{ minWidth: 51 + 8 }}>{format(createdAt, "kk:mm:ss")}</div>
      <div
        className={`mr-2 flex min-w-[47px] flex-col items-center rounded-lg border p-[2px] ${
          isSelected ? "border-base-grey-3-dark" : "border-bd-darker"
        }`}
      >
        {toLabel(log.type)}
      </div>
      <div className="overflow-hidden text-ellipsis">{log.message}</div>
    </div>
  );
}

export default LogsEditor;

function toLabel(logType: LogType): string {
  switch (logType) {
    case LogType.Codegen:
      return "GEN";
    case LogType.GraphQl:
      return "GQL";
    case LogType.Init:
      return "INIT";
    case LogType.Js:
      return "JS";
    case LogType.Schema:
      return "SCHEMA";
    case LogType.SdkMessage:
    case LogType.SdkNode:
      return "SDK";
    default: {
      const neverLogType: never = logType;
      throw new Error(`Unexpected log type: ${neverLogType}`);
    }
  }
}

LogsEditor.LoadingSkeleton = function (): React.ReactElement {
  return (
    <div className="flex flex-col gap-2 px-3 py-2">
      <Skeleton height={13} width={450} />
      <Skeleton height={13} width={450} />
    </div>
  );
};
