import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BusinessManagementDocument,
  BusinessesDocument,
  MeDocument,
  useBusinessesQuery,
  useVerifyEmailAndAcceptInviteMutation,
} from "../../generated/graphql";
import BusinessPage from "./BusinessPage";
import getErrorMessage from "../../lib/query/getErrorMessage";
import Card from "../../components/Card";
import Label from "../../components/Label";
import VerifyAndInviteEmailButton from "./VerifyAndInviteEmailButton";
import Button from "../../components/buttons/Button";

export default function EmailLinkPage(): React.ReactElement | null {
  const params = useParams();
  const navigate = useNavigate();

  const { businessInviteToken, verifyEmailAndInviteToken } = params;
  const { data, loading: dataLoading } = useBusinessesQuery();
  const [verifyAndAccept, { error }] = useVerifyEmailAndAcceptInviteMutation({
    refetchQueries: [
      BusinessesDocument,
      BusinessManagementDocument,
      MeDocument,
    ],
    awaitRefetchQueries: true,
  });
  const [loading, setLoading] = useState<boolean | null>(null);
  const [response, setResponse] = useState<{
    verifiedEmail: boolean;
    totalBusinessesCount: number;
  } | null>(null);

  useEffect(() => {
    if (!businessInviteToken && !verifyEmailAndInviteToken) {
      return;
    }
    if (!dataLoading && loading === null) {
      setLoading(true);
      verifyAndAccept({
        variables: {
          input: {
            businessInviteToken,
            verifyEmailAndInviteToken,
          },
        },
      })
        .then((resp) =>
          setResponse(resp.data?.verifyEmailAndAcceptInvite || null)
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    data,
    loading,
    dataLoading,
    businessInviteToken,
    verifyEmailAndInviteToken,
    verifyAndAccept,
  ]);

  return (
    <BusinessPage>
      <Card layout="horizontal">
        {!data || loading || loading === null ? (
          <Label type="title3">Processing...</Label>
        ) : error ? (
          <>
            <Label type="title3">Error: {getErrorMessage(error)}</Label>
            <VerifyAndInviteEmailButton text="Send new link" />
          </>
        ) : (
          <>
            <div>
              <Label type="title1">Success</Label>
              <p className="mt-1 text-tx-muted">
                {response && (
                  <>
                    {response.totalBusinessesCount > 1 && (
                      <>
                        {response.verifiedEmail
                          ? "We've verified your email and you "
                          : "You "}
                        are now a member of
                        <b> {response.totalBusinessesCount - 1}</b> team
                        {response.totalBusinessesCount - 1 === 1 ? "" : "s"}.
                      </>
                    )}
                    {response.totalBusinessesCount === 1 &&
                      response.verifiedEmail && <>We've verified your email.</>}
                  </>
                )}
              </p>
            </div>
            {response && (
              <Button
                text="Go to projects"
                onClick={() => {
                  if (
                    data?.businesses.length !== response.totalBusinessesCount
                  ) {
                    // This is a fix to force page reload in case
                    // the refetch failed or in case it didn't get the latest data.
                    // TODO: find a better way to do this.
                    console.error(
                      "[verifyEmailAndAcceptInvite] refetch issues"
                    );
                    window.location.href = "/";
                    return Promise.resolve();
                  }
                  return navigate("/");
                }}
                intent="primary"
                weight="outlined"
              />
            )}
          </>
        )}
      </Card>
    </BusinessPage>
  );
}
