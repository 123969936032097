import { getFieldArgumentsObjectTypeName } from "./schema/fieldArgumentsObjectTypeName";

export const anonymousIdCookieName = "hypertuneAnonymousId";
export const sessionIdParamName = "hypertuneSessionId";
export const sessionIdHeaderName = "X-Hypertune-Session-Id";
export const appVersionHeaderName = "X-Version";

export const rightDoubleArrowSymbol = "⇒";

export const voidGraphqlScalarTypeName = "Void";
export const queryObjectTypeName = "Query";
export const sourceObjectTypeName = "Source";
export const rootFieldName = "root";
export const rootFieldArgsTypeName = "RootArgs";
export const rootObjectTypeName = "Root";
export const queryRootArgsTypeName = "Query_root_args";

const contextInputs = ["user", "team", "org", "organization", "wallet"];
export const contextInputsSet = new Set(contextInputs);
export const contextInputsWeights = Object.fromEntries(
  contextInputs.map((value, index) => [value, index])
);
const contextInputFields = [
  "id",
  "uuid",
  "uid",
  "guid",
  "slug",
  "email",
  "address",
];
export const contextInputFieldsSet = new Set(contextInputFields);
export const contextInputFieldsWeights = Object.fromEntries(
  contextInputFields.map((value, index) => [value, index])
);

export const kilSwitchExpressionNote = "Kill switch";
export const graphqlScalarTypeNames = [
  voidGraphqlScalarTypeName,
  "Boolean",
  "Int",
  "Float",
  "String",
] as const;
export const allGraphqlScalarTypeNames: string[] = [...graphqlScalarTypeNames];
export const reservedTypeNames = [rootFieldArgsTypeName, sourceObjectTypeName];
export const queryRootArgsObjectTypeName = getFieldArgumentsObjectTypeName({
  parentObjectTypeName: queryObjectTypeName,
  fieldName: rootFieldName,
});

export const defaultArmLabel = "Default";
export const variableExpressionOptionsGroupLabel = "Variables";
export const otherExpressionOptionsGroupLabel = "Logic";

export const unimplementedExpressionErrorMessage =
  "Expression is not implemented.";

export const cookieConsentCookieName = "hypertuneCookieConsent";

export const backendGraphqlEndpoint = "/graphql";

export const teamGroupId = "team";
/** Percentage of hash requests to send logs for, where 0.1 = 10% */
export const hashRequestLogSamplingRate = 1 / 1000;

export const oldSchemaWelcomeMessage = `# Welcome to Hypertune, the most powerful feature flag, A/B testing and app 
# configuration platform.
#
# Follow the quickstart: https://docs.hypertune.com/quickstart
#
# This is your schema, written in GraphQL. Use Boolean for feature flags, 
# custom enums for flags with more than two states, Int for numeric flags like 
# limits and timeouts, Strings for in-app copy, and custom object and list types
# for more complex app configuration.
#
# Once you've defined your schema, head to the Logic tab.
`;

export const newSchemaWelcomeMessage = `"""
Welcome to Hypertune, the most powerful feature flag, A/B testing and app
configuration platform.

Follow the quickstart: https://docs.hypertune.com/quickstart

This is your schema, written in GraphQL. Use Boolean for feature flags,
custom enums for flags with more than two states, Int for numeric flags like
limits and timeouts, Strings for in-app copy, and custom object and list types
for more complex app configuration.

Once you've defined your schema, head to the Logic tab.
"""`;

export const oldSchemaRootComment = `# You can add arguments to any field in your schema, which you can then
  # reference when defining your logic. We've added a 'context' argument on your
  # root field already, which contains details of the current 'user'.`;

export const newSchemaRootComment = `"""
  You can add arguments to any field in your schema, which you can then
  reference when defining your logic. We've added a 'context' argument on your
  root field already, which contains details of the current 'user'.
  """`;

export const variablePathSuffix = "-var";

export const vercelFlagPathSeparator = ".";
