import { Check } from "@phosphor-icons/react";
import { useEffect, useMemo, useState } from "react";
import { DbBillingData } from "@hypertune/shared-internal";
import { DbVercelInstallation } from "@hypertune/shared-internal/src/vercelMarketplaceTypes";
import Skeleton from "react-loading-skeleton";
import { useHypertune } from "../../../generated/hypertune.react";
import twMerge from "../../../lib/twMerge";
import BusinessPage from "../BusinessPage";
import { calendlyUrl, intentPrimaryHex } from "../../../lib/constants";
import {
  BusinessType,
  useBusinessesQuery,
  useUpdatePlanMutation,
} from "../../../generated/graphql";
import Button from "../../../components/buttons/Button";
import useUpgradeToProAction from "./useUpgradeToProAction";
import UpdatePlanModal from "./UpdatePlanModal";
import { formatRawDate } from "../../../lib/generic/formatDate";
import Tag from "../../../components/Tag";
import { canEditBusiness } from "../../../lib/query/rolePermissions";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";
import NewBusinessModal from "../team/NewBusinessModal";
import {
  Plan,
  PlanContent,
  PlanEnumValues,
} from "../../../generated/hypertune";
import VercelBillingModal from "./VercelBillingModal";

export default function PlansPage(): React.ReactElement | null {
  useEffect(() => {
    document.title = "Plans - Hypertune";
  }, []);

  const { data } = useBusinessesQuery();

  const billingData: DbBillingData = JSON.parse(
    data?.primaryBusiness?.business.billingDataJson ?? "{}"
  );

  const isEnding = !!billingData.subscriptionEndsAt;

  const canEdit = canEditBusiness(data?.primaryBusiness?.role);

  const vercelInstallation: DbVercelInstallation | null = JSON.parse(
    data?.primaryBusiness?.business.vercelInstallationJson ?? "null"
  );

  const currentPlan = data?.primaryBusiness?.business.plan ?? null;

  const hypertune = useHypertune();

  const { allPlans, plansData } = useMemo(() => {
    const plansContent = hypertune.plansContent();
    const plansOrdering = Object.fromEntries(
      plansContent
        .ordering({ itemFallback: "enterprise" })
        .map((item, index) => [item, index])
    );
    const newPlansData = Object.fromEntries(
      PlanEnumValues.filter((plan) => plan.toLowerCase() === plan).map(
        (plan) => [plan, plansContent.planContent({ args: { plan } }).get()]
      )
    ) as Record<Plan, PlanContent>;

    return {
      allPlans: Object.entries(newPlansData)
        .filter(
          ([plan, planData]) => planData.isVisible || plan === currentPlan
        )
        .map(([plan]) => plan)
        .sort((a, b) => plansOrdering[a] - plansOrdering[b]) as Plan[],
      plansData: newPlansData,
    };
  }, [hypertune, currentPlan]);

  const { maxWidth, containerClassName, rowNameClassName, cellClassName } =
    getColumnClassNames(allPlans.length);

  return (
    <BusinessPage
      containerChildrenClassName={`${maxWidth} md:w-full lg:w-full xl:w-full px-20`}
    >
      <div className={containerClassName}>
        <RowName
          name="Plans"
          className="flex-col items-start px-[30px] pt-4 text-h2 font-semibold text-tx-default"
          wrapperClassName={twMerge("px-0", rowNameClassName)}
        />
        {allPlans.map((plan) => (
          <PlanHeader
            businessId={data?.primaryBusiness?.id}
            businessType={data?.primaryBusiness?.business.type}
            billingData={billingData}
            vercelInstallation={vercelInstallation}
            allPlans={allPlans}
            plan={plan}
            currentPlan={currentPlan}
            plansData={plansData}
            isEnding={isEnding}
            canEdit={canEdit}
            cellClassName={cellClassName}
          />
        ))}
        {hypertune
          .plansContent()
          .featureGrid()
          .map((group) => {
            const groupName = group.name({ fallback: "" });
            return (
              <>
                {groupName && (
                  <FeatureGroupHeaderRow
                    name={groupName}
                    allPlans={allPlans}
                    currentPlan={currentPlan}
                    isEnding={isEnding}
                    rowNameClassName={rowNameClassName}
                    cellWrapperClassName={cellClassName}
                  />
                )}
                {group.feature().map((featureNode) => (
                  <>
                    <RowName
                      name={featureNode.name({ fallback: "" })}
                      wrapperClassName={rowNameClassName}
                    />
                    {allPlans.map((plan) => {
                      const featureData = featureNode
                        .data({ args: { plan } })
                        .get();
                      return (
                        <FeatureCell
                          enabled={featureData.enabled}
                          message={featureData.message}
                          isCurrent={plan === currentPlan}
                          isEnding={isEnding}
                          className={cellClassName}
                        />
                      );
                    })}
                  </>
                ))}
              </>
            );
          })}
        <FeatureGroupHeaderRow
          name=""
          allPlans={allPlans}
          currentPlan={currentPlan}
          isEnding={isEnding}
          className="min-h-[42px]"
          rowNameClassName={rowNameClassName}
          cellWrapperClassName={twMerge(
            "rounded-bl-lg rounded-br-lg",
            cellClassName
          )}
        />
      </div>
    </BusinessPage>
  );
}

function PlanHeader({
  businessId,
  businessType,
  billingData,
  vercelInstallation,
  allPlans,
  plan,
  currentPlan,
  plansData,
  isEnding,
  canEdit,
  cellClassName,
}: {
  businessId?: string;
  businessType?: BusinessType;
  billingData: DbBillingData;
  vercelInstallation: DbVercelInstallation | null;
  allPlans: Plan[];
  plan: Plan;
  currentPlan: Plan | null;
  plansData: Record<Plan, PlanContent>;
  isEnding: boolean;
  canEdit: boolean;
  cellClassName: string;
}): React.ReactElement | null {
  const { upgrade } = useUpgradeToProAction();
  const [loading, setLoading] = useState(false);
  const [update] = useUpdatePlanMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  const [showVercelBillingModal, setShowVercelBillingModal] = useState(false);

  const isCurrent = plan === currentPlan;
  const isReactivate = isCurrent && !!billingData.subscriptionEndsAt;
  const planIndex = allPlans.findIndex((pl) => pl === plan);
  const currentPlanIndex = allPlans.findIndex((pl) => pl === currentPlan);
  const isUpgrade = currentPlan && currentPlanIndex < planIndex;

  return (
    <Cell
      className="flex flex-col items-stretch gap-3 px-[30px] pb-[10px] pt-[30px]"
      wrapperClassName={twMerge(
        "px-0 rounded-tl-lg rounded-tr-lg",
        cellClassName
      )}
      isCurrent={isCurrent}
      isEnding={isEnding}
    >
      <div className="flex flex-row items-center justify-between">
        <p className="text-h4 font-semibold">{plansData[plan].name}</p>
        {isCurrent && billingData.subscriptionEndsAt && (
          <Tag
            intent="warning"
            text={`Ends at ${formatRawDate(billingData.subscriptionEndsAt)}`}
          />
        )}
      </div>
      <p className="text-md text-tx-muted">{plansData[plan].description}</p>
      {currentPlan && businessId ? (
        <Button
          size="x-large"
          weight={isUpgrade || isReactivate ? "filled" : "elevated"}
          disabled={
            !canEdit ||
            (isCurrent && !isReactivate) ||
            (!isCurrent && !isUpgrade && !!billingData.subscriptionEndsAt)
          }
          loading={loading}
          intent={isUpgrade || isReactivate ? "primary" : "neutral"}
          text={
            isCurrent
              ? billingData.subscriptionEndsAt
                ? "Reactivate"
                : "Current plan"
              : plan === "enterprise"
                ? "Contact us"
                : isUpgrade
                  ? "Upgrade"
                  : "Downgrade"
          }
          className="mt-auto shadow-button"
          onClick={async () => {
            if (businessType === BusinessType.Personal) {
              setShowNewBusinessModal(true);
              return;
            }
            if (plan === "enterprise") {
              window.open(calendlyUrl, "_blank");
              return;
            }
            if (vercelInstallation?.id) {
              setShowVercelBillingModal(true);
              return;
            }
            if (isReactivate) {
              setLoading(true);
              await update({
                variables: { input: { businessId, cancel: false } },
              });
              setLoading(false);
              return;
            }
            if (isUpgrade && currentPlan === "free" && plan !== "free") {
              setLoading(true);
              await upgrade(businessId, plan);
              // No need to disable loading state as we are navigating to stripe.
              return;
            }
            setShowUpdatePlanModal(true);
          }}
        />
      ) : (
        <div className="mt-auto w-full">
          <Skeleton className="h-[32px] w-full" />
        </div>
      )}
      <div>
        {businessId && currentPlan && showUpdatePlanModal && (
          <UpdatePlanModal
            businessId={businessId}
            currentPlan={currentPlan}
            newPlan={plan}
            hasNewBilling={Object.keys(billingData).length > 0}
            onClose={() => setShowUpdatePlanModal(false)}
          />
        )}
        {showNewBusinessModal && (
          <NewBusinessModal
            upgradePlan={plan}
            onClose={() => setShowNewBusinessModal(false)}
          />
        )}
        {showVercelBillingModal && (
          <VercelBillingModal
            onClose={() => setShowVercelBillingModal(false)}
          />
        )}
      </div>
    </Cell>
  );
}

function FeatureGroupHeaderRow({
  name,
  allPlans,
  currentPlan,
  isEnding,
  className,
  rowNameClassName,
  cellWrapperClassName,
}: {
  name: string;
  allPlans: Plan[];
  currentPlan: Plan | null;
  isEnding: boolean;
  className?: string;
  rowNameClassName?: string;
  cellWrapperClassName?: string;
}): React.ReactElement | null {
  return (
    <>
      <RowName
        name={name}
        className={twMerge(
          "min-h-[70px] items-end border-b-0 text-lg font-semibold text-tx-default",
          className
        )}
        wrapperClassName={rowNameClassName}
      />
      {allPlans.map((planName) => (
        <Cell
          isCurrent={planName === currentPlan}
          isEnding={isEnding}
          className={twMerge("border-b-0", className)}
          wrapperClassName={cellWrapperClassName}
        />
      ))}
    </>
  );
}

function RowName({
  name,
  className,
  wrapperClassName,
}: {
  name: string;
  className?: string;
  wrapperClassName?: string;
}): React.ReactElement | null {
  return (
    <Cell
      wrapperClassName={twMerge("col-span-3 text-tx-muted", wrapperClassName)}
      className={className}
      isCurrent={false}
      isEnding={false}
    >
      {name}
    </Cell>
  );
}

function FeatureCell({
  enabled,
  message,
  isCurrent,
  isEnding,
  className,
}: {
  enabled: boolean;
  message: string;
  isCurrent: boolean;
  isEnding: boolean;
  className?: string;
}): React.ReactElement | null {
  return (
    <Cell
      isCurrent={isCurrent}
      isEnding={isEnding}
      wrapperClassName={className}
    >
      {message ||
        (enabled && <Check weight="bold" color={intentPrimaryHex} size={20} />)}
    </Cell>
  );
}

function Cell({
  children,
  isCurrent,
  isEnding,
  wrapperClassName,
  className,
}: {
  children?: React.ReactNode;
  isCurrent: boolean;
  isEnding: boolean;
  wrapperClassName?: string;
  className?: string;
}): React.ReactElement | null {
  return (
    <div
      className={twMerge(
        "col-span-2 px-[30px]",
        isCurrent && !isEnding && "bg-intent-primary/5",
        isCurrent && isEnding && "bg-intent-warning/10",
        wrapperClassName
      )}
    >
      <div
        className={twMerge(
          "flex h-full w-full flex-row items-center border-b py-[10px]",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

function getColumnClassNames(numberOfPlans: number): {
  maxWidth: string;
  containerClassName: string;
  rowNameClassName: string;
  cellClassName: string;
} {
  if (numberOfPlans <= 3) {
    return {
      maxWidth: "max-w-[1264px]",
      containerClassName: "grid min-w-[675px] grid-cols-9",
      rowNameClassName: "col-span-3",
      cellClassName: "col-span-2",
    };
  }
  switch (numberOfPlans) {
    case 4:
      return {
        maxWidth: "max-w-[1444px]",
        containerClassName: "grid min-w-[875px] grid-cols-11",
        rowNameClassName: "col-span-3",
        cellClassName: "col-span-2",
      };
    case 5:
      return {
        maxWidth: "max-w-[1624px]",
        containerClassName: "grid min-w-[1075px] grid-cols-7",
        rowNameClassName: "col-span-2",
        cellClassName: "col-span-1",
      };
    case 6:
      return {
        maxWidth: "max-w-[1804px]",
        containerClassName: "grid min-w-[1275px] grid-cols-8",
        rowNameClassName: "col-span-2",
        cellClassName: "col-span-1",
      };
    case 7:
      return {
        maxWidth: "max-w-[1984px]",
        containerClassName: "grid min-w-[1475px] grid-cols-9",
        rowNameClassName: "col-span-2",
        cellClassName: "col-span-1",
      };
    case 8:
      return {
        maxWidth: "max-w-[2164px]",
        containerClassName: "grid min-w-[1675px] grid-cols-10",
        rowNameClassName: "col-span-2",
        cellClassName: "col-span-1",
      };
    case 9:
      return {
        maxWidth: "max-w-[2344px]",
        containerClassName: "grid min-w-[1875px] grid-cols-11",
        rowNameClassName: "col-span-2",
        cellClassName: "col-span-1",
      };
    default:
      return {
        maxWidth: "max-w-[2524px]",
        containerClassName: "grid min-w-[2075px] grid-cols-12",
        rowNameClassName: "col-span-2",
        cellClassName: "col-span-1",
      };
  }
}
