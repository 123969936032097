import {
  asError,
  Expression,
  FunctionValueType,
  ListExpression,
  nullThrows,
  prefixError,
  Schema,
  uniqueId,
  ValueType,
} from "@hypertune/sdk/src/shared";
import { useEffect, useRef, useState } from "react";
import { parse } from "csv-parse";
import {
  isPrimitiveValueType,
  PrimitiveValueType,
  toFloat,
  toInt,
} from "@hypertune/shared-internal";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import getNewVariableName from "@hypertune/shared-internal/src/expression/getNewVariableName";
import Modal from "../../../components/Modal";
import Label from "../../../components/Label";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ListImportModalState, setListImportModalState } from "../projectSlice";
import List from "../../../components/icons/List";
import Input from "../../../components/input/Input";
import ErrorMessage from "../../../components/ErrorMessage";
import ExpressionTree from "./expression/ExpressionTree";
import { ExpressionEditorState } from "../../../lib/types";
import MarkdownView from "../../../components/MarkdownView";
import { useHypertune } from "../../../generated/hypertune.react";
import { ValueType as HypertuneValueType } from "../../../generated/hypertune";
import DeleteButton from "../../../components/buttons/DeleteButton";

export const width = 395;

export default function ListImportModal(): React.ReactElement | null {
  const state = useAppSelector(
    (globalState) => globalState.project.listImportModalState
  );

  if (!state) {
    return null;
  }
  return <ListImportModalInner {...state} />;
}

function ListImportModalInner({
  listValueType,
  addItems,
  meId,
  commitContext,
}: ListImportModalState): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const content = useHypertune().content().logic();
  const { itemValueType } = listValueType;

  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [draftExpression, setDraftExpression] = useState<ListExpression | null>(
    null
  );
  const [expressionEditorState, setExpressionEditorState] =
    useState<ExpressionEditorState>({
      selectedItem: null,
      collapsedExpressionIds: {},
    });

  const isValid = draftExpression !== null;

  function onClose(): void {
    dispatch(setListImportModalState(undefined));
  }

  function onSubmit(): void {
    if (!isValid) {
      return;
    }
    addItems(draftExpression.items);
    onClose();
  }

  async function onChange(csvContent: string): Promise<void> {
    let records: string[][];
    try {
      records = await parseCSV(csvContent);
    } catch (error) {
      setErrorMessage(`Failed to parse CSV: ${asError(error).message}`);
      return;
    }

    if (records.length === 0) {
      setErrorMessage("CSV file is empty");
      return;
    }
    const [header, ...items] = records;

    if (isPrimitiveValueType(itemValueType)) {
      if (header.length !== 1 || header[0] !== "value") {
        setErrorMessage(
          `CSV must have a header in the following format: "value"`
        );
        return;
      }
      try {
        setDraftExpression({
          id: uniqueId(),
          type: "ListExpression",
          valueType: listValueType,
          items: items.map(([value], index) =>
            prefixError(
              () =>
                parsePrimitiveValue(itemValueType as PrimitiveValueType, value),
              // We add 2 as header is not included in the items.
              `Invalid value in row: ${index + 2}`
            )
          ),
        });
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(`Failed to parse CSV value: ${asError(error).message}`);
      }
      return;
    }

    if (itemValueType.type === "ObjectValueType") {
      const headerSet = new Set(header);
      const missingFieldNames = Object.keys(
        commitContext.schema.objects[itemValueType.objectTypeName].fields
      )
        .map((fieldName) => (headerSet.has(fieldName) ? null : fieldName))
        .filter(Boolean);
      if (missingFieldNames.length > 0) {
        setErrorMessage(
          `CSV is missing fields: ${missingFieldNames.join(", ")}`
        );
        return;
      }
      const fieldNameToIndex = Object.fromEntries(
        header.map((fieldName, index) => [fieldName, index])
      );
      try {
        setDraftExpression({
          id: uniqueId(),
          type: "ListExpression",
          valueType: listValueType,
          items: items.map((values, index) =>
            prefixError(
              () => ({
                id: uniqueId(),
                type: "ObjectExpression",
                objectTypeName: itemValueType.objectTypeName,
                valueType: itemValueType,
                fields: Object.fromEntries(
                  Object.entries(
                    commitContext.schema.objects[itemValueType.objectTypeName]
                      .fields
                  ).map(([fieldName, fieldSchema]) => {
                    const { unwrappedValueType, wrapExpression } =
                      unwrapFunctionValueType(fieldSchema.valueType);
                    return [
                      fieldName,
                      wrapExpression(
                        isPrimitiveValueType(unwrappedValueType)
                          ? parsePrimitiveValue(
                              unwrappedValueType as PrimitiveValueType,
                              values[fieldNameToIndex[fieldName]]
                            )
                          : parseJSONValue(
                              commitContext.schema,
                              unwrappedValueType,
                              JSON.parse(values[fieldNameToIndex[fieldName]])
                            )
                      ),
                    ];
                  })
                ),
              }),
              // We add 2 as header is not included in the items.
              `Invalid value in row: ${index + 2}`
            )
          ),
        });
        setErrorMessage(null);
      } catch (error) {
        setErrorMessage(`Failed to parse CSV value: ${asError(error).message}`);
      }
      return;
    }

    setErrorMessage(
      `Unexpected list item value type: ${JSON.stringify(itemValueType)}`
    );
  }

  const isFileSelected = !!draftExpression || !!errorMessage;
  useEffect(() => {
    if (!isFileSelected && inputRef.current?.value) {
      inputRef.current.value = "";
    }
  }, [isFileSelected]);

  return (
    <Modal
      buttonLayout="end"
      modalStyle="medium"
      onClose={onClose}
      closeOnEsc
      closeText="Cancel"
      title={
        <div className="flex flex-row items-center gap-2">
          <List />
          <Label type="title3" className="text-tx-default">
            Import from CSV
          </Label>
        </div>
      }
      childrenStyle={{ paddingLeft: 0, paddingRight: 0 }}
      saveText="Import"
      saveIntent="neutral"
      saveWeight="outlined"
      saveDisabled={!isValid}
      onSave={onSubmit}
    >
      <div className="mt-4 flex flex-col text-tx-default">
        <div className="flex max-h-[510px] max-w-[620px] flex-col gap-2 overflow-y-auto px-3">
          <MarkdownView
            markdown={content.listCSVImportMarkdown({
              args: { itemValueType: itemValueType.type as HypertuneValueType },
              fallback: `Unsupported list item value type: ${JSON.stringify(itemValueType)}`,
            })}
          />
          <Input
            type="file"
            accept=".csv"
            elementRef={inputRef}
            readOnly={isFileSelected}
            onChange={onChange}
            style={{
              paddingRight: 5,
              ...(!isFileSelected ? { marginBottom: 8 } : {}),
            }}
            endIcon={
              isFileSelected && (
                <DeleteButton
                  title="Clear"
                  size="x-small"
                  onClick={() => {
                    setErrorMessage(null);
                    setDraftExpression(null);
                  }}
                />
              )
            }
          />
          <ErrorMessage errorMessage={errorMessage} />
          {draftExpression && (
            <div className="mt-2">
              <Label type="title2">
                Imported values ({draftExpression.items.length})
              </Label>
              <ExpressionTree
                className="w-full px-0 py-2"
                context={{
                  meId,
                  commitContext,
                  evaluations: {},
                  expressionEditorState,
                  setExpressionEditorState,
                  ignoreErrors: false,
                  readOnly: false,
                  expandByDefault: true,
                  disableVariableCreation: true,
                  disablePermissionsManagement: true,
                  fullFieldPath: "",
                  resolvedPermissions: {
                    user: {},
                    group: { team: { write: "allow" } },
                  },
                  hideAddRuleButton: true,
                  hideCSVImportButton: true,
                }}
                variables={{}}
                setVariableName={{}}
                valueTypeConstraint={getConstraintFromValueType(listValueType)}
                expression={draftExpression}
                setExpression={(newExpression) =>
                  setDraftExpression(newExpression as ListExpression | null)
                }
                parentExpression={null}
                lift={() => {
                  // noop
                }}
                includeExpressionOption={({ expressionOption }) => {
                  if (
                    expressionOption.type === "SwitchExpression" ||
                    expressionOption.type === "SplitExpression" ||
                    expressionOption.type === "EnumSwitchExpression" ||
                    expressionOption.type ===
                      "GetUrlQueryParameterExpression" ||
                    (expressionOption.type === "GetFieldExpression" &&
                      expressionOption.valueType.type === "ListValueType")
                  ) {
                    return false;
                  }
                  return true;
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const booleanTrueValues = new Set(["true", "yes", "1"]);

function parseJSONValue(
  schema: Schema,
  valueType: ValueType,
  parsedValue: any
): Expression {
  if (isPrimitiveValueType(valueType)) {
    return parsePrimitiveValue(
      valueType as PrimitiveValueType,
      parsedValue.toString()
    );
  }
  if (valueType.type === "ObjectValueType") {
    const { objectTypeName } = valueType;

    return {
      id: uniqueId(),
      type: "ObjectExpression",
      objectTypeName,
      valueType,
      fields: Object.fromEntries(
        Object.entries(schema.objects[objectTypeName].fields).map(
          ([fieldName, fieldSchema]) => {
            const { unwrappedValueType, wrapExpression } =
              unwrapFunctionValueType(fieldSchema.valueType);

            return [
              fieldName,
              wrapExpression(
                parseJSONValue(
                  schema,
                  unwrappedValueType,
                  nullThrows(
                    parsedValue?.[fieldName],
                    `Missing field name "${fieldName}" for object "${objectTypeName}"`
                  )
                )
              ),
            ];
          }
        )
      ),
    };
  }
  if (valueType.type === "ListValueType") {
    if (!Array.isArray(parsedValue)) {
      throw new Error(
        `Provided value is not a list: ${JSON.stringify(parsedValue)}`
      );
    }
    return {
      id: uniqueId(),
      type: "ListExpression",
      valueType,
      items: parsedValue.map((item) => {
        const { unwrappedValueType, wrapExpression } = unwrapFunctionValueType(
          valueType.itemValueType
        );

        return wrapExpression(parseJSONValue(schema, unwrappedValueType, item));
      }),
    };
  }
  throw new Error(
    `Unexpected value type when parsing JSON expression: ${JSON.stringify(valueType)}`
  );
}

function parsePrimitiveValue(
  valueType: PrimitiveValueType,
  value: string
): Expression {
  switch (valueType.type) {
    case "BooleanValueType":
      return {
        id: uniqueId(),
        type: "BooleanExpression",
        valueType,
        value: booleanTrueValues.has(value.toLowerCase()),
      };
    case "IntValueType":
      return {
        id: uniqueId(),
        type: "IntExpression",
        valueType,
        value: toInt(value),
      };
    case "FloatValueType":
      return {
        id: uniqueId(),
        type: "FloatExpression",
        valueType,
        value: toFloat(value),
      };
    case "StringValueType":
      return {
        id: uniqueId(),
        type: "StringExpression",
        valueType,
        value,
      };
    case "RegexValueType":
      return {
        id: uniqueId(),
        type: "RegexExpression",
        valueType,
        value,
      };
    case "EnumValueType":
      return {
        id: uniqueId(),
        type: "EnumExpression",
        valueType,
        value,
      };
    default: {
      const neverValueType: never = valueType;
      throw new Error(
        `Unexpected value type when parsing primitive value: ${JSON.stringify(neverValueType)}`
      );
    }
  }
}

function parseCSV(csvContent: string): Promise<string[][]> {
  return new Promise((resolve, reject) => {
    const records = new Array<string[]>();

    const parser = parse({
      delimiter: ",",
    });
    parser
      .on("readable", () => {
        let record = parser.read();
        while (record !== null) {
          records.push(record);
          record = parser.read();
        }
      })
      .on("error", (error) => {
        reject(error);
      })
      .on("end", () => {
        resolve(records);
      });
    parser.write(csvContent);
    parser.end();
  });
}

function unwrapFunctionValueType(valueType: ValueType): {
  unwrappedValueType: Exclude<ValueType, FunctionValueType>;
  wrapExpression: (expression: Expression) => Expression;
} {
  switch (valueType.type) {
    case "FunctionValueType": {
      const { unwrappedValueType, wrapExpression } = unwrapFunctionValueType(
        valueType.returnValueType
      );
      return {
        unwrappedValueType,
        wrapExpression: (expression) => ({
          id: uniqueId(),
          valueType,
          type: "FunctionExpression",
          body: wrapExpression(expression),
          parameters: valueType.parameterValueTypes.map((argValueType) => {
            return {
              id: uniqueId(),
              name: getNewVariableName({}, argValueType),
            };
          }),
        }),
      };
    }

    default:
      return {
        unwrappedValueType: valueType,
        wrapExpression: (expression) => expression,
      };
  }
}
