import React from "react";
import { Link } from "react-router-dom";
import {
  mediumFontSize,
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from "../../lib/constants";
import ErrorMessage from "../ErrorMessage";
import Logo from "../Logo";
import twMerge from "../../lib/twMerge";

export default function ContainerWithLogo({
  errorMessage,
  className,
  children,
}: {
  errorMessage?: string;
  className?: string;
  children?: React.ReactNode;
}): React.ReactElement {
  const year = new Date().getFullYear();
  return (
    <div className="flex min-h-screen w-full flex-col items-center bg-white">
      <Logo size="large" className="mt-4 lg-h:mt-12" />
      <div
        className={twMerge(
          "flex h-full w-full max-w-[520px] flex-col items-stretch p-4 text-lg md-h:mt-6 lg-h:mt-20 lg-h:p-12",
          className
        )}
      >
        <div className="-mt-[19.5px] mb-6 flex h-[19.5px] w-full flex-col items-center text-center">
          {errorMessage ? (
            <ErrorMessage
              hideIcon
              style={{ fontSize: mediumFontSize }}
              errorMessage={errorMessage}
            />
          ) : null}
        </div>
        {children}
      </div>
      <div className="flex flex-grow" />
      <div className="mb-8 flex w-full flex-row gap-4 px-8 text-sm text-tx-disabled lg:px-40">
        <div>© {year} Hypertune Ltd</div>
        <div className="flex flex-grow" />
        <div>
          <div>
            <Link
              className="hover:underline"
              to={termsAndConditionsUrl}
              target="_blank"
            >
              Terms of service
            </Link>
          </div>
        </div>
        <div>
          <Link
            className="hover:underline"
            to={privacyPolicyUrl}
            target="_blank"
          >
            Privacy policy
          </Link>
        </div>
      </div>
    </div>
  );
}
