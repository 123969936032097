export const logsCreationEndpoint = "/logs";

export const localEdgeWorkerBaseUrl = "http://localhost:3002";
export const localLogsEndpointUrl = `http://localhost:3003${logsCreationEndpoint}`;

export const stagingEdgeCdnBaseUrl = "https://hypertune-edge-staging.b-cdn.net";
export const stagingLogsEndpointUrl = `https://gcp-staging.fasthorse.workers.dev${logsCreationEndpoint}`;

export const prodEdgeCdnBaseUrl = "https://edge.hypertune.com";
export const prodLogsEndpointUrl = `https://gcp.fasthorse.workers.dev${logsCreationEndpoint}`;

export const traceIdHeaderName = "x-hypertune-trace-id";

export const configFileName = "hypertune.json";

export const tokenEnvironmentVariableName = "HYPERTUNE_TOKEN";

export const graphqlTypeNameKey = "__typename";
export const isPartialObjectKey = "__isPartialObject__";
export const isQueryVariableKey = "__isVariable";
export const fieldPathSeparator = " > ";
export const numHashBuckets = 10000;
export const defaultArmKey = "default";
export const rootFieldName = "root";

export const defaultRetries = 10;
export const defaultCacheSize = 250;

export const breakingSchemaChangesError =
  "If you've made breaking changes to your schema like adding a new field argument, you may need to re-run code generation and fix the type errors.";

export const defaultBranchName = "main";

export const jsonitoResponsePrefix = "jsonito_";
