import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TooltipsState = {
  [notificationId: string]: number;
};

const initialState: TooltipsState = {};

const tooltipsSlice = createSlice({
  name: "tooltips",
  initialState,
  reducers: {
    setTooltipStep: (
      draftState,
      action: PayloadAction<{ id: string; step: number }>
    ) => {
      draftState[action.payload.id] = action.payload.step;
    },
  },
});

export const { setTooltipStep } = tooltipsSlice.actions;

export default tooltipsSlice.reducer;
