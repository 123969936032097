import { Expression, Schema, ValueType } from "@hypertune/sdk/src/shared";
import { NotePencil, Nut } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import {
  changeObjectFieldType,
  objectFieldToCode,
  removeFieldFromObject,
  removeObjectFieldsFromExpression,
  setObjectFieldDeprecationReason,
  setObjectFieldDescription,
} from "@hypertune/shared-internal";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import { useAppDispatch } from "../../../../../app/hooks";
import TypeIcon from "../../../../../components/icons/TypeIcon";
import {
  setDraftCommitSchema,
  setDraftCommitSchemaAndExpression,
} from "../../../projectSlice";
import DeleteWithConfirmationButton from "../DeleteWithConfirmationButton";
import {
  Code,
  Description,
  DetailContainer,
  Status,
} from "../../../../../components/Details";
import ValueTypeSelector, {
  valueTypeOptionGroupsFromSchema,
  unrollValueType,
} from "./ValueTypeSelector";
import { valueTypeToTypeIconType } from "./ObjectEditor";
import ModalWithContent from "../../../../../components/ModalWithContent";
import StatusChangeButton from "../StatusChangeButton";
import { useHypertune } from "../../../../../generated/hypertune.react";
import getSchemaTypeReferences from "../../getSchemaTypeReferences";
import getNewSchemaAndValueType from "../../getNewSchemaAndValueType";
import getValueTypeMessage from "../../getValueTypeMessage";
import Tags from "../../../../../components/Tags";

export default function ObjectFieldDetails({
  readOnly,
  schema,
  topLevelExpression,
  expression,
  setExpression,
  objectTypeName,
  fieldName,
  view,
  onDelete,
  onClose,
  trackEditing,
  showDetails,
}: {
  readOnly: boolean;
  schema: Schema;
  topLevelExpression: Expression;
  expression?: Expression;
  setExpression?: (newExpression: Expression | null) => void;
  objectTypeName: string;
  fieldName: string;
  view: "schema" | "logic";
  onDelete: () => void;
  onClose?: () => void;
  trackEditing?: React.Dispatch<React.SetStateAction<Set<string>>>;
  showDetails?: boolean;
}): React.ReactElement | null {
  const hypertune = useHypertune();
  const content = hypertune.content().schema();
  const dispatch = useAppDispatch();
  const typeReferences = useMemo(
    () => getSchemaTypeReferences(schema),
    [schema]
  );

  const [newValueTypes, setNewValueTypes] = useState<ValueType[] | null>(null);

  const entityName = view === "schema" ? "field" : "flag";
  const parentObject = schema.objects[objectTypeName];
  if (!parentObject) {
    return null;
  }

  const field = parentObject.fields[fieldName];
  const disableActions =
    readOnly || Object.keys(parentObject.fields).length <= 1;

  return (
    <>
      {showDetails && (
        <Description
          readOnly={readOnly}
          editTracking={
            trackEditing
              ? {
                  id: "field-description",
                  track: trackEditing,
                }
              : undefined
          }
          icon={
            view === "schema" ? (
              <TypeIcon
                type={valueTypeToTypeIconType(schema, field.valueType)}
              />
            ) : undefined
          }
          title={view === "schema" ? toStartCase(fieldName) : undefined}
          text={field.description || ""}
          onClose={onClose}
          setText={(newDescription) =>
            dispatch(
              setDraftCommitSchema(
                setObjectFieldDescription(
                  schema,
                  objectTypeName,
                  fieldName,
                  newDescription || null
                )
              )
            )
          }
        />
      )}
      {expression && (
        <Tags
          readOnly={readOnly}
          schema={schema}
          expression={expression}
          setExpression={setExpression}
        />
      )}

      {showDetails && newValueTypes !== null && (
        <ModalWithContent
          content={content
            .changeTypeConfirmation({
              args: {
                message:
                  getValueTypeMessage({
                    content,
                    schema,
                    objectTypeName,
                    typeReferences,
                    baseValueType: newValueTypes.at(-1)!,
                  }) ?? "",
              },
            })
            .get()}
          variables={{ entityName }}
          onClose={() => setNewValueTypes(null)}
          onSave={() => {
            const { newSchema, newValueType } = getNewSchemaAndValueType({
              schema,
              objectTypeName,
              typeReferences,
              valueTypes: newValueTypes,
            });
            dispatch(
              setDraftCommitSchema(
                changeObjectFieldType(
                  newSchema,
                  objectTypeName,
                  fieldName,
                  newValueType
                )
              )
            );
            setNewValueTypes(null);
          }}
        />
      )}
      {showDetails && parentObject.role === "output" && (
        <Status
          currentStatus={
            field.deprecationReason === undefined ? "Active" : "Deprecated"
          }
          intent={field.deprecationReason === undefined ? "success" : "neutral"}
        />
      )}
      {showDetails && (
        <>
          <DetailContainer icon={<Nut weight="regular" />} title="Type">
            <ValueTypeSelector
              readOnly={readOnly}
              objectTypeName={objectTypeName}
              trackEditing={trackEditing}
              hideFirstTitle
              optionGroups={valueTypeOptionGroupsFromSchema(
                schema,
                objectTypeName
              )}
              valueTypes={unrollValueType(field.valueType)}
              setValueTypes={setNewValueTypes}
              titleClassName="mt-0 -mb-1"
              dropdownStyle={{
                caret: "down",
                scrollToPosition: "center",
                buttonClassName:
                  "border min-h-[46px] px-[14px] py-[12px] w-[300px]",
                subtitleClassName: "max-w-[300px]",
                panelClassName: "w-[340px] px-[19px] -ml-1 overflow-x-hidden",
              }}
            />
          </DetailContainer>
          <Code
            code={objectFieldToCode(schema, objectTypeName, fieldName, true)}
          />
          {!disableActions && (
            <DetailContainer icon={<NotePencil />} title="Actions">
              <div className="flex flex-row gap-2">
                {parentObject.role === "output" && (
                  <StatusChangeButton
                    deprecationReason={field.deprecationReason}
                    setDeprecationReason={(reason) => {
                      dispatch(
                        setDraftCommitSchema(
                          setObjectFieldDeprecationReason(
                            schema,
                            objectTypeName,
                            fieldName,
                            reason
                          )
                        )
                      );
                    }}
                  />
                )}
                <DeleteWithConfirmationButton
                  entityName={entityName}
                  onClick={() => {
                    const newSchema = removeFieldFromObject(
                      schema,
                      objectTypeName,
                      fieldName
                    );
                    dispatch(
                      setDraftCommitSchemaAndExpression({
                        schema: newSchema,
                        expression: removeObjectFieldsFromExpression(
                          newSchema,
                          topLevelExpression,
                          {
                            [objectTypeName]: [fieldName],
                          }
                        ),
                      })
                    );
                    onDelete();
                  }}
                />
              </div>
            </DetailContainer>
          )}
        </>
      )}
    </>
  );
}
