import {
  Expression,
  Schema,
  StringExpression,
} from "@hypertune/sdk/src/shared";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import React, { useState } from "react";
import {
  getFieldArgumentsObjectTypeNameParts,
  queryObjectTypeName,
  rootObjectTypeName,
  setObjectDescription,
  unwrapValueType,
} from "@hypertune/shared-internal";
import { VariableMap } from "@hypertune/shared-internal/src/expression/types";
import { ArrowUp, CaretDoubleDown, NotePencil } from "@phosphor-icons/react";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import MarkdownView from "../../../../components/MarkdownView";
import Label from "../../../../components/Label";
import CollapseButton from "../../../../components/buttons/CollapseButton";
import CollapseDiv from "../../../../components/animations/CollapseDiv";
import {
  Description,
  ContentContainer,
  DetailsContainer,
  DetailContainer,
} from "../../../../components/Details";
import { useAppDispatch } from "../../../../app/hooks";
import { setDraftCommitSchema } from "../../projectSlice";
import ObjectFieldDetails from "../../schema/typeEditor/object/ObjectFieldDetails";
import Tags from "../../../../components/Tags";
import { LiftFunction, VariableContext } from "../../../../lib/types";
import Button from "../../../../components/buttons/Button";
import DeleteButton from "../../../../components/buttons/DeleteButton";

type CollapsedExpressions = {
  [expressionId: string]: boolean;
};

export default function ExpressionDetail({
  readOnly,
  topLevelExpression,
  fieldName,
  parentExpression,
  expression,
  setExpression,
  variables,
  variableContext,
  lift,
  schema,
  onDelete,
}: {
  readOnly: boolean;
  topLevelExpression: Expression;
  fieldName: string;
  parentExpression: Expression | null;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  variables: VariableMap;
  variableContext?: VariableContext;
  lift: LiftFunction;
  schema: Schema;
  onDelete: () => void;
}): React.ReactElement | null {
  const [collapsedExpressions, setCollapsedExpressions] =
    useState<CollapsedExpressions>({});

  return (
    <DetailsContainer>
      <ExpressionDetailInner
        readOnly={readOnly}
        topLevelExpression={topLevelExpression}
        fieldName={fieldName}
        parentExpression={parentExpression}
        expression={expression}
        setExpression={setExpression}
        variables={variables}
        variableContext={variableContext}
        lift={lift}
        schema={schema}
        onDelete={onDelete}
        collapsedExpressions={collapsedExpressions}
        setCollapsedExpressions={setCollapsedExpressions}
        showDetails
        showLabels
      />
    </DetailsContainer>
  );
}

function ExpressionDetailInner({
  readOnly,
  topLevelExpression,
  fieldName,
  parentExpression,
  expression,
  setExpression,
  variables,
  variableContext,
  lift,
  schema,
  onDelete,
  collapsedExpressions,
  setCollapsedExpressions,
  showLabels,
  showDetails,
}: {
  readOnly: boolean;
  topLevelExpression: Expression;
  fieldName: string;
  parentExpression: Expression | null;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  variables: VariableMap;
  variableContext?: VariableContext;
  lift: LiftFunction;
  schema: Schema;
  onDelete: () => void;
  collapsedExpressions: CollapsedExpressions;
  setCollapsedExpressions: (
    newCollapsedExpressions: CollapsedExpressions
  ) => void;
  showLabels: boolean;
  showDetails?: boolean;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  if (!expression) {
    return null;
  }
  switch (expression.type) {
    case "ApplicationExpression":
      return (
        <ExpressionDetailInner
          readOnly={readOnly}
          topLevelExpression={topLevelExpression}
          fieldName={fieldName}
          parentExpression={parentExpression}
          expression={expression.function}
          setExpression={(newExpression) =>
            setExpression({ ...expression, function: newExpression })
          }
          variables={variables}
          variableContext={variableContext}
          lift={lift}
          schema={schema}
          onDelete={onDelete}
          collapsedExpressions={collapsedExpressions}
          setCollapsedExpressions={setCollapsedExpressions}
          showLabels={showLabels}
          showDetails={showDetails}
        />
      );
    case "ObjectExpression":
      return (
        <>
          {showDetails &&
            schema.objects[expression.objectTypeName]?.description && (
              <Description
                readOnly={readOnly}
                text={
                  schema.objects[expression.objectTypeName]
                    .description as string
                }
                setText={(newDescription) =>
                  dispatch(
                    setDraftCommitSchema(
                      setObjectDescription(
                        schema,
                        expression.objectTypeName,
                        newDescription || null
                      )
                    )
                  )
                }
              />
            )}
          {showLabels && (
            <Tags
              readOnly={readOnly}
              schema={schema}
              expression={expression}
              setExpression={setExpression}
            />
          )}
          {!readOnly && variableContext && (
            <VariableActions
              readOnly={readOnly}
              expression={expression}
              setExpression={setExpression}
              parentExpression={parentExpression}
              variableContext={variableContext}
              schema={schema}
              lift={lift}
            />
          )}
          {Object.entries(expression.fields).map(([objectFieldName, field]) => {
            return (
              <ExpressionDetailInner
                readOnly={readOnly}
                topLevelExpression={topLevelExpression}
                fieldName={objectFieldName}
                parentExpression={parentExpression}
                expression={field}
                setExpression={(newExpression) =>
                  setExpression({
                    ...expression,
                    fields: {
                      ...expression.fields,
                      [objectFieldName]: newExpression,
                    },
                  })
                }
                variables={variables}
                lift={lift}
                schema={schema}
                onDelete={onDelete}
                collapsedExpressions={collapsedExpressions}
                setCollapsedExpressions={setCollapsedExpressions}
                showLabels={false}
                showDetails={false}
              />
            );
          })}
        </>
      );
    case "FunctionExpression": {
      const argsDef =
        expression.valueType.parameterValueTypes.length === 1 &&
        expression.valueType.parameterValueTypes[0].type === "ObjectValueType"
          ? getFieldArgumentsObjectTypeNameParts(
              expression.valueType.parameterValueTypes[0].objectTypeName
            )
          : null;
      const showObjectFieldDetails =
        showDetails &&
        argsDef &&
        argsDef?.parentObjectTypeName &&
        schema.objects[argsDef.parentObjectTypeName].fields[argsDef.fieldName];
      return (
        <>
          {showObjectFieldDetails && (
            <ObjectFieldDetails
              readOnly={readOnly}
              view="logic"
              schema={schema}
              topLevelExpression={topLevelExpression}
              expression={expression}
              setExpression={setExpression}
              objectTypeName={argsDef.parentObjectTypeName}
              fieldName={argsDef.fieldName}
              onDelete={onDelete}
              showDetails={showDetails}
            />
          )}
          <ExpressionDetailInner
            readOnly={readOnly}
            topLevelExpression={topLevelExpression}
            fieldName={fieldName}
            parentExpression={parentExpression}
            expression={expression.body}
            setExpression={(newExpression) =>
              setExpression({
                ...expression,
                body: newExpression,
              })
            }
            variables={variables}
            variableContext={variableContext}
            lift={lift}
            schema={schema}
            onDelete={onDelete}
            collapsedExpressions={collapsedExpressions}
            setCollapsedExpressions={setCollapsedExpressions}
            showLabels={showLabels && !showObjectFieldDetails}
            showDetails={false}
          />
        </>
      );
    }

    case "StringConcatExpression":
      return (
        <MarkdownExpressionDetail
          readOnly={readOnly}
          parentExpression={parentExpression}
          expression={{
            ...expression,
            type: "StringExpression",
            value:
              expression.strings !== null &&
              expression.strings.type === "ListExpression" &&
              expression.strings.valueType.itemValueType.type ===
                "StringValueType"
                ? expression.strings.items
                    .map((item) =>
                      item?.type === "StringExpression"
                        ? item.value
                        : item?.type === "VariableExpression"
                          ? `# {{ ${variables[item.variableId].name} }}`
                          : `# {{ dynamicValue }}`
                    )
                    .join("\n")
                : "",
          }}
          fieldName={fieldName}
          collapsedExpressions={collapsedExpressions}
          setCollapsedExpressions={setCollapsedExpressions}
          setExpression={setExpression}
          variableContext={variableContext}
          lift={lift}
          schema={schema}
          showLabels={showLabels}
        />
      );
    case "StringExpression":
      if (isMarkdown(fieldName)) {
        return (
          <MarkdownExpressionDetail
            readOnly={readOnly}
            parentExpression={parentExpression}
            expression={expression}
            setExpression={setExpression}
            fieldName={fieldName}
            collapsedExpressions={collapsedExpressions}
            setCollapsedExpressions={setCollapsedExpressions}
            variableContext={variableContext}
            lift={lift}
            schema={schema}
            showLabels={showLabels}
          />
        );
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return (
        <ExpressionDetailsActions
          readOnly={readOnly}
          parentExpression={parentExpression}
          expression={expression}
          setExpression={setExpression}
          variableContext={variableContext}
          lift={lift}
          schema={schema}
          showLabels={showLabels}
        />
      );
  }
}

function isMarkdown(fieldName: string): boolean {
  return fieldName.toLowerCase().includes("markdown");
}

function MarkdownExpressionDetail({
  readOnly,
  parentExpression,
  expression,
  setExpression,
  fieldName,
  variableContext,
  lift,
  schema,
  showLabels,
  collapsedExpressions,
  setCollapsedExpressions,
}: {
  readOnly: boolean;
  parentExpression: Expression | null;
  expression: StringExpression;
  setExpression: (newExpression: Expression | null) => void;
  fieldName: string;
  variableContext?: VariableContext;
  lift: LiftFunction;
  schema: Schema;
  showLabels: boolean;
  collapsedExpressions: CollapsedExpressions;
  setCollapsedExpressions: (
    newCollapsedExpressions: CollapsedExpressions
  ) => void;
}): React.ReactElement | null {
  const isOpen = !collapsedExpressions[expression.id];
  return (
    <>
      <ContentContainer className={isOpen ? "" : "border-b-0"}>
        <div className="flex flex-row justify-between border-b border-bd-darker px-3 py-2">
          <Label type="title3">{toStartCase(fieldName)}</Label>
          <CollapseButton
            isOpen={isOpen}
            setOpen={(newIsOpen) => {
              setCollapsedExpressions({
                ...collapsedExpressions,
                [expression.id]: !newIsOpen,
              });
            }}
          />
        </div>
        <CollapseDiv isOpen={isOpen} className="overflow-auto">
          <div className="px-3 pt-2">
            <MarkdownView markdown={expression.value} />
          </div>
        </CollapseDiv>
      </ContentContainer>
      <ExpressionDetailsActions
        readOnly={readOnly}
        parentExpression={parentExpression}
        expression={expression}
        setExpression={setExpression}
        variableContext={variableContext}
        lift={lift}
        schema={schema}
        showLabels={showLabels}
      />
    </>
  );
}

function ExpressionDetailsActions({
  readOnly,
  parentExpression,
  expression,
  setExpression,
  variableContext,
  lift,
  schema,
  showLabels,
}: {
  readOnly: boolean;
  parentExpression: Expression | null;
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
  variableContext?: VariableContext;
  lift: LiftFunction;
  schema: Schema;
  showLabels: boolean;
}): React.ReactElement {
  return (
    <>
      {showLabels && (
        <Tags
          readOnly={readOnly}
          schema={schema}
          expression={expression}
          setExpression={setExpression}
        />
      )}
      {!readOnly && variableContext && (
        <VariableActions
          readOnly={readOnly}
          expression={expression}
          setExpression={setExpression}
          parentExpression={parentExpression}
          variableContext={variableContext}
          schema={schema}
          lift={lift}
        />
      )}
    </>
  );
}

function VariableActions({
  readOnly,
  expression,
  setExpression,
  parentExpression,
  variableContext,
  schema,
  lift,
}: {
  readOnly: boolean;
  expression: Expression;
  setExpression: (newExpression: Expression | null) => void;
  parentExpression: Expression | null;
  variableContext: VariableContext;
  schema: Schema;
  lift: LiftFunction;
}): React.ReactElement | null {
  const parentUnwrappedValueType = parentExpression
    ? unwrapValueType(parentExpression.valueType)
    : null;
  const canLift =
    !readOnly &&
    parentUnwrappedValueType &&
    (parentUnwrappedValueType.type !== "ObjectValueType" ||
      (parentUnwrappedValueType.objectTypeName !== queryObjectTypeName &&
        parentUnwrappedValueType.objectTypeName !== rootObjectTypeName)) &&
    expression.type !== "VariableExpression" &&
    expression.type !== "FunctionExpression" &&
    isValueTypeValid(schema, expression.valueType);

  function doLift(newVariableName: string | null, isNew: boolean): void {
    if (!expression) {
      return;
    }
    lift({
      argument: expression,
      replaceArgument: (variable) => variable,
      replacedVariableIdToNewVariable: {},
      newVariableName,
      isNew,
      keepInObjectField: isNew,
    });
  }

  return (
    <DetailContainer icon={<NotePencil />} title="Actions">
      <div className="flex flex-row flex-wrap gap-2">
        <Button
          text="Drop variable"
          icon={<CaretDoubleDown weight="regular" />}
          weight="elevated"
          onClick={variableContext.drop}
        />
        <DeleteButton
          weight="elevated"
          text="Delete contents"
          onClick={() => setExpression(null)}
        />
        {canLift && (
          <Button
            text="Lift variable"
            icon={<ArrowUp weight="regular" />}
            weight="elevated"
            onClick={() => doLift(variableContext.name, false)}
          />
        )}
      </div>
    </DetailContainer>
  );
}
