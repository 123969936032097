import ModalWithContent from "../../../components/ModalWithContent";

export default function VercelBillingModal({
  onClose,
}: {
  onClose: () => void;
}): React.ReactElement | null {
  return (
    <ModalWithContent
      content={{
        title: "Change plan",
        message: "You can change your plan from the Vercel dashboard.",
        buttonText: "Close",
        closeText: "",
        intent: "PRIMARY",
      }}
      onClose={onClose}
      onSave={onClose}
    />
  );
}
