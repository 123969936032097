import React from "react";
import {
  BookBookmark,
  CaretDoubleLeft,
  CaretDoubleRight,
  ChatTeardropText,
  Command,
  Gauge,
  MagnifyingGlass,
  SignOut,
} from "@phosphor-icons/react";
import { signOut, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SidebarItem, { SidebarItemProps } from "./SidebarItem";
import { docsUrl, intentDangerHex, lighterGreyHex } from "../lib/constants";
import useStickyState from "../lib/hooks/useStickyState";
import Button from "./buttons/Button";
import ShortcutDefinition from "./ShortcutDefinition";
import useIntercom from "../app/useIntercom";
import Tooltip from "./tooltips/Tooltip";
import { useHypertune } from "../generated/hypertune.react";
import {
  PrimaryBusinessUsageQuery,
  usePrimaryBusinessUsageQuery,
} from "../generated/graphql";
import { getCookieConsent } from "../lib/cookieConsent";
import { RootNode } from "../generated/hypertune";

export type Section = {
  items: SidebarItemProps[];
};

export default function Sidebar({
  sidebarId,
  collapsible,
  setIsCommandPaletteVisible,
  showCommandPaletteTooltip,
  sections,
  bottomItems,
}: {
  sidebarId: string;
  collapsible?: boolean;
  setIsCommandPaletteVisible?: (isVisible: boolean) => void;
  showCommandPaletteTooltip?: boolean;
  sections: Section[];
  bottomItems?: SidebarItemProps[];
}): React.ReactElement {
  const hypertune = useHypertune();
  const hasCookieConsent = getCookieConsent();

  const content = hypertune.content();
  const { data, loading } = usePrimaryBusinessUsageQuery();

  const navigate = useNavigate();
  const { showIntercom } = useIntercom();
  const [isCollapsed, setIsCollapsed] = useStickyState(
    false,
    `${sidebarId}-collapsed`
  );

  return (
    <div
      className={`flex flex-shrink-0 flex-col items-start gap-[7px] overflow-y-auto overflow-x-hidden border-r p-[10px] ${isCollapsed ? "w-[53px]" : "w-[248px]"}`}
    >
      {collapsible && (
        <div
          className={`flex ${
            isCollapsed ? "flex-col gap-2" : "flex-row gap-[6px]"
          } mb-[9px] w-full`}
        >
          <Tooltip
            id="logic"
            step={2}
            allSteps={content.logic().tooltips()}
            isVisible={showCommandPaletteTooltip}
            className={!isCollapsed ? "flex w-full flex-row" : ""}
          >
            <Button
              size="large"
              weight="elevated"
              icon={
                <MagnifyingGlass
                  size={14}
                  weight="regular"
                  color={lighterGreyHex}
                />
              }
              text={!isCollapsed ? "Search..." : undefined}
              iconEnd={
                !isCollapsed ? (
                  <ShortcutDefinition
                    keys={[
                      <>
                        <Command
                          size={12}
                          weight="regular"
                          color={lighterGreyHex}
                        />
                        K
                      </>,
                    ]}
                  />
                ) : undefined
              }
              onClick={
                setIsCommandPaletteVisible
                  ? () => setIsCommandPaletteVisible(true)
                  : undefined
              }
              className={!isCollapsed ? "flex-grow justify-between" : ""}
            />
          </Tooltip>
          <Button
            size="large"
            weight="elevated"
            icon={
              isCollapsed ? (
                <CaretDoubleRight
                  size={14}
                  weight="regular"
                  color={lighterGreyHex}
                />
              ) : (
                <CaretDoubleLeft
                  size={14}
                  weight="regular"
                  color={lighterGreyHex}
                />
              )
            }
            onClick={() => setIsCollapsed((collapsed) => !collapsed)}
          />
        </div>
      )}
      {sections.map(
        (section) =>
          section.items.map(
            ({
              icon,
              title,
              onClick,
              link,
              hasError,
              isSelected,
              children,
            }) => (
              <SidebarItem
                key={title}
                isCollapsed={isCollapsed}
                icon={icon}
                title={title}
                onClick={onClick}
                link={link}
                hasError={hasError}
                isSelected={isSelected}
              >
                {children}
              </SidebarItem>
            )
          )
        // TODO: add divider if there is more than one section.
      )}
      <div className="mt-auto min-h-[68px] w-full" />
      <div className="flex w-full flex-col items-stretch gap-[7px]">
        {!loading &&
          data?.primaryBusiness &&
          data.primaryBusiness.business.plan === "free" && (
            <OverageMessage
              isCollapsed={isCollapsed}
              primaryBusiness={data?.primaryBusiness}
            />
          )}
        {bottomItems?.map(
          ({ icon, title, onClick, link, hasError, isSelected, children }) => (
            <SidebarItem
              key={title}
              isCollapsed={isCollapsed}
              icon={icon}
              title={title}
              onClick={onClick}
              link={link}
              hasError={hasError}
              isSelected={isSelected}
            >
              {children}
            </SidebarItem>
          )
        ) || null}
        <SidebarItem
          isCollapsed={isCollapsed}
          icon={<ChatTeardropText />}
          title="Live chat"
          onClick={showIntercom}
          loading={showIntercom.loading}
        />
        <SidebarItem
          isCollapsed={isCollapsed}
          icon={<BookBookmark />}
          title="Documentation"
          link={{ href: docsUrl, isExternal: true }}
        />
        <SidebarItem
          isCollapsed={isCollapsed}
          icon={<SignOut />}
          title="Sign out"
          onClick={(): void => {
            signOut(getAuth())
              .then(() => navigate("/login"))
              .catch((signOutError) => {
                console.error("signOut error:", signOutError);
              });
          }}
          className={
            !hasCookieConsent ? "mb-[128.5px] 2xs:mb-[69px] xs:mb-[59.5px]" : ""
          }
        />
      </div>
    </div>
  );
}

function OverageMessage({
  isCollapsed,
  primaryBusiness,
}: {
  isCollapsed: boolean;
  primaryBusiness: NonNullable<PrimaryBusinessUsageQuery["primaryBusiness"]>;
}): React.ReactElement | null {
  const navigate = useNavigate();
  const hypertune = useHypertune();
  const limits = hypertune.features().planLimits().get();

  const hasTeamSizeOverage =
    limits.softTeamMemberCount > 0 &&
    primaryBusiness.business.memberCount > limits.softTeamMemberCount;

  if (hasTeamSizeOverage) {
    return (
      <SidebarItem
        isCollapsed={isCollapsed}
        icon={<Gauge color={intentDangerHex} weight="fill" />}
        title="Team size overage"
        subtitle={`${primaryBusiness.business.memberCount}/${limits.softTeamMemberCount} allowed team members`}
        intent="danger"
        variant="minimal"
        onClick={() => navigate("/plans")}
      />
    );
  }

  const requestsOverageIntent = getUsageIntent(
    hypertune,
    primaryBusiness.business.requestsCurrentUsage,
    limits.billedRequestCount
  );
  const analyticsOverageIntent = getUsageIntent(
    hypertune,
    primaryBusiness.business.analyticsEventsCurrentUsage,
    limits.analyticsEventsCount
  );

  if (!requestsOverageIntent && !analyticsOverageIntent) {
    return null;
  }

  const intent =
    requestsOverageIntent === "danger" || analyticsOverageIntent === "danger"
      ? "danger"
      : "warning";
  const requestsUsagePercentage = getUsagePercentage(
    primaryBusiness.business.requestsCurrentUsage,
    limits.billedRequestCount
  );
  const requestsUsagePercentageString =
    requestsUsagePercentage.toLocaleString();
  const analyticsEventsUsagePercentage = getUsagePercentage(
    primaryBusiness.business.analyticsEventsCurrentUsage,
    limits.analyticsEventsCount
  );
  const analyticsEventsUsagePercentageString =
    analyticsEventsUsagePercentage.toLocaleString();
  const message = hypertune
    .content()
    .usage()
    .overageMessage({
      args: {
        requestsUsagePercentage,
        requestsUsagePercentageString,
        analyticsEventsUsagePercentage,
        analyticsEventsUsagePercentageString,
      },
    })
    .get({
      fallback: {
        title:
          intent === "danger" ? "Usage overage" : "Approaching usage limits",
        description: `Used ${requestsUsagePercentageString}% of requests and ${analyticsEventsUsagePercentageString}% of analytics events.`,
      },
    });

  return (
    <SidebarItem
      isCollapsed={isCollapsed}
      icon={<Gauge color={intentDangerHex} weight="fill" />}
      title={message.title}
      subtitle={message.description}
      intent={intent}
      variant="minimal"
      onClick={() => navigate("/plans")}
    />
  );
}

function getUsageIntent(
  hypertune: RootNode,
  usage: number,
  limit: number
): "warning" | "danger" | null {
  if (usage > limit) {
    return "danger";
  }
  if (
    usage / limit >=
    hypertune.features().earlyWarningThresholdPercentage({ fallback: 0.5 })
  ) {
    return "warning";
  }
  return null;
}

function getUsagePercentage(usage: number, limit: number): number {
  return Math.round((usage * 100) / limit);
}
