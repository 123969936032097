import {
  NavigateOptions,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCallback } from "react";
import { defaultBranchName } from "@hypertune/sdk/src/shared/constants";
import { draftCommitId } from "../../lib/constants";
import { selectedPRIdUrlKey } from "./pull-request/pullRequestHooks";

export const skipNavigationBlockerKey = "skip_block";

export const projectViews = [
  "logic",
  "schema",
  "splits",
  "preview",
  "logs",
  "diff",
  "branches",
  "pull-requests",
  "analytics",
  "settings",
] as const;
export type ProjectView = (typeof projectViews)[number];

export type ProjectSelectedState = {
  projectId?: string;
  branchName?: string;
  commitId?: string;
  view: ProjectView;
};

export type ProjectSelectedStateUpdate = Partial<
  Omit<ProjectSelectedState, "projectId"> & {
    searchParams: URLSearchParams;
  }
>;

export function useProjectSelectedState(): {
  searchParams: URLSearchParams;
  selected: ProjectSelectedState;
  setSelected: (
    newSelected: ProjectSelectedStateUpdate,
    options?: NavigateOptions
  ) => void;
} {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedProjectId,
    selectedBranchName: rawSelectedBranchName,
    selectedCommitId,
    selectedView,
  } = useParams();
  const selectedBranchName = decodeURIComponent(rawSelectedBranchName ?? "");
  const view = (selectedView ?? "logic") as ProjectView;

  const setSelected = useCallback(
    (newSelected: ProjectSelectedStateUpdate, options?: NavigateOptions) => {
      if (Object.keys(newSelected).length === 0) {
        return;
      }

      const newSelectedView = newSelected.view ?? view;
      const newSearchParams = new URLSearchParams({
        ...Object.fromEntries(
          Object.entries(Object.fromEntries(searchParams)).filter(
            ([param]) => param !== skipNavigationBlockerKey
          )
        ),
        ...(newSelected.view && newSelectedView === "pull-requests"
          ? { [selectedPRIdUrlKey]: "0" }
          : {}),
        ...Object.fromEntries(newSelected.searchParams ?? []),
      });

      if (Object.keys(newSelected).length === 1 && newSelected.searchParams) {
        setSearchParams(newSearchParams);
        return;
      }

      navigate(
        getProjectUrl({
          projectId: selectedProjectId!,
          selectedBranchName: newSelected.branchName ?? selectedBranchName,
          selectedCommitId: newSelected.commitId ?? selectedCommitId,
          selectedView: newSelectedView,
          searchParams: newSearchParams,
        }),
        options
      )?.catch((error) => console.error("failed to navigate", { error }));
    },
    [
      navigate,
      searchParams,
      selectedBranchName,
      selectedCommitId,
      selectedProjectId,
      setSearchParams,
      view,
    ]
  );

  return {
    searchParams,
    selected: {
      projectId: selectedProjectId,
      branchName: selectedBranchName,
      commitId: selectedCommitId,
      view,
    },
    setSelected,
  };
}

export function getProjectUrl({
  projectId,
  selectedBranchName = defaultBranchName,
  selectedCommitId = draftCommitId,
  selectedView,
  searchParams,
}: {
  projectId: string;
  selectedBranchName?: string;
  selectedCommitId?: string;
  selectedView: ProjectView;
  searchParams?: URLSearchParams;
}): string {
  return `/projects/${projectId}/${encodeURIComponent(selectedBranchName)}/${selectedCommitId}/${selectedView}${searchParams ? `?${searchParams.toString()}` : ""}`;
}
