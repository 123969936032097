// Generated by ts-to-zod
import { z } from "zod";
import {
  type ValueType,
  type ListValueType,
  type FunctionValueType,
  type VariableDefinitions,
  type Value,
  type ObjectValue,
  type ValueWithVariables,
  type ObjectValueWithVariables,
  type QueryObjectValueWithVariables,
  type InlineFragmentObjectValueWithVariables,
  type FieldQueryObjectValueWithVariables,
  type FragmentObjectValueWithVariables,
  type SelectionObjectValueWithVariables,
  type InitQuery,
  type InitRequestBody,
  type GraphqlRequestBody,
  LogLevel,
  LogType,
  DimensionType,
} from "@hypertune/sdk/src/shared/types";

export const voidValueTypeSchema = z.object({
  type: z.literal("VoidValueType"),
});

export const booleanValueTypeSchema = z.object({
  type: z.literal("BooleanValueType"),
});

export const intValueTypeSchema = z.object({
  type: z.literal("IntValueType"),
});

export const floatValueTypeSchema = z.object({
  type: z.literal("FloatValueType"),
});

export const stringValueTypeSchema = z.object({
  type: z.literal("StringValueType"),
});

export const regexValueTypeSchema = z.object({
  type: z.literal("RegexValueType"),
});

export const enumValueTypeSchema = z.object({
  type: z.literal("EnumValueType"),
  enumTypeName: z.string(),
});

export const objectValueTypeSchema = z.object({
  type: z.literal("ObjectValueType"),
  objectTypeName: z.string(),
});

export const unionValueTypeSchema = z.object({
  type: z.literal("UnionValueType"),
  unionTypeName: z.string(),
});

export const fragmentSpreadSchema = z.object({
  type: z.literal("FragmentSpread"),
  fragmentName: z.string(),
});

export const queryVariableSchema = z.object({
  __isVariable: z.literal(true),
  name: z.string(),
});

export const sdkTypeSchema = z.union([
  z.literal("js"),
  z.literal("python"),
  z.literal("rust"),
  z.literal("go"),
]);

export const jsLanguageSchema = z.union([
  z.literal("ts"),
  z.literal("js"),
  z.literal("mjs"),
  z.literal("cjs"),
]);

export const codegenFrameworkSchema = z.union([
  z.literal("nextApp"),
  z.literal("nextPages"),
  z.literal("react"),
  z.literal("reactNative"),
  z.literal("remix"),
  z.literal("gatsby"),
  z.literal("vue"),
]);

export const codegenPlatformSchema = z.literal("vercel");

export const languageSchema = z.union([
  jsLanguageSchema,
  z.literal("python"),
  z.literal("rust"),
  z.literal("go"),
]);

export const schemaRequestBodySchema = z.object({
  schemaVersion: z.string().optional(),
  optionalInputTypes: z.boolean().optional(),
  introspection: z.boolean().optional(),
});

export const evaluationCountInputSchema = z.object({
  commitId: z.string(),
  count: z.number(),
  expressionId: z.string(),
});

export const eventInputSchema = z.object({
  commitId: z.string(),
  createdAt: z.string(),
  eventObjectTypeName: z.string().optional().nullable(),
  eventPayloadJson: z.string().optional().nullable(),
  eventTypeId: z.string().optional().nullable(),
  unitId: z.string().optional().nullable(),
});

export const logLevelSchema = z.nativeEnum(LogLevel);

export const logTypeSchema = z.nativeEnum(LogType);

export const dimensionTypeSchema = z.nativeEnum(DimensionType);

export const codegenRequestBodySchema = z.object({
  query: z.string().nullable(),
  framework: codegenFrameworkSchema.optional(),
  platform: codegenPlatformSchema.optional(),
  packageName: z.string().optional(),
  clientFileName: z.string().optional(),
  getHypertuneImportPath: z.string().optional(),
  includeToken: z.boolean().optional(),
  includeFallback: z.boolean().optional(),
  includeToolbar: z.boolean().optional(),
  sdkType: sdkTypeSchema,
  sdkVersion: z.string(),
  language: languageSchema,
});

export const logInputSchema = z.object({
  commitId: z.string().optional().nullable(),
  createdAt: z.string(),
  level: logLevelSchema,
  message: z.string(),
  metadataJson: z.string(),
  type: logTypeSchema,
});

export const assignmentInputSchema = z.object({
  continuousValue: z.number().optional().nullable(),
  dimensionId: z.string(),
  discreteArmId: z.string().optional().nullable(),
  entryType: dimensionTypeSchema,
});

export const exposureInputSchema = z.object({
  assignment: z.array(assignmentInputSchema),
  commitId: z.string(),
  createdAt: z.string(),
  eventObjectTypeName: z.string().optional().nullable(),
  eventPayloadJson: z.string().optional().nullable(),
  splitId: z.string(),
  unitId: z.string(),
});

export const createLogsInputSchema = z.object({
  evaluations: z.array(evaluationCountInputSchema),
  events: z.array(eventInputSchema),
  exposures: z.array(exposureInputSchema),
  idempotencyKey: z.string(),
  logs: z.array(logInputSchema),
  token: z.string(),
});

export const valueTypeSchema: z.ZodSchema<ValueType> = z.lazy(() =>
  z.union([
    voidValueTypeSchema,
    booleanValueTypeSchema,
    intValueTypeSchema,
    floatValueTypeSchema,
    stringValueTypeSchema,
    regexValueTypeSchema,
    enumValueTypeSchema,
    objectValueTypeSchema,
    unionValueTypeSchema,
    listValueTypeSchema,
    functionValueTypeSchema,
  ])
);

export const listValueTypeSchema: z.ZodSchema<ListValueType> = z.lazy(() =>
  z.object({
    type: z.literal("ListValueType"),
    itemValueType: valueTypeSchema,
  })
);

export const functionValueTypeSchema: z.ZodSchema<FunctionValueType> = z.lazy(
  () =>
    z.object({
      type: z.literal("FunctionValueType"),
      parameterValueTypes: z.array(valueTypeSchema),
      returnValueType: valueTypeSchema,
    })
);

export const variableDefinitionsSchema: z.ZodSchema<VariableDefinitions> =
  z.lazy(() =>
    z.record(
      z.object({
        valueType: valueTypeSchema,
        defaultValue: valueSchema.optional(),
      })
    )
  );

export const valueSchema: z.ZodSchema<Value> = z.lazy(() =>
  z.union([
    z.boolean(),
    z.number(),
    z.string(),
    objectValueSchema,
    z.array(valueSchema),
  ])
);

export const objectValueSchema: z.ZodSchema<ObjectValue> = z.lazy(() =>
  z.record(valueSchema)
);

export const valueWithVariablesSchema: z.ZodSchema<ValueWithVariables> = z.lazy(
  () =>
    z.union([
      valueSchema,
      queryVariableSchema,
      z.array(valueWithVariablesSchema),
      objectValueWithVariablesSchema,
    ])
);

export const objectValueWithVariablesSchema: z.ZodSchema<ObjectValueWithVariables> =
  z.lazy(() => z.record(valueWithVariablesSchema));

export const queryObjectValueWithVariablesSchema: z.ZodSchema<QueryObjectValueWithVariables> =
  z.lazy(() =>
    z.object({
      name: z.string().optional(),
      variableDefinitions: variableDefinitionsSchema,
      fragmentDefinitions: z.record(
        inlineFragmentObjectValueWithVariablesSchema
      ),
      fieldQuery: fieldQueryObjectValueWithVariablesSchema,
    })
  );

export const inlineFragmentObjectValueWithVariablesSchema: z.ZodSchema<InlineFragmentObjectValueWithVariables> =
  z.lazy(() =>
    z.object({
      type: z.literal("InlineFragment"),
      objectTypeName: z.string(),
      selection: selectionObjectValueWithVariablesSchema,
    })
  );

export const fieldQueryObjectValueWithVariablesSchema: z.ZodSchema<FieldQueryObjectValueWithVariables> =
  z.lazy(() => z.record(fragmentObjectValueWithVariablesSchema));

export const fragmentObjectValueWithVariablesSchema: z.ZodSchema<FragmentObjectValueWithVariables> =
  z.lazy(() =>
    z.union([
      inlineFragmentObjectValueWithVariablesSchema,
      fragmentSpreadSchema,
    ])
  );

export const selectionObjectValueWithVariablesSchema: z.ZodSchema<SelectionObjectValueWithVariables> =
  z.lazy(() =>
    z.record(
      z.object({
        fieldArguments: objectValueWithVariablesSchema,
        fieldQuery: fieldQueryObjectValueWithVariablesSchema.nullable(),
      })
    )
  );

export const initQuerySchema: z.ZodSchema<InitQuery> = z.lazy(() =>
  z.union([
    z.object({
      type: z.literal("Query"),
      query: queryObjectValueWithVariablesSchema.nullable(),
    }),
    z.object({
      type: z.literal("GraphqlQuery"),
      code: z.string(),
    }),
    z.object({
      type: z.literal("StoredQuery"),
      id: z.string(),
    }),
  ])
);

export const initRequestBodySchema: z.ZodSchema<InitRequestBody> = z.lazy(() =>
  z.object({
    query: z.union([z.string(), initQuerySchema]),
    variables: objectValueSchema,
    sdkType: sdkTypeSchema,
    sdkVersion: z.string(),
  })
);

export const graphqlRequestBodySchema: z.ZodSchema<GraphqlRequestBody> = z.lazy(
  () =>
    z.object({
      query: z.string(),
      variables: objectValueSchema.optional(),
      schemaVersion: z.string().optional(),
    })
);
