import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAuth, signInWithCustomToken } from "firebase/auth";

import ContainerWithLogo from "../../components/container/ContainerWithLogo";
import getErrorMessage from "../../lib/query/getErrorMessage";
import useLoginRedirectQuery from "../../lib/hooks/useLoginRedirectQuery";

export default function LoginWithTokenPage(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const auth = getAuth();

  const redirect = useLoginRedirectQuery();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const login = useCallback(
    async function login(): Promise<void> {
      if (!token) {
        throw new Error("No token.");
      }

      await signInWithCustomToken(auth, token);
      await redirect();
    },
    [token, auth, redirect]
  );

  useEffect(() => {
    document.title = "Logging in...";

    login().catch((loginError) => {
      setErrorMessage(getErrorMessage(loginError));
    });
  }, [login]);

  return (
    <ContainerWithLogo>
      <div style={{ textAlign: "center" }}>
        {errorMessage || "Logging in..."}
      </div>
    </ContainerWithLogo>
  );
}
