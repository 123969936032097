import React, { useState } from "react";
import { nullThrows, fieldPathSeparator } from "@hypertune/sdk/src/shared";
import {
  FunnelStep,
  getAllValuePathsForObject,
} from "@hypertune/shared-internal";
import Skeleton from "react-loading-skeleton";
import { Pencil } from "@phosphor-icons/react";
import { FrequentistMetricsResult } from "@hypertune/sdk/src/shared/helpers/calculateFrequentistMetrics";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import getFunnelSegmentDataKey from "../../../../lib/analytics/getFunnelSegmentDataKey";
import {
  borderRadiusPx,
  defaultStepHeight,
  intentPrimaryHex,
} from "../../../../lib/constants";
import { CommitContext, FunnelSegmentData } from "../../../../lib/types";
import Segment from "./Segment";
import { sum } from "../../../../lib/generic/math";
import DeleteButton from "../../../../components/buttons/DeleteButton";
import SelectFilter from "./SelectFilter";
import SelectAggregation from "./SelectAggregation";
import MultiSelect from "./MultiSelect";
import DerivedFields from "./DerivedFields";
import getPayloadPathString, {
  getStartCasePayloadPathString,
} from "../../../../lib/getPayloadPathString";
import Button from "../../../../components/buttons/Button";
import AddOrEditStepModal from "../AddOrEditStepModal";
import getFunnelStepPayloadObjectTypeName from "../../../../lib/getFunnelStepPayloadObjectTypeName";

const payloadPathPrefix = `payload${fieldPathSeparator}`;

export default function Step({
  meId,
  canEdit,
  commitContext,
  steps,
  setSteps,
  segmentDataMap,
  maxStepCount,
  index,
  data,
  focusModeEnabled,
  frequentistMetrics,
  bayesianProbabilities,
  controlSegmentIndex,
}: {
  meId: string;
  canEdit: boolean;
  commitContext: CommitContext;
  steps: FunnelStep[];
  setSteps: (newSteps: FunnelStep[]) => void;
  segmentDataMap: { [segmentDataKey: string]: FunnelSegmentData };
  maxStepCount: number;
  index: number;
  data: FunnelSegmentData[];
  focusModeEnabled: boolean;
  frequentistMetrics: { [index: number]: FrequentistMetricsResult } | null;
  bayesianProbabilities: number[] | null;
  controlSegmentIndex: number;
}): React.ReactElement {
  const [isEditing, setIsEditing] = useState(false);

  const step = steps[index];
  const stepCount = sum(data.map((segmentData) => segmentData.count));
  const baseStepHeight = defaultStepHeight + (focusModeEnabled ? 100 : 0);
  const stepHeight =
    ((steps.length + 1 - index) / (steps.length + 1)) * baseStepHeight;

  return (
    <>
      <StepContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            minHeight: stepHeight,
            height: stepHeight,
            marginTop: baseStepHeight - stepHeight,
          }}
        >
          <div style={{ flexGrow: 1 }} />
          {data.map((segmentData, segmentIndex) => (
            <Segment
              style={
                segmentIndex < 1
                  ? {
                      borderRadius: `${borderRadiusPx}px ${borderRadiusPx}px 0 0`,
                    }
                  : {}
              }
              maxStepCount={maxStepCount}
              stepCount={stepCount}
              numSegments={data.length}
              stepHeight={stepHeight}
              data={segmentData}
              parent={
                index > 0
                  ? nullThrows(
                      segmentDataMap[
                        getFunnelSegmentDataKey(
                          segmentData.breakdownPathValuesList.slice(0, -1),
                          segmentData.assignmentList.slice(0, -1)
                        )
                      ],
                      "no parent segment"
                    )
                  : null
              }
              frequentistMetrics={
                frequentistMetrics && controlSegmentIndex !== segmentIndex
                  ? frequentistMetrics[segmentIndex]
                  : null
              }
              bayesianProbability={
                bayesianProbabilities && controlSegmentIndex !== segmentIndex
                  ? bayesianProbabilities[segmentIndex]
                  : null
              }
            />
          ))}
        </div>
        <div className="mt-3 flex flex-col items-stretch">
          {canEdit && !focusModeEnabled && (
            <div className="mb-3 flex flex-row items-center justify-center gap-2">
              <DeleteButton
                onClick={() => {
                  setSteps([
                    ...steps.slice(0, index),
                    ...steps.slice(index + 1),
                  ]);
                }}
              />
              {(Object.keys(commitContext.eventTypes).length > 0 ||
                Object.keys(commitContext.splits).length > 0) && (
                <Button
                  icon={<Pencil color={intentPrimaryHex} />}
                  weight="minimal"
                  intent="primary"
                  onClick={() => setIsEditing(true)}
                />
              )}
            </div>
          )}
          <div>
            <p className="mb-1 text-center font-semibold last:mb-3">
              {getStepLabel(commitContext, step)}
            </p>
            {!focusModeEnabled && step.type === "FunnelEventStep" && (
              <p className="mb-3 text-center text-tx-muted">
                <strong>Unit ID:</strong> Payload{fieldPathSeparator}
                {step.unitIdPayloadPath
                  .map(toStartCase)
                  .join(fieldPathSeparator)}
              </p>
            )}
          </div>

          {!focusModeEnabled && (
            <StepBreakdowns
              meId={meId}
              canEdit={canEdit}
              commitContext={commitContext}
              step={step}
              setStep={(newStep: FunnelStep): void => {
                setSteps([
                  ...steps.slice(0, index),
                  newStep,
                  ...steps.slice(index + 1),
                ]);
              }}
            />
          )}
        </div>
      </StepContainer>
      {isEditing && (
        <AddOrEditStepModal
          commitContext={commitContext}
          steps={steps}
          setSteps={setSteps}
          index={index}
          insert={false}
          onClose={() => setIsEditing(false)}
        />
      )}
    </>
  );
}

function StepContainer({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement | null {
  return (
    <div className="flex min-w-[170px] max-w-[170px] flex-col items-stretch rounded-xl border border-bd-darker bg-white p-5">
      {children}
    </div>
  );
}

function getStepLabel(commitContext: CommitContext, step: FunnelStep): string {
  if (step.type === "FunnelEventStep") {
    return toStartCase(step.eventObjectTypeName);
  }
  const split = commitContext.splits[step.splitId];
  return split ? split.name : "Unknown split";
}

function StepBreakdowns({
  meId,
  canEdit,
  commitContext,
  step,
  setStep,
}: {
  meId: string;
  canEdit: boolean;
  commitContext: CommitContext;
  step: FunnelStep;
  setStep: (newStep: FunnelStep) => void;
}): React.ReactElement {
  const { schema, splits } = commitContext;
  const payloadObjectTypeName = getFunnelStepPayloadObjectTypeName(
    commitContext,
    step
  );
  const unitIdPath = (
    step.type === "FunnelEventStep"
      ? ["payload", ...step.unitIdPayloadPath]
      : step.unitId.type === "payloadPath"
        ? ["payload", ...step.unitId.payloadPath]
        : []
  ).join(fieldPathSeparator);

  const selectedBreakdowns: Record<string, true> = Object.fromEntries(
    step.breakdowns?.map((field) => [
      field.type === "payloadPath"
        ? getPayloadPathString(field.payloadPath)
        : field.fieldName,
      true,
    ]) ?? []
  );

  return (
    <div className="flex flex-col items-stretch gap-3 rounded-lg border px-3 py-2">
      <SelectFilter
        meId={meId}
        stepType={step.type}
        payloadObjectTypeName={payloadObjectTypeName}
        schema={commitContext.schema}
        filter={step.filter}
        setFilter={(newFilter) =>
          setStep({
            ...step,
            filter: newFilter,
          })
        }
        canEdit={canEdit}
      />
      {payloadObjectTypeName && (
        <DerivedFields
          meId={meId}
          stepType={step.type}
          payloadObjectTypeName={payloadObjectTypeName}
          schema={commitContext.schema}
          step={step}
          setStep={setStep}
          canEdit={canEdit}
        />
      )}
      {step.type === "FunnelExposureStep" ? (
        <MultiSelect
          canEdit={canEdit}
          noun="dimension"
          options={
            splits[step.splitId]
              ? Object.fromEntries(
                  Object.values(splits[step.splitId].dimensions).map(
                    (dimension) => [dimension.id, dimension.name]
                  )
                )
              : {}
          }
          selectedIds={step.dimensionIds}
          setSelectedIds={(dimensionIds) => setStep({ ...step, dimensionIds })}
        />
      ) : null}
      {payloadObjectTypeName && (
        <MultiSelect
          canEdit={canEdit}
          noun="breakdown"
          options={Object.fromEntries(
            getAllValuePathsForObject(schema, payloadObjectTypeName)
              .map((path) => [
                getPayloadPathString(path),
                getStartCasePayloadPathString(path),
              ])
              .filter(([path]) => path !== unitIdPath)
              .concat(
                step.derivedFields?.map(({ name }) => [
                  name,
                  toStartCase(name),
                ]) ?? []
              )
          )}
          selectedIds={selectedBreakdowns}
          setSelectedIds={(breakdowns) =>
            setStep({
              ...step,
              breakdowns: Object.keys(breakdowns).map((breakdown) =>
                breakdown.startsWith(payloadPathPrefix)
                  ? {
                      type: "payloadPath",
                      payloadPath: breakdown.split(fieldPathSeparator).slice(1),
                    }
                  : {
                      type: "derivedField",
                      fieldName: breakdown,
                    }
              ),
            })
          }
        />
      )}

      <SelectAggregation
        payloadObjectTypeName={payloadObjectTypeName}
        schema={commitContext.schema}
        aggregations={step.aggregations}
        setAggregations={(newAggregations) =>
          setStep({
            ...step,
            aggregations: newAggregations,
          })
        }
        canEdit={canEdit}
        derivedFields={step.derivedFields ?? []}
      />
    </div>
  );
}

Step.LoadingSkeleton = function ({
  focusModeEnabled,
}: {
  focusModeEnabled: boolean;
}): React.ReactElement {
  return (
    <StepContainer>
      <Skeleton height={defaultStepHeight} />
      <Skeleton
        height={13}
        className={!focusModeEnabled ? "mt-[60.5px]" : "mt-3"}
      />
      {!focusModeEnabled && <Skeleton height={160} className="mt-3" />}
    </StepContainer>
  );
};
