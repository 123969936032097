import React, { useEffect, useRef, useState } from "react";
import {
  formatSchemaCode,
  getFullSchemaCode,
  queryObjectTypeName,
  sourceObjectTypeName,
} from "@hypertune/shared-internal";
import CodeEditor from "../../../components/CodeEditor";
import ErrorMessage from "../../../components/ErrorMessage";
import Button from "../../../components/buttons/Button";
import { SelectedType } from "./schemaHooks";
import { useAppDispatch } from "../../../app/hooks";
import { useHypertune } from "../../../generated/hypertune.react";
import {
  formatDraftCommitSchemaCode,
  setDraftCommitEditableSchemaCode,
  setDraftCommitReadonlySchemaCode,
} from "../projectSlice";

const lineHeight = 19.5;
const codeEditorTopPadding = 8;
const codeEditorId = "schema-code-editor";

export default function SchemaCodeEditor({
  isVisible,
  readOnlySchemaCode,
  editableSchemaCode,
  selectedType,
  errorMessage,
  readOnly,
}: {
  isVisible: boolean;
  readOnlySchemaCode: string;
  editableSchemaCode: string;
  selectedType: SelectedType | null;
  errorMessage?: string;
  readOnly: boolean;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const hypertune = useHypertune();
  const canEditFullSchema = hypertune
    .app()
    .allowFullSchemaEdit({ fallback: false });

  const [scrolledOnShow, setScrolledOnShow] = useState(false);
  const [scrolledToType, setScrolledToType] = useState<{
    name: string;
    count?: number;
  } | null>(null);

  const fullSchemaCode = getFullSchemaCode(
    readOnlySchemaCode,
    editableSchemaCode
  );
  const schemaCodeLines = fullSchemaCode.split("\n");
  const schemaCodeCleanLines = schemaCodeLines.map((line) =>
    line.toLowerCase().replaceAll(" ", "")
  );
  const schemaCodeLinesEnds = schemaCodeLines.map((line) => line.length);
  schemaCodeLinesEnds.reduce((totalLength, length, index) => {
    schemaCodeLinesEnds[index] = totalLength + length + 1;
    return schemaCodeLinesEnds[index];
  }, -readOnlySchemaCode.length - 3);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isVisible) {
      setScrolledOnShow(false);
      setScrolledToType(null);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && selectedType === null) {
      setScrolledOnShow(true);
    }
    if (
      !isVisible ||
      selectedType === null ||
      (selectedType.name === scrolledToType?.name &&
        selectedType.count === scrolledToType?.count)
    ) {
      return;
    }
    const selectedTypePrefix = `${
      selectedType.type === "object"
        ? "type"
        : selectedType.type === "event"
          ? "input"
          : selectedType.type
    }${selectedType.name.toLocaleLowerCase()}`;

    const typeIndex = schemaCodeCleanLines.findIndex((line) =>
      line.startsWith(selectedTypePrefix)
    );
    if (typeIndex === -1) {
      return;
    }
    const el = ref.current;
    if (el && el.scrollTo) {
      el.scrollTo({
        top: (typeIndex - 2) * lineHeight + codeEditorTopPadding,
        behavior: scrolledOnShow ? "smooth" : undefined,
      });
    }
    const textareaEl = document.getElementById(
      codeEditorId
    ) as HTMLTextAreaElement | null;
    if (textareaEl) {
      textareaEl.select();
      textareaEl.focus();

      textareaEl.selectionStart = schemaCodeLinesEnds[typeIndex];
      textareaEl.selectionEnd = schemaCodeLinesEnds[typeIndex];
    }
    setScrolledOnShow(true);
    setScrolledToType({ name: selectedType.name, count: selectedType.count });
  }, [
    isVisible,
    scrolledOnShow,
    selectedType,
    scrolledToType,
    schemaCodeLinesEnds,
    schemaCodeCleanLines,
  ]);

  return (
    <div
      className={`${
        isVisible ? "flex" : "hidden"
      } flex-grow flex-col overflow-hidden`}
    >
      <div className="flex flex-row items-center justify-between border-b border-bd-darker px-3 py-[7.5px]">
        <ErrorMessage
          errorMessage={errorMessage}
          noErrorsMessage="No schema errors."
        />
        <Button
          weight="outlined"
          text="Format"
          size="small"
          onClick={() => {
            dispatch(formatDraftCommitSchemaCode());
          }}
          disabled={
            !!errorMessage ||
            fullSchemaCode === "" ||
            formatSchemaCode(fullSchemaCode) === fullSchemaCode
          }
        />
      </div>
      <div
        ref={ref}
        style={{
          overflowY: "auto",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {readOnlySchemaCode && (
          <CodeEditor
            textareaId={`${codeEditorId}query`}
            code={
              canEditFullSchema
                ? readOnlySchemaCode
                : readOnlySchemaCode.replace(
                    queryObjectTypeName,
                    sourceObjectTypeName
                  )
            }
            setCode={
              canEditFullSchema
                ? (newCode) =>
                    dispatch(setDraftCommitReadonlySchemaCode(newCode))
                : () => {
                    // noop
                  }
            }
            language="graphql"
            readOnly={!canEditFullSchema}
            className="bg-bg-medium"
            textAreaClassName={
              canEditFullSchema ? undefined : "cursor-not-allowed"
            }
          />
        )}
        <CodeEditor
          textareaId={codeEditorId}
          code={editableSchemaCode}
          setCode={(newCode) =>
            dispatch(setDraftCommitEditableSchemaCode(newCode))
          }
          language="graphql"
          readOnly={readOnly}
          style={{
            paddingBottom: "100vh",
          }}
        />
      </div>
    </div>
  );
}
