import {
  highlight,
  languages as prismLanguages,
} from "prismjs/components/prism-core";
import "prismjs/components/prism-graphql";
import "prismjs/components/prism-json";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-markdown";
import "prismjs/components/prism-clike"; // Necessary for prism-javascript
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-python";
import "prismjs/components/prism-rust";
import "prismjs/components/prism-csv";
import "prismjs/components/prism-go";
import "prismjs/themes/prism-solarizedlight.css";
import React from "react";
import Editor from "react-simple-code-editor";
import {
  interFontFamily,
  mediumFontSize,
  monospaceFontFamily,
} from "../lib/constants";
import CopyButton from "./buttons/CopyButton";
import TopBarDropdown from "./TopBarDropdown";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { setTabValue } from "./tabsSlice";
import removeNonBreakingSpaces from "../lib/expression/removeNonBreakingSpaces";

export const languages = [
  "graphql",
  "json",
  "csv",
  "markup",
  "markdown",
  "typescript",
  "javascript",
  "bash",
  "python",
  "rust",
  "go",
] as const;
export type Language = (typeof languages)[number];

export type Tab = {
  label: string;
  value: string;
};

export default function CodeEditor({
  textareaId,
  code,
  setCode,
  language,
  readOnly = false,
  selectAll = false,
  padding = { left: 12, right: 12, top: 8, bottom: 8 },
  style = {},
  fileName,
  showButtons,
  actions,
  className,
  wrapperClassName,
  textAreaClassName,
  disableCopy,
}: {
  textareaId?: string;
  code: string;
  setCode: (newCode: string) => void;
  language: Language;
  readOnly?: boolean;
  selectAll?: boolean;
  padding?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  fileName?: string;
  showButtons?: "on-hover" | "always";
  actions?: React.ReactNode;
  style?: Omit<React.CSSProperties, "padding">;
  className?: string;
  wrapperClassName?: string;
  textAreaClassName?: string;
  disableCopy?: boolean;
}): React.ReactElement {
  return (
    // The wrapper div ensures the editor can scroll if it's in a constrained
    // height space, and ensures it takes up the space for click events if it's
    // in an unconstrained height space
    <div className={`group h-full flex-grow ${className || ""}`}>
      {readOnly && showButtons && (!disableCopy || actions) && (
        <div
          className={`flex w-full flex-row bg-white ${
            fileName
              ? "items-center justify-between border-b px-3 py-2 leading-normal"
              : "justify-end"
          }`}
          style={
            fileName
              ? {
                  fontFamily: interFontFamily,
                  fontWeight: 520,
                }
              : undefined
          }
        >
          {fileName || null}
          <div
            className={
              fileName
                ? ""
                : `relative z-100 -mb-[28px] -translate-x-1 translate-y-1 ${
                    showButtons === "on-hover" ? "hidden group-hover:block" : ""
                  }`
            }
          >
            <div className="flex flex-row gap-2">
              {actions || null}
              {!disableCopy && <CopyButton text={code} />}
            </div>
          </div>
        </div>
      )}
      <div className={wrapperClassName}>
        <Editor
          textareaId={textareaId}
          value={code}
          readOnly={readOnly}
          onValueChange={(newCode) => setCode(sanitizeCode(newCode))}
          // eslint-disable-next-line no-shadow
          highlight={(code) => highlight(code, prismLanguages[language])}
          padding={padding}
          style={{
            fontFamily: monospaceFontFamily,
            fontSize: mediumFontSize,
            outline: "none",
            boxShadow: "none",
            minHeight: "100%",
            flexGrow: 1,
            ...style,
          }}
          textareaClassName={textAreaClassName}
          onClick={
            selectAll
              ? (event) => {
                  const element = event.currentTarget;
                  if (!element || !("select" in element)) {
                    return;
                  }
                  element.select();
                }
              : undefined
          }
        />
      </div>
    </div>
  );
}

export function TabbedCodeEditor({
  tabs,
  language,
  className,
  wrapperClassName,
  textAreaClassName,
  disableCopy,
}: {
  tabs: Tab[];
  language: Language;
  className?: string;
  wrapperClassName?: string;
  textAreaClassName?: string;
  disableCopy?: boolean;
}): React.ReactElement {
  const tabKey = `code-tabs:${tabs.map((tab) => tab.label).join("-")}`;
  const dispatch = useAppDispatch();
  const selectedTabLabel = useAppSelector(
    (state) => state.tabs[tabKey] || tabs[0].label
  );
  const selectedTab = tabs.find((tab) => tab.label === selectedTabLabel);

  return (
    <CodeEditor
      code={selectedTab?.value || ""}
      readOnly
      selectAll
      setCode={() => {
        // Dummy
      }}
      language={language}
      showButtons="always"
      className={className}
      wrapperClassName={wrapperClassName}
      textAreaClassName={textAreaClassName}
      actions={
        <TopBarDropdown
          value={selectedTab || null}
          placeholder=""
          options={{
            type: "options",
            options: tabs,
          }}
          onChange={(newOption) => {
            if (newOption) {
              dispatch(setTabValue({ key: tabKey, value: newOption.label }));
            }
          }}
          dropdownStyle={{
            minWidth: 0,
            hideSearch: tabs.length < 10,
            sectionMaxHeight: 140,
            muted: "all",
            caret: "down",
            panelClassName: "pt-1 data-top:pb-1",
            optionClassName: "font-medium py-[7px] gap-0",
            buttonClassName:
              "bg-white border border-intent-neutral/10 rounded-lg py-[3.5px] px-[7px] gap-0 font-medium shadow-button",
          }}
        />
      }
      disableCopy={disableCopy}
    />
  );
}

function sanitizeCode(code: string): string {
  return removeNonBreakingSpaces(code.replace(/[“”]/g, '"'));
}
