import {
  blackHex,
  borderRadiusPx,
  normal,
  small,
  greyHex,
  smallFontSize,
  singlePanelHeight,
  navyBlueHex,
  blueHexBoxShadow,
} from "../../../../lib/constants";
import ErrorCircle from "../../../../components/icons/ErrorCircle";
import {
  Intent,
  intentToHexColor,
  intentToLightBgClassName,
  intentToTextColorName,
} from "../../../../components/intent";
import { ExpressionControlContext } from "../../../../lib/types";
import { useHypertune } from "../../../../generated/hypertune.react";

export default function Panel({
  header,
  message,
  intent = "neutral",
  showErrorIcon,
  isSelected = false,
  childContainer = "normal",
  shouldStack,
  children,
  expressionId,
  disablePanelOnSelect,
  context,
}: {
  header: {
    component: React.ReactNode;
    options: React.ReactNode[];
  } | null;
  message: string | null;
  /** Whether to enable the child container's styles (e.g. the border and padding). */
  childContainer?: "normal" | "small" | "disabled";
  intent?: Intent;
  showErrorIcon?: boolean;
  isSelected?: boolean;
  shouldStack: boolean;
  children: React.ReactNode;
  expressionId: string;
  disablePanelOnSelect?: boolean;
  context: ExpressionControlContext;
}): React.ReactElement {
  const isExpressionSelectabilityEnabled =
    useHypertune().expressionsSelectability({ fallback: false });
  return (
    <div
      className={`${isExpressionSelectabilityEnabled ? "UIExpressionWrapper panel" : ""} relative flex flex-col ${intentToTextColorName(intent)} ${shouldStack ? "" : "rounded-lg"}`}
      style={{
        border: `${childContainer === "disabled" ? "0px" : "1px solid"}
        ${
          intent !== "neutral"
            ? intentToHexColor(intent)
            : context.expressionEditorState.selectedItem?.id === expressionId &&
                !disablePanelOnSelect
              ? navyBlueHex
              : greyHex
        }`,
        boxShadow:
          isSelected && childContainer !== "disabled" && !disablePanelOnSelect
            ? blueHexBoxShadow
            : "none",
      }}
      onMouseDown={(event) => {
        if (isExpressionSelectabilityEnabled) {
          context.setExpressionEditorState({
            ...context.expressionEditorState,
            selectedItem: { type: "expression", id: expressionId },
          });
          event.stopPropagation();
        }
      }}
    >
      {header ? (
        <div
          className={intentToLightBgClassName(intent)}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: small,
            paddingBottom: small,
            paddingLeft: normal,
            paddingRight: normal,
            borderWidth:
              childContainer === "disabled"
                ? "1px 1px 0px 1px"
                : "0px 0px 1px 0px",
            borderStyle: "solid",
            borderColor:
              intent !== "neutral" ? intentToHexColor(intent) : greyHex,
            borderRadius: shouldStack
              ? 0
              : `${borderRadiusPx}px ${borderRadiusPx}px 0 0`,
            minHeight: singlePanelHeight,
          }}
        >
          {showErrorIcon ? (
            <div
              style={{
                marginRight: normal,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ErrorCircle />
            </div>
          ) : null}
          {header.component}
          <div style={{ flexGrow: 1, minWidth: normal }} />
          {header.options.length > 0 ? (
            <>
              {header.options.map((option) => (
                <div style={{ marginLeft: small }}>{option}</div>
              ))}
            </>
          ) : null}
        </div>
      ) : null}
      {message ? (
        <div
          className={intentToLightBgClassName(intent)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            borderWidth:
              childContainer === "disabled"
                ? "1px 1px 0px 1px"
                : "0px 0px 1px 0px",
            borderStyle: "solid",
            borderColor:
              intent !== "neutral" ? intentToHexColor(intent) : greyHex,
            borderRadius:
              shouldStack || header
                ? 0
                : `${borderRadiusPx}px ${borderRadiusPx}px 0 0`,
            color: intent !== "neutral" ? intentToHexColor(intent) : blackHex,
            padding: `${small}px ${normal}px`,
            // To ensure it matches up with the header / content box, avoiding:
            // -----------
            // | Message |
            // ----------------
            // | Content      |
            // ----------------
            minWidth: "100%",
            // To set a limit where it will wrap correctly while allowing it to
            // collapse to minWidth. Previously used width of 0 to avoid it
            // expanding beyond where necessary, but this resulted in crunched
            // text on small expression components like toggles.
            // See https://linear.app/hypertune/issue/HYP-166
            maxWidth: "225px",
            overflowWrap: "break-word",
            fontSize: smallFontSize,
          }}
        >
          {message}
        </div>
      ) : null}
      <div
        className={
          childContainer !== "disabled"
            ? intentToLightBgClassName(intent)
            : undefined
        }
        style={{
          ...(childContainer === "normal"
            ? {
                padding: normal,
              }
            : childContainer === "small"
              ? {
                  paddingTop: small,
                  paddingBottom: small,
                  paddingRight: normal,
                  paddingLeft: normal,
                }
              : {}),

          display: "flex",
          flexDirection: "column",
          borderRadius:
            shouldStack || header || message
              ? `0 0 ${borderRadiusPx}px ${borderRadiusPx}px`
              : borderRadiusPx,
        }}
      >
        {children}
      </div>
    </div>
  );
}
