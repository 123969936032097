import {
  defaultArmKey,
  arithmeticOperators,
  Expression,
  SplitMap,
  Schema,
} from "@hypertune/sdk/src/shared";
import {
  defaultArmLabel,
  unimplementedExpressionErrorMessage,
} from "../constants";
import getComparisonOperators from "./getComparisonOperators";
import getConstraintFromValueType from "./constraint/getConstraintFromValueType";
import getFieldValueType from "./getFieldValueType";
import getSplitErrorMessage from "./getSplitErrorMessage";
import getVariableNames from "./getVariableNames";
import isDefaultArmNeeded from "./isDefaultArmNeeded";
import isValueTypeCompatible from "./constraint/isValueTypeCompatible";
import isValueTypeValid from "./isValueTypeValid";
import valueTypeConstraintToString from "./constraint/valueTypeConstraintToString";
import { ValueTypeConstraint, VariableMap } from "./types";
import valueTypeToString from "../schema/valueTypeToString";

// eslint-disable-next-line max-params
export default function getExpressionErrorMessage(
  schema: Schema,
  splits: SplitMap,
  variables: VariableMap,
  valueTypeConstraint: ValueTypeConstraint,
  parentExpression: Expression | null,
  expression: Expression | null
): string | null {
  if (!expression) {
    return unimplementedExpressionErrorMessage;
  }

  const invalidTags = expression.metadata?.tags
    ? Object.keys(expression.metadata.tags).filter(
        (tagName) => !schema.tags?.[tagName]
      )
    : [];

  if (invalidTags.length > 0) {
    return `Expression has invalid ${invalidTags.length > 1 ? "labels:" : "label: "} ${invalidTags.map((invalidTag) => `"${invalidTag}"`).join(", ")}`;
  }

  if (!isValueTypeValid(schema, expression.valueType)) {
    return `Expression has invalid type (${valueTypeToString(
      expression.valueType
    )}).`;
  }

  if (valueTypeConstraint.type === "ErrorValueTypeConstraint") {
    return "No type constraint.";
  }

  if (
    !isValueTypeCompatible(schema, valueTypeConstraint, expression.valueType)
  ) {
    return `Expression type (${valueTypeToString(
      expression.valueType
    )}) is incompatible with type constraint (${valueTypeConstraintToString(
      valueTypeConstraint
    )}).`;
  }

  switch (expression.type) {
    case "NoOpExpression":
    case "BooleanExpression":
      break;

    case "IntExpression":
    case "FloatExpression":
      if (Number.isNaN(expression.value)) {
        return "Invalid number.";
      }
      break;

    case "StringExpression":
      break;

    case "RegexExpression":
      {
        let isValid = true;
        try {
          // eslint-disable-next-line no-new
          new RegExp(expression.value);
        } catch {
          isValid = false;
        }
        if (!isValid) {
          return "Invalid regex.";
        }
      }
      break;

    case "EnumExpression":
      {
        const schemaEnumValues = Object.fromEntries(
          Object.entries(
            schema.enums[expression.valueType.enumTypeName]?.values || {}
          ).filter(
            ([, valueSchema]) => valueSchema.deprecationReason === undefined
          )
        );

        if (!schemaEnumValues[expression.value]) {
          return `Invalid value for the Enum type "${
            expression.valueType.enumTypeName
          }". The valid values are: ${Object.keys(schemaEnumValues)
            .map((x) => `"${x}"`)
            .join(", ")}.`;
        }
      }
      break;

    case "ObjectExpression":
      {
        const { objectTypeName } = expression;

        if (objectTypeName !== expression.valueType.objectTypeName) {
          return `Object type "${objectTypeName}" is different to value type "${expression.valueType.objectTypeName}".`;
        }

        const schemaObjectFields = schema.objects[objectTypeName]?.fields || {};

        const missingFields: string[] = [];
        Object.keys(schemaObjectFields).forEach((schemaFieldName) => {
          if (typeof expression.fields[schemaFieldName] === "undefined") {
            missingFields.push(schemaFieldName);
          }
        });
        if (missingFields.length > 0) {
          return `Object is missing field${
            missingFields.length !== 1 ? "s" : ""
          }: ${missingFields.map((x) => `"${x}"`).join(", ")}.`;
        }

        const extraFields: string[] = [];
        Object.keys(expression.fields).forEach((fieldName) => {
          if (typeof schemaObjectFields[fieldName] === "undefined") {
            extraFields.push(fieldName);
          }
        });
        if (extraFields.length > 0) {
          return `Object has extra field${
            extraFields.length !== 1 ? "s" : ""
          }: ${extraFields.map((x) => `"${x}"`).join(", ")}.`;
        }
      }
      break;

    case "GetFieldExpression":
      if (!expression.fieldPath) {
        return "No field selected.";
      }

      if (
        expression.object &&
        expression.object.valueType.type === "ObjectValueType"
      ) {
        if (!isValueTypeValid(schema, expression.object.valueType)) {
          return `Referenced object has invalid type (${valueTypeToString(
            expression.object.valueType
          )}).`;
        }

        const fieldValueType = getFieldValueType(
          schema,
          expression.object.valueType,
          expression.fieldPath
        );

        if (!fieldValueType) {
          return `Object with type ${valueTypeToString(
            expression.object.valueType
          )} has no field path "${expression.fieldPath}".`;
        }

        if (
          !isValueTypeCompatible(
            schema,
            getConstraintFromValueType(expression.valueType),
            fieldValueType
          )
        ) {
          return `Field path "${
            expression.fieldPath
          }" of object type ${valueTypeToString(
            expression.object.valueType
          )} has type ${valueTypeToString(
            fieldValueType
          )} which is not compatible with this expression's type (${valueTypeToString(
            expression.valueType
          )}).`;
        }
      }
      break;

    case "UpdateObjectExpression":
      {
        const schemaObjectFields =
          schema.objects[expression.valueType.objectTypeName]?.fields || {};

        const invalidFields: string[] = [];
        Object.keys(expression.updates).forEach((fieldName) => {
          if (!schemaObjectFields[fieldName]) {
            invalidFields.push(fieldName);
          }
        });
        if (invalidFields.length > 0) {
          return `Update has invalid field${
            invalidFields.length !== 1 ? "s" : ""
          }: ${invalidFields.map((x) => `"${x}"`).join(", ")}.`;
        }
      }
      break;

    case "ListExpression":
      break;

    case "SwitchExpression":
      break;

    case "EnumSwitchExpression":
      {
        const schemaEnumValues =
          expression.control &&
          expression.control.valueType.type === "EnumValueType"
            ? schema.enums[expression.control.valueType.enumTypeName]?.values ||
              {}
            : {};

        const missingEnumValues: string[] = [];
        Object.keys(schemaEnumValues).forEach((schemaEnumValue) => {
          if (typeof expression.cases[schemaEnumValue] === "undefined") {
            missingEnumValues.push(schemaEnumValue);
          }
        });
        if (missingEnumValues.length > 0) {
          return `Enum switch is missing case${
            missingEnumValues.length !== 1 ? "s" : ""
          }: ${missingEnumValues.map((x) => `"${x}"`).join(", ")}.`;
        }

        const extraEnumValues: string[] = [];
        Object.keys(expression.cases).forEach((enumValue) => {
          if (!schemaEnumValues[enumValue]) {
            extraEnumValues.push(enumValue);
          }
        });
        if (extraEnumValues.length > 0) {
          return `Enum switch has extra case${
            extraEnumValues.length !== 1 ? "s" : ""
          }: ${extraEnumValues.map((x) => `"${x}"`).join(", ")}.`;
        }
      }
      break;

    case "ComparisonExpression":
      if (expression.a && expression.b) {
        const aValueType = isValueTypeValid(schema, expression.a.valueType)
          ? expression.a.valueType
          : null;

        const operators = getComparisonOperators(aValueType);

        if (!expression.operator || !operators.includes(expression.operator)) {
          return "Invalid operator.";
        }
      }
      break;

    case "ArithmeticExpression":
      if (
        !expression.operator ||
        !arithmeticOperators.includes(expression.operator)
      ) {
        return "Invalid operator.";
      }
      break;

    case "RoundNumberExpression":
    case "StringifyNumberExpression":
    case "StringConcatExpression":
    case "GetUrlQueryParameterExpression":
      break;

    case "SplitExpression":
      {
        if (!expression.splitId) {
          return "No split selected.";
        }

        const split = splits[expression.splitId]
          ? splits[expression.splitId]
          : null;

        if (!split) {
          return `Invalid split ID: ${expression.splitId}.`;
        }
        if (split.archived) {
          return "Completed split can't be used in an expression.";
        }

        const splitErrorMessage = getSplitErrorMessage(schema, split);
        if (splitErrorMessage) {
          return `Split error: ${splitErrorMessage}`;
        }

        if (!expression.dimensionId) {
          return "No dimension selected.";
        }

        const dimension = split.dimensions[expression.dimensionId]
          ? split.dimensions[expression.dimensionId]
          : null;

        if (!dimension) {
          return `Invalid dimension ID "${expression.dimensionId}".`;
        }

        const needDefaultArm = isDefaultArmNeeded(split, dimension);

        const { dimensionMapping } = expression;

        if (dimension.type === "discrete") {
          if (dimensionMapping.type !== "discrete") {
            return "Selected dimension is discrete but dimension mapping isn't.";
          }

          const missingArmNames: string[] = [];
          Object.keys(dimension.arms)
            .concat(needDefaultArm ? [defaultArmKey] : [])
            .forEach((armId) => {
              if (typeof dimensionMapping.cases[armId] === "undefined") {
                missingArmNames.push(
                  armId === defaultArmKey
                    ? defaultArmLabel
                    : dimension.arms[armId].name
                );
              }
            });
          if (missingArmNames.length > 0) {
            return `Missing arm${
              missingArmNames.length !== 1 ? "s" : ""
            } for selected dimension "${dimension.name}": ${missingArmNames
              .map((x) => `"${x}"`)
              .join(", ")}.`;
          }

          if (
            !needDefaultArm &&
            typeof dimensionMapping.cases[defaultArmKey] !== "undefined"
          ) {
            return "Unnecessary default arm.";
          }

          const extraArmIds: string[] = [];
          Object.keys(dimensionMapping.cases).forEach((armId) => {
            if (armId !== defaultArmKey && !dimension.arms[armId]) {
              extraArmIds.push(armId);
            }
          });
          if (extraArmIds.length > 0) {
            return `Invalid arm ID${
              extraArmIds.length !== 1 ? "s" : ""
            } for selected dimension "${dimension.name}": ${extraArmIds
              .map((x) => `"${x}"`)
              .join(", ")}.`;
          }
        } else if (dimensionMapping.type !== "continuous") {
          return "Selected dimension is continuous but dimension mapping isn't.";
        }

        // TODO: Re-enable this as a warning with a less jarring UI.
        // if (getSplitArmNonUniqueExpressionIds(expression).size > 0) {
        //   return "More than one arm has the same logic.";
        // }
      }
      break;

    case "LogEventExpression":
      {
        if (!expression.eventObjectTypeName) {
          return "No event type selected.";
        }

        const eventType = schema.objects[expression.eventObjectTypeName];

        if (!eventType || eventType.role !== "event") {
          return `Invalid event type name: ${expression.eventObjectTypeName}.`;
        }
      }
      break;

    case "FunctionExpression":
      {
        const numParameters = expression.parameters.length;
        const numParameterValueTypes =
          expression.valueType.parameterValueTypes.length;

        if (numParameters !== numParameterValueTypes) {
          return `Expression type (${valueTypeToString(
            expression.valueType
          )}) requires ${numParameterValueTypes} parameter${
            numParameterValueTypes === 1 ? "" : "s"
          } but ${numParameters} are defined in the expression.`;
        }

        const variableNames = getVariableNames(variables);

        for (const parameter of expression.parameters) {
          if (variableNames[parameter.name]) {
            return `More than one variable in the current scope is named "${parameter.name}". Please rename your variables to make them unique.`;
          }
          variableNames[parameter.name] = true;
        }
      }
      break;

    case "VariableExpression":
      {
        const variable = variables[expression.variableId];

        if (!variable) {
          return "Invalid variable.";
        }

        if (!isValueTypeValid(schema, variable.valueType)) {
          return `Variable has invalid type (${valueTypeToString(
            variable.valueType
          )}).`;
        }

        if (
          !isValueTypeCompatible(
            schema,
            getConstraintFromValueType(expression.valueType),
            variable.valueType
          )
        ) {
          return `Variable type (${valueTypeToString(
            variable.valueType
          )}) is incompatible with expression type (${valueTypeToString(
            expression.valueType
          )}).`;
        }
      }
      break;

    case "ApplicationExpression":
      if (
        expression.function &&
        expression.function.valueType.type === "FunctionValueType"
      ) {
        const numArguments = expression.arguments.length;
        const numParameters =
          expression.function.valueType.parameterValueTypes.length;

        if (numArguments !== numParameters) {
          return `The given function requires ${numParameters} parameter${
            numParameters === 1 ? "" : "s"
          } but ${numArguments} argument${
            numArguments === 1 ? "" : "s"
          } are applied.`;
        }
      }
      break;

    default: {
      const neverExpression: never = expression;
      throw new Error(
        `Expression with unexpected type: ${JSON.stringify(neverExpression)}`
      );
    }
  }

  // TODO: Re-enable this as a warning with a less jarring UI.
  // if (
  //   parentExpression?.type === "SplitExpression" &&
  //   getSplitArmNonUniqueExpressionIds(parentExpression)?.has(expression.id)
  // ) {
  //   return "Arm logic is not unique.";
  // }

  return null;
}
