import { Location, To, useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { getPreviousFromLocation } from "../query/locationState";
import {
  BusinessesLoginDocument,
  BusinessesLoginQuery,
  BusinessesLoginQueryVariables,
} from "../../generated/graphql";
import { getProjectUrl } from "../../features/project/projectHooks";
import getApolloClient from "../query/getApolloClient";
import getErrorMessage from "../query/getErrorMessage";

export default function useLoginRedirectQuery({
  skipPersonalize,
  projectId,
  searchParams,
}: {
  skipPersonalize?: boolean;
  projectId?: string;
  searchParams?: URLSearchParams;
} = {}): () => Promise<void> {
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = useCallback(async () => {
    let data: BusinessesLoginQuery | null = null;
    try {
      const { data: queryData } = await getApolloClient().query<
        BusinessesLoginQuery,
        BusinessesLoginQueryVariables
      >({
        query: BusinessesLoginDocument,
        fetchPolicy: "network-only",
      });
      data = queryData;
    } catch (error) {
      console.error("failed to fetch login query", {
        message: getErrorMessage(error),
        error,
      });
      return Promise.resolve();
    }

    const path = getLoginRedirectPath({
      location,
      data,
      skipPersonalize,
      projectId,
    });

    const to = `${path}${searchParams ? `?${searchParams.toString()}` : ""}`;

    return navigate(to, { state: location.state });
  }, [location, navigate, skipPersonalize, projectId, searchParams]);

  return redirect;
}

function getLoginRedirectPath({
  location,
  data,
  skipPersonalize,
  projectId,
}: {
  location: Location;
  data: BusinessesLoginQuery | null;
  skipPersonalize?: boolean;
  projectId?: string;
}): To {
  const previousLocation = getPreviousFromLocation(location);

  if (
    previousLocation &&
    (previousLocation.pathname !== "/" || previousLocation.search)
  ) {
    return previousLocation;
  }
  if (
    !skipPersonalize &&
    location.pathname !== "/personalize" &&
    data?.me?.metadataJson &&
    Object.keys(JSON.parse(data.me.metadataJson) ?? {}).length === 0
  ) {
    return "/personalize";
  }

  if (!data?.primaryBusiness) {
    return "/";
  }

  const { projects } = data.primaryBusiness.business;

  if (projectId && projects.some((project) => project.id === projectId)) {
    return getProjectUrl({
      projectId,
      selectedView: "logic",
    });
  }

  if (projects.length === 1) {
    return getProjectUrl({ projectId: projects[0].id, selectedView: "logic" });
  }

  return "/";
}
