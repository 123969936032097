import {
  differenceInCalendarDays,
  endOfDay,
  isEqual,
  isToday,
  startOfDay,
  subDays,
} from "date-fns";
import React from "react";
import { DateRangePicker } from "rsuite";
import { Calendar } from "@phosphor-icons/react";
import formatDate from "../lib/generic/formatDate";
import { CustomTimeRanges, TimeRange } from "../lib/types";

export default function TimeRangePicker({
  range,
  setRange,
  placement,
  customDateRanges,
}: {
  range: TimeRange;
  setRange: (newRange: TimeRange) => void;
  placement?: "bottomStart" | "bottomEnd";
  customDateRanges?: CustomTimeRanges;
}): React.ReactElement {
  const ranges = customDateRanges || [
    {
      label: "Today",
      value: [new Date(), new Date()],
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
    },
    {
      label: "Last 90 days",
      value: [subDays(new Date(), 89), new Date()],
    },
    {
      label: "All",
      value: [new Date(2022, 0), new Date()],
    },
  ];
  return (
    <div className="relative flex flex-row items-center">
      <Calendar className="absolute left-[8px] top-[7px] z-10" weight="fill" />
      <DateRangePicker
        caretAs={emptyComponent}
        value={[range.start, range.end]}
        defaultValue={[range.start, range.end]}
        disabledDate={
          DateRangePicker.afterToday ? DateRangePicker.afterToday() : undefined
        }
        onChange={(value): void => {
          if (value && value.length === 2) {
            const start = startOfDay(value[0]);
            const end = endOfDay(value[1]);
            setRange({ start, end });
          }
        }}
        appearance="subtle"
        cleanable={false}
        editable={false}
        renderValue={(value) => {
          if (value.length !== 2) {
            return "Select a date range...";
          }
          const start = value[0];
          const end = value[1];
          let prefix = "";
          const rangeValue = ranges.find(
            ({ value: [rStart, rEnd] }) =>
              rStart.getTime() === start.getTime() &&
              rEnd.getTime() === end.getTime()
          );
          if (rangeValue) {
            prefix = rangeValue.label;
          } else if (isToday(end)) {
            if (isToday(start)) {
              prefix = "Today";
            } else if (isEqual(start, new Date(2022, 0))) {
              prefix = "All";
            } else {
              prefix = `Last ${differenceInCalendarDays(end, start) + 1} days`;
            }
          }
          return `${prefix}${prefix ? ": " : ""}${formatDate(start)} – ⁠${formatDate(end)}`;
        }}
        ranges={ranges}
        placement={placement}
      />
    </div>
  );
}

function emptyComponent(): null {
  return null;
}
