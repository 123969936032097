import React from "react";
import { Info, WarningCircle } from "@phosphor-icons/react";
import { intentPrimaryHex, intentWarningHex } from "../lib/constants";
import SuccessCircle from "./icons/SuccessCircle";
import ErrorCircle from "./icons/ErrorCircle";
import SpinnerArrows from "./icons/SpinnerArrows";

export type ToastType = "success" | "error" | "warning" | "info" | "loading";

export type ToastMessage = {
  type: ToastType;
  text: string;
};

export default function Toast({
  messages,
}: {
  messages: ToastMessage[];
}): React.ReactElement {
  return (
    <div className="absolute right-0 top-10 z-100 flex flex-col gap-1 rounded-lg border bg-white px-2 py-1 shadow-dropdown">
      {messages.map((message) => (
        <InnerMessage
          key={`toast-message-${message.type}-${message.text}`}
          message={message}
        />
      ))}
    </div>
  );
}

function InnerMessage({
  message,
}: {
  message: ToastMessage;
}): React.ReactElement | null {
  return (
    <div className="flex flex-row items-center gap-1">
      {message.type === "success" ? (
        <SuccessCircle />
      ) : message.type === "error" ? (
        <ErrorCircle />
      ) : message.type === "warning" ? (
        <WarningCircle size={14.5} color={intentWarningHex} weight="regular" />
      ) : message.type === "loading" ? (
        <SpinnerArrows size={14.5} />
      ) : (
        <Info size={14.5} color={intentPrimaryHex} weight="regular" />
      )}
      <div className="max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap">
        {message.text}
      </div>
    </div>
  );
}
