import Select, {
  CSSObjectWithLabel,
  InputProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
import {
  blueHex,
  borderRadiusPx,
  navyBlueHex,
  darkGreyHex,
  dropdownHeight,
  greyHex,
  mediumFontSize,
  boldFontWeight,
  interFontFamily,
  greyBgHoverHex,
  blueHexSelected,
  blackTextHex,
  blueHexPressed,
  blueHexBoxShadow,
} from "../lib/constants";
import {
  Intent,
  intentToHexBackgroundColor,
  intentToHexColor,
  intentToShadowStyle,
} from "./intent";
import getTextWidth from "../lib/generic/getTextWidth";
import InputLabel from "./input/InputLabel";

type Options<TValue> =
  | { type: "options"; options: LabeledOption<TValue>[] }
  | { type: "groups"; groups: LabeledOptionGroup<TValue>[] };

export type LabeledOption<TValue> = {
  icon?: React.ReactNode;
  label: string;
  value: TValue;
};

export type LabeledOptionGroup<TValue> = {
  label: string;
  options: LabeledOption<TValue>[];
};

export default function Dropdown<TValue>({
  options,
  value,
  onChange,
  label,
  labelVariant,
  placeholder,
  noOptionsMessage,
  disabled,
  minWidth,
  maxWidth,
  height,
  focusOnMount,
  valueContainerPaddingLeftOffset,
  indicatorsContainerWidthOffset,
  hideIndicator,
  intent = "neutral",
  style,
  controlStyle,
  onOpen,
  onClose,
  disablePanelOnSelect,
}: {
  options: Options<TValue>;
  value: LabeledOption<TValue> | null;
  onChange: (newOption: LabeledOption<TValue> | null) => void;
  label?: string;
  labelVariant?: "default" | "muted" | "large";
  placeholder: string;
  noOptionsMessage: string;
  disabled?: boolean;
  minWidth?: number;
  maxWidth?: number; // TODO: Make this actually set the max width?
  height: number;
  focusOnMount?: boolean;
  valueContainerPaddingLeftOffset?: number;
  indicatorsContainerWidthOffset?: number;
  hideIndicator?: boolean;
  intent?: Intent;
  style?: React.CSSProperties;
  controlStyle?: React.CSSProperties;
  onOpen?: () => void;
  onClose?: () => void;
  disablePanelOnSelect?: boolean;
}): React.ReactElement {
  let width =
    getTextWidth(
      interFontFamily,
      mediumFontSize,
      value ? value.label : placeholder
    ) + 84;
  if (minWidth) {
    width = Math.max(width, minWidth);
  }
  if (maxWidth) {
    width = Math.min(width, maxWidth);
  }

  const heightVar = height || dropdownHeight;

  return (
    <>
      <InputLabel text={label} variant={labelVariant} />
      <Select<LabeledOption<TValue>>
        captureMenuScroll={false}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        autoFocus={focusOnMount}
        isDisabled={disabled}
        options={options.type === "options" ? options.options : options.groups}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        styles={{
          container: (base) =>
            ({
              ...base,
              fontSize: mediumFontSize,
              minWidth:
                width +
                (indicatorsContainerWidthOffset || 0) +
                (valueContainerPaddingLeftOffset || 0),
              ...style,
            }) as CSSObjectWithLabel,
          control: (base, state) =>
            ({
              ...base,
              cursor: "pointer",
              height: heightVar,
              minHeight: heightVar,
              borderRadius: borderRadiusPx,
              ...(intent === "neutral"
                ? {
                    borderColor:
                      state.isFocused && !disablePanelOnSelect
                        ? navyBlueHex
                        : greyHex,
                    boxShadow:
                      state.isFocused && !disablePanelOnSelect
                        ? blueHexBoxShadow
                        : undefined,
                    "&:hover": {
                      borderColor: !disablePanelOnSelect ? navyBlueHex : "",
                    },
                  }
                : {}),
              ...(state.isDisabled ? { backgroundColor: "#fcfcfd" } : {}),
              ...(intent !== "neutral"
                ? {
                    borderColor: intentToHexColor(intent),
                    backgroundColor: intentToHexBackgroundColor(intent),
                    boxShadow: state.isFocused
                      ? intentToShadowStyle(intent)
                      : undefined,
                    "&:hover": {
                      borderColor: intentToHexColor(intent),
                    },
                  }
                : {}),

              ...controlStyle,
            }) as CSSObjectWithLabel,
          valueContainer: (base) =>
            ({
              ...base,
              height: heightVar - 2,
              minHeight: heightVar - 2,
              paddingLeft: 8 + (valueContainerPaddingLeftOffset || 0),
              paddingRight: 6,
              paddingTop: 2.5,
              flexWrap: "nowrap",
            }) as CSSObjectWithLabel,
          singleValue: (base) =>
            ({
              ...base,
              marginTop: -1,
              marginLeft: 0,
              marginRight: 0,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              color: blackTextHex,
              ...(intent !== "neutral"
                ? { color: intentToHexColor(intent) }
                : {}),
            }) as CSSObjectWithLabel,
          placeholder: (base) =>
            ({
              ...base,
              marginTop: -1,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              ...(intent !== "neutral"
                ? { color: intentToHexColor(intent) }
                : {}),
            }) as CSSObjectWithLabel,
          input: (base) =>
            ({
              ...base,
              margin: "0px",
            }) as CSSObjectWithLabel,
          indicatorSeparator: () => ({
            display: "none",
          }),
          indicatorsContainer: (base) =>
            ({
              ...base,
              width: 32 + (indicatorsContainerWidthOffset || 0),
              height: heightVar - 2,
              minHeight: heightVar - 2,
            }) as CSSObjectWithLabel,
          dropdownIndicator: (base) =>
            ({
              ...base,
              ...(intent === "neutral"
                ? {
                    color: greyHex,
                    "&:hover": {
                      color: navyBlueHex,
                    },
                  }
                : { color: intentToHexColor(intent) }),
            }) as CSSObjectWithLabel,
          menu: (base) =>
            ({
              ...base,
              border: "1px solid",
              borderRadius: borderRadiusPx,
              borderColor: greyHex,
              overflow: "hidden",
              width: "unset",
              minWidth: "100%",
              zIndex: 100,
              top: height - 4,
              boxShadow: `0px 4px 20px 0px rgba(0, 0, 0, 0.06)`,
            }) as CSSObjectWithLabel,
          menuList: (base, state) =>
            ({
              ...base,
              ...(options.type === "groups" &&
              Array.isArray(state.children) &&
              state.children.length > 0
                ? { marginTop: -5 }
                : {}),
            }) as CSSObjectWithLabel,
          option: (base, state) =>
            ({
              ...base,
              cursor: "pointer",
              padding: "6px 10px",
              color: state.isSelected ? blueHex : blackTextHex,
              backgroundColor: state.isSelected
                ? blueHexSelected
                : state.isFocused
                  ? greyBgHoverHex
                  : "transparent",
              "&:active": {
                backgroundColor: blueHexPressed,
              },
            }) as CSSObjectWithLabel,
          group: (base) =>
            ({
              ...base,
              padding: 0,
            }) as CSSObjectWithLabel,
          groupHeading: (base) =>
            ({
              ...base,
              padding: "10px 10px 2px 10px",
              color: darkGreyHex,
              fontSize: mediumFontSize,
              fontWeight: boldFontWeight,
              textTransform: "none",
            }) as CSSObjectWithLabel,
        }}
        components={{
          Option,
          SingleValue,
          Input,
          ...(hideIndicator ? { DropdownIndicator: () => null } : {}),
        }}
      />
    </>
  );
}

function Option<TValue>({
  children,
  ...props
}: OptionProps<LabeledOption<TValue>>): React.ReactElement | null {
  return (
    <components.Option {...props}>
      {props.data.icon ? (
        <div className="flex flex-row items-center gap-[6px]">
          {props.data.icon}
          <span className="pt-[0.5px]">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.Option>
  );
}

function SingleValue<TValue>({
  children,
  ...props
}: SingleValueProps<LabeledOption<TValue>>): React.ReactElement | null {
  return (
    <components.SingleValue {...props}>
      {props.data.icon ? (
        <div className="flex flex-row items-center gap-[6px]">
          {props.data.icon}
          <span className="pt-[0.5px]">{props.data.label}</span>
        </div>
      ) : (
        children
      )}
    </components.SingleValue>
  );
}

function Input<TValue>({
  style,
  value,
  ...props
}: InputProps<LabeledOption<TValue>>): React.ReactElement | null {
  const newProps = {
    ...props,
    value,
    style: {
      ...(style || {}),
      cursor: "pointer",
      outline: "none",
      width: !value
        ? 0
        : getTextWidth(interFontFamily, mediumFontSize, value as string),
    },
  };
  return <components.Input {...newProps} />;
}
