import { UserMinus, Users } from "@phosphor-icons/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import BigIcon from "../../../components/BigIcon";
import CardGroup from "../../../components/CardGroup";
import Label from "../../../components/Label";
import Tag from "../../../components/Tag";
import Button from "../../../components/buttons/Button";
import { intentDangerHex } from "../../../lib/constants";
import Menu from "../../../components/Menu";
import EvictUserModal from "./EvictUserModal";
import { useUpdateMeMutation } from "../../../generated/graphql";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";

export type Business = {
  id: string;
  name: string;
  memberCount: number;
};

type LoadingAction = {
  action: "view" | "manage";
  businessId: string;
} | null;

export default function BusinessesList({
  meId,
  allBusinesses,
  selectedBusinessId,
  onSwitchBusinessStart,
  onSwitchBusinessEnd,
}: {
  meId: string;
  allBusinesses: Business[];
  selectedBusinessId?: string;
  onSwitchBusinessStart?: () => void;
  onSwitchBusinessEnd?: () => void;
}): React.ReactElement | null {
  const navigate = useNavigate();

  const [leaveBusinessId, setLeaveBusinessId] = useState("");
  const [loadingAction, _setLoadingAction] = useState<LoadingAction>(null);
  const setLoadingAction = useCallback(
    (newLoadingAction: LoadingAction) => {
      if (newLoadingAction) {
        onSwitchBusinessStart?.();
      } else {
        onSwitchBusinessEnd?.();
      }
      _setLoadingAction(newLoadingAction);
    },
    [onSwitchBusinessStart, onSwitchBusinessEnd]
  );

  const [updateMe, { loading }] = useUpdateMeMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });

  const switchBusiness = useCallback(
    async (action: "view" | "manage", newBusinessId: string) => {
      if (selectedBusinessId === newBusinessId) {
        await navigate(action === "view" ? "/" : "/team");
        return;
      }
      setLoadingAction({
        action: "view",
        businessId: newBusinessId,
      });
      try {
        await updateMe({
          variables: {
            input: {
              primaryBusinessId: newBusinessId,
            },
          },
        });
        await navigate(action === "view" ? "/" : "/team");
      } catch (error) {
        console.error("switchBusiness error:", error);
      }
      setLoadingAction(null);
    },
    [selectedBusinessId, setLoadingAction, navigate, updateMe]
  );

  return (
    <>
      {meId && leaveBusinessId && (
        <EvictUserModal
          userId={meId}
          businessId={leaveBusinessId}
          isLeave
          onClose={() => setLeaveBusinessId("")}
        />
      )}

      <CardGroup
        layout="list"
        cardLayout="horizontal-with-icon"
        cards={allBusinesses.map((business) => ({
          key: business.id,
          children: (
            <>
              <BigIcon
                icon={<Users weight="regular" color="white" size={20} />}
              />
              <div>
                <div className="flex flex-row gap-2">
                  <Label type="title1">{business.name}</Label>
                  {business.id === selectedBusinessId && (
                    <Tag intent="primary" text="Selected" />
                  )}
                </div>
                <Label type="title4" className="text-tx-muted">
                  {business.memberCount === 1
                    ? "1 member"
                    : `${business.memberCount} members`}
                </Label>
              </div>
              <div className="flex gap-2">
                <Button
                  text="View"
                  onClick={async () => {
                    await switchBusiness("view", business.id);
                  }}
                  loading={
                    loading &&
                    loadingAction?.action === "view" &&
                    loadingAction?.businessId === business.id
                  }
                  intent="primary"
                  weight="outlined"
                />
                <Button
                  text="Manage"
                  onClick={async () => {
                    await switchBusiness("manage", business.id);
                  }}
                  loading={
                    loading &&
                    loadingAction?.action === "manage" &&
                    loadingAction?.businessId === business.id
                  }
                  intent="primary"
                  weight="outlined"
                />
                <Menu
                  items={[
                    {
                      icon: <UserMinus />,
                      iconActive: <UserMinus color={intentDangerHex} />,
                      intent: "danger",
                      title: "Leave",
                      onClick: () => {
                        setLeaveBusinessId(business.id);
                      },
                    },
                  ]}
                />
              </div>
            </>
          ),
        }))}
      />
    </>
  );
}
