/* eslint-disable @typescript-eslint/ban-ts-comment */

"use client";

import { cookieConsentCookieName } from "@hypertune/shared-internal/src/constants";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { cookiePolicyUrl, dotSymbol, privacyPolicyUrl } from "../lib/constants";
import { useMeQuery } from "../generated/graphql";
import { useHypertune } from "../generated/hypertune.react";

export default function CookieConsentBanner(): React.ReactElement | null {
  const { refetch } = useMeQuery();
  const hypertune = useHypertune().website();

  return (
    <CookieConsent
      disableStyles
      location="none"
      buttonText={hypertune.cookieAcceptButtonText({
        fallback: "Accept all",
      })}
      cookieName={cookieConsentCookieName}
      extraCookieOptions={
        window.location.hostname.includes("hypertune.com")
          ? { domain: "hypertune.com" }
          : undefined
      }
      containerClasses="z-100 fixed bottom-4 left-4 p-3 max-w-[440px] bg-white border border-bd-darker rounded-lg shadow-modal"
      contentClasses="text-tx-default text-[14px]"
      buttonWrapperClasses="flex flex-row gap-2 items-center justify-end mt-3"
      buttonClasses="bg-intent-primary py-1 px-2 rounded-lg text-white text-[13px] hover:bg-intent-primary/90 active:bg-intent-primary/80"
      declineButtonClasses="border border-base-grey-medium !text-tx-default text-[13px] py-1 px-2 rounded-lg text-white hover:bg-intent-neutral/10 active:bg-intent-neutral/15"
      declineButtonText={hypertune.cookieDeclineButtonText({
        fallback: "Accept essential only",
      })}
      enableDeclineButton
      expires={365}
      onAccept={async () => {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        function gtag(): void {
          // @ts-ignore
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        }
        // @ts-ignore
        gtag("consent", "update", {
          analytics_storage: "granted",
        });
        await refetch();
      }}
    >
      {hypertune.cookieBannerText({
        fallback:
          "This website uses essential and analytics cookies to enhance the user experience.",
      })}{" "}
      <Link
        className="text-intent-primary underline"
        to={cookiePolicyUrl}
        target="_blank"
      >
        Cookie policy
      </Link>
      {` ${dotSymbol} `}
      <Link
        className="text-intent-primary underline"
        to={privacyPolicyUrl}
        target="_blank"
      >
        Privacy policy
      </Link>
    </CookieConsent>
  );
}
