import { User } from "@phosphor-icons/react";
import { blueHex, lighterGreyHex } from "../lib/constants";

export type OutlineStyle = "muted" | "default" | "dashed";
export type Size = "small" | "default" | "large";

export default function UserPicture({
  href,
  size = "default",
  isSelected,
  outlineStyle = "default",
}: {
  href?: string;
  size?: Size;
  isSelected?: boolean;
  outlineStyle?: OutlineStyle;
}): React.ReactElement | null {
  const divSize = size === "large" ? 30 : size === "small" ? 20 : 25;
  const iconSize = size === "large" ? 16 : size === "small" ? 12 : 14;
  return (
    <div
      className={`flex items-center justify-center bg-white ${!href ? "border" : ""} ${
        isSelected
          ? "border-intent-primary"
          : outlineStyle === "muted"
            ? "border-bd-darker"
            : "border-tx-muted"
      } ${outlineStyle === "dashed" ? "border-dashed" : ""} overflow-hidden rounded-full`}
      style={{ height: divSize, width: divSize }}
    >
      {href ? (
        <img src={href} alt="User" />
      ) : (
        <User
          weight={isSelected ? "duotone" : "regular"}
          color={isSelected ? blueHex : lighterGreyHex}
          size={iconSize}
        />
      )}
    </div>
  );
}
