import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ArrowRight, CreditCard, Pencil } from "@phosphor-icons/react";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import Skeleton from "react-loading-skeleton";
import { DbBillingData } from "@hypertune/shared-internal";
import iso3311a2 from "iso-3166-1-alpha-2";
import BusinessPage from "../BusinessPage";
import Table from "../../../components/Table";
import { intentPrimaryHex, whiteHex } from "../../../lib/constants";
import { formatRawDate } from "../../../lib/generic/formatDate";
import Label from "../../../components/Label";
import { canEditBusiness } from "../../../lib/query/rolePermissions";
import {
  BusinessType,
  BusinessesQuery,
  useBusinessesQuery,
  useUpdatePaymentMethodMutation,
} from "../../../generated/graphql";
import Button from "../../../components/buttons/Button";
import Card from "../../../components/Card";
import BigIcon from "../../../components/BigIcon";
import { useHypertune } from "../../../generated/hypertune.react";
import CardGroup, { CardGroupItem } from "../../../components/CardGroup";
import UpdateBillingDetailsModal from "./UpdateBillingDetailsModal";
import { Plan } from "../../../generated/hypertune";

export default function BillingPage(): React.ReactElement | null {
  useEffect(() => {
    document.title = "Billing - Hypertune";
  }, []);

  const { data } = useBusinessesQuery();
  const currentPlan = data?.primaryBusiness?.business.plan ?? null;

  const canEdit = canEditBusiness(data?.primaryBusiness?.role);
  const billingData: DbBillingData = JSON.parse(
    data?.primaryBusiness?.business.billingDataJson ?? "{}"
  );
  const billingDataLoaded = !!data?.primaryBusiness?.business.billingDataJson;

  if (
    data &&
    (data.primaryBusiness?.business.type === BusinessType.Personal ||
      data?.primaryBusiness?.business.billingDataJson === null ||
      !billingData.paymentMethod ||
      !canEdit)
  ) {
    return <Navigate to="/settings" />;
  }

  return (
    <BusinessPage>
      <Label type="title1" className="mb-5">
        Billing
      </Label>
      <PaymentMethod
        businessId={data?.primaryBusiness?.business.id}
        billingData={billingData}
        billingDataLoaded={billingDataLoaded}
      />
      <CurrentPlan
        currentPlan={currentPlan}
        billingData={billingData}
        teamSize={data?.primaryBusiness?.business.businessUsers.length ?? 0}
      />
      <BillingDetails
        businessId={data?.primaryBusiness?.business.id}
        billingData={billingData}
        billingDataLoaded={billingDataLoaded}
      />
      <BillingHistory primaryBusiness={data?.primaryBusiness} />
    </BusinessPage>
  );
}

function PaymentMethod({
  businessId,
  billingData,
  billingDataLoaded,
}: {
  businessId?: string;
  billingData: DbBillingData;
  billingDataLoaded: boolean;
}): React.ReactElement | null {
  const [updatePaymentLoading, setUpdatePaymentLoading] = useState(false);
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();

  return (
    <Card layout="horizontal-with-icon" className="mb-7">
      <BigIcon
        icon={<CreditCard size={20} color={whiteHex} weight="regular" />}
      />

      <div className="flex h-full flex-col">
        <Label type="title2">Payment method</Label>
        {billingDataLoaded && billingData.paymentMethod ? (
          <Label type="title4" className="text-tx-muted">
            {billingData.paymentMethod.type === "card" ? (
              <>
                {billingData.paymentMethod.cardType
                  ? toStartCase(billingData.paymentMethod.cardType)
                  : "Card"}{" "}
                ending in {billingData.paymentMethod.last4Digits}
              </>
            ) : (
              toStartCase(billingData.paymentMethod.type)
            )}
          </Label>
        ) : (
          <Skeleton className="h-4 max-w-40" />
        )}
      </div>

      {billingDataLoaded && (
        <Button
          text="Update"
          className="min-w-36"
          size="large"
          weight="elevated"
          loading={updatePaymentLoading}
          onClick={async () => {
            if (!businessId) {
              return;
            }
            setUpdatePaymentLoading(true);

            const resp = await updatePaymentMethod({
              variables: { input: { businessId } },
            });
            if (resp.data?.updatePaymentMethod.url) {
              window.location.href = resp.data?.updatePaymentMethod.url;
            }
          }}
        />
      )}
    </Card>
  );
}

function CurrentPlan({
  currentPlan,
  billingData,
  teamSize,
}: {
  currentPlan: Plan | null;
  billingData: DbBillingData;
  teamSize: number;
}): React.ReactElement | null {
  const navigate = useNavigate();
  const content = useHypertune().plansContent();
  const { name, description } = content
    .planContent({
      args: { plan: currentPlan ?? "free" },
    })
    .get();

  return (
    <>
      <Label type="title2" className="mb-5">
        Current plan
      </Label>
      <Card layout="horizontal" className="mb-7">
        <div className="flex h-full flex-col">
          {currentPlan ? (
            <>
              <Label type="title3">{name}</Label>
              <Label
                type="title4"
                className="whitespace-pre-wrap text-tx-muted"
              >
                {currentPlan === "pro"
                  ? `$${10 * teamSize} / month (${teamSize} x ${description})`
                  : description}
                <br />
                {currentPlan !== "pro" && billingData.nextInvoiceTotal
                  ? `Current total for next invoice: ${formatAmount(billingData.nextInvoiceTotal)}`
                  : ""}
              </Label>
            </>
          ) : (
            <>
              <Skeleton className="h-4 max-w-28" />
              <Skeleton className="h-4 min-w-40" />
            </>
          )}
        </div>
        <Button
          text="Manage"
          className="min-w-36"
          weight="elevated"
          onClick={() => navigate("/plans")}
        />
      </Card>
    </>
  );
}

function BillingDetails({
  businessId,
  billingData,
  billingDataLoaded,
}: {
  businessId?: string;
  billingData: DbBillingData;
  billingDataLoaded: boolean;
}): React.ReactElement | null {
  const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false);

  return (
    <>
      <div className="mb-5 flex min-h-[30px] flex-row justify-between">
        <Label type="title2" className="">
          Billing details
        </Label>
        {billingDataLoaded && (
          <Button
            text="Update"
            intent="primary"
            weight="filled"
            icon={<Pencil color={whiteHex} />}
            onClick={() => setShowUpdateDetailsModal(true)}
          />
        )}
      </div>

      <CardGroup
        layout="list"
        cardLayout="horizontal"
        cards={[
          BillingDetailCard({
            loaded: billingDataLoaded,
            label: "Name",
            value: billingData.name,
          }),
          BillingDetailCard({
            loaded: billingDataLoaded,
            label: "Email",
            value: billingData.email,
          }),
          BillingDetailCard({
            loaded: billingDataLoaded,
            label: "Address",
            value: [
              billingData.address?.line1,
              billingData.address?.line2,
              billingData.address?.city,
              billingData.address?.postal_code,
              billingData.address?.country
                ? iso3311a2.getCountry(billingData.address.country)
                : null,
            ]
              .filter(Boolean)
              .join(", "),
          }),
        ]}
        className="mb-7"
      />
      {businessId && showUpdateDetailsModal && (
        <UpdateBillingDetailsModal
          businessId={businessId}
          billingData={billingData}
          onClose={() => setShowUpdateDetailsModal(false)}
        />
      )}
    </>
  );
}

function BillingDetailCard({
  loaded,
  label,
  value,
}: {
  loaded: boolean;
  label: string;
  value?: string;
}): CardGroupItem {
  return {
    key: `billing-data-${label}`,
    children: (
      <>
        <Label type="title3">{label}</Label>
        {loaded ? (
          <Label type="title4" className="text-tx-muted">
            {value}
          </Label>
        ) : (
          <Skeleton className="h-4 min-w-28" />
        )}
      </>
    ),
  };
}

function BillingHistory({
  primaryBusiness,
}: {
  primaryBusiness?: BusinessesQuery["primaryBusiness"];
}): React.ReactElement | null {
  return (
    <>
      <Label type="title2" className="mb-5">
        Billing history
      </Label>
      {primaryBusiness && primaryBusiness.business.invoices.length === 0 ? (
        <Label type="title4" className="min-w text-tx-muted">
          No invoices yet.
        </Label>
      ) : (
        <Table
          colClassNames={[
            "w-full min-w-22 -ml-2",
            "min-w-20",
            "min-w-20",
            "min-w-[140px] -mr-2 items-end",
          ]}
          variant="no-border"
          rows={
            primaryBusiness?.business.invoices
              ?.filter((invoice) => invoice.total !== 0)
              .toReversed()
              .map((invoice) => [
                <p className="text-tx-muted">{formatRawDate(invoice.end)}</p>,
                <p className="font-semibold text-tx-default">
                  {toStartCase(invoice.status)}
                </p>,
                <p className="text-tx-muted">{formatAmount(invoice.total)}</p>,
                <Link
                  target="_blank"
                  to={invoice.url}
                  className="flex flex-row items-center gap-1 text-intent-primary"
                >
                  View invoice
                  <ArrowRight
                    color={intentPrimaryHex}
                    weight="regular"
                    size={12}
                  />
                </Link>,
              ]) ?? [
              [
                <Skeleton className="h-[19.5px] pr-[80px]" />,
                <Skeleton className="h-[19.5px] pr-[80px] lg:pr-[400px]" />,
                <Skeleton className="h-[19.5px] pr-[60px]" />,
                <Skeleton className="h-[19.5px] pr-[40px]" />,
                <Skeleton className="h-[19.5px] pr-[100px]" />,
              ],
            ]
          }
        />
      )}
    </>
  );
}

function formatAmount(amount: number): string {
  return `$${(amount / 100).toFixed(2)}`;
}
