import formatCount from "../../../../lib/generic/formatCount";
import { darkBlueFilter, navyBlueHex, small } from "../../../../lib/constants";
import twMerge from "../../../../lib/twMerge";

export default function EvaluationCount({
  count,
  className,
}: {
  count: number;
  className?: string;
}): React.ReactElement {
  return (
    <div
      key="evaluationCount"
      className={twMerge("mr-[2px] flex flex-row items-center", className)}
    >
      <img
        src="/graph.svg"
        width={9}
        height={9}
        style={{ filter: darkBlueFilter, marginTop: 2 }}
        alt=""
      />
      <div
        style={{
          color: navyBlueHex,
          whiteSpace: "nowrap",
          marginLeft: small,
        }}
      >
        {formatCount(count)}
      </div>
    </div>
  );
}
