import toWords from "./toWords";

const capitalizationOverrides: Record<string, string | undefined> = {
  Ui: "UI",
  Us: "US",
  Uk: "UK",
  Gb: "GB",
  Eu: "EU",
  Usa: "USA",
  Gui: "GUI",
  Ux: "UX",
  Crm: "CRM",
  Lifo: "LIFO",
  Fifo: "FIFO",
  Erp: "ERP",
  Bi: "BI",
  Aws: "AWS",
  Gcp: "GCP",
  Arn: "ARN",
  Seo: "SEO",
  Ai: "AI",
  Ml: "ML",
  Llm: "LLM",
  Saas: "SaaS",
  Api: "API",
  Json: "JSON",
  Sql: "SQL",
  Csp: "CSP",
  Cors: "CORS",
  Sso: "SSO",
  Saml: "SAML",
  Oidc: "OIDC",
  Dkim: "DKIM",
  Dmarc: "DMARC",
  Ci: "CI",
  Cd: "CD",
  Ssl: "SSL",
  Tls: "TLS",
  Dns: "DNS",
  Http: "HTTP",
  Https: "HTTPS",
  Ftp: "FTP",
  Sftp: "SFTP",
  Smtp: "SMTP",
  Ip: "IP",
  Cidr: "CIDR",
  Cdn: "CDN",
  Cname: "CNAME",
  Pdf: "PDF",
  Html: "HTML",
  Xml: "XML",
  Css: "CSS",
  Mp3: "MP3",
  Mp4: "MP4",
};

/**
 * @param s A string in camel, pascal, snake, kebab and spaced case format.
 * @returns A string in start case.
 * @example toStartCase("convertMy_string") === "Convert My String"
 */
export default function toStartCase(s: string): string {
  return toStartCaseWords(s).join(" ");
}

/**
 * @param s A string in camel, pascal, snake, kebab and spaced case format.
 * @returns A list of strings in title case.
 * @example toStartCase("convertMy_string") === ["Convert", "My", "String"]
 */
export function toStartCaseWords(s: string): string[] {
  return toWords(s).map((word) => {
    const startCase = word.charAt(0).toUpperCase() + word.slice(1);
    return capitalizationOverrides[startCase] ?? startCase;
  });
}

export function toCapitalCamelCase(s: string): string {
  return toStartCaseWords(s).join("");
}
