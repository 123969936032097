import React, { useRef } from "react";
import { useFloating, autoUpdate, arrow } from "@floating-ui/react-dom";
import { twJoin } from "tailwind-merge";
import { Info } from "@phosphor-icons/react";
import ErrorCircle from "../icons/ErrorCircle";
import TooltipArrow from "../icons/TooltipArrow";
import { Intent, intentToHexColor, intentToTextColorName } from "../intent";
import twMerge from "../../lib/twMerge";

export default function InfoTooltip({
  position = "top",
  content,
  icon,
  intent,
  showDetailOnHover,
  bubbleClassName,
}: {
  position?: "top" | "top-end";
  content?: React.ReactNode;
  icon: "error" | "info";
  intent: Intent;
  showDetailOnHover?: boolean;
  bubbleClassName?: string;
}): React.ReactElement | null {
  const arrowRef = useRef(null);
  const errorTooltip = useFloating({
    placement: position,
    whileElementsMounted: autoUpdate,
    middleware: [
      arrow({
        element: arrowRef,
      }),
    ],
  });

  if (!content) {
    return null;
  }
  return (
    <div className="group">
      <span ref={errorTooltip.refs.setReference}>
        {icon === "error" ? (
          <ErrorCircle
            size={16}
            intent={intent}
            weight={showDetailOnHover ? "regular" : "fill"}
          />
        ) : (
          <Info size={16} weight="regular" color={intentToHexColor(intent)} />
        )}
      </span>
      <div
        ref={errorTooltip.refs.setFloating}
        style={errorTooltip.floatingStyles}
        className={twJoin(
          "flex flex-col items-center",
          showDetailOnHover && "hidden group-hover:block"
        )}
      >
        <div
          className={twMerge(
            "mb-[7px] max-w-[240px] text-wrap rounded-[10px] border border-bd-darker bg-white px-3 py-2 text-base font-medium leading-[16px]",
            intentToTextColorName(intent),
            position === "top-end" ? "-mr-3" : "",
            bubbleClassName
          )}
        >
          {content}
        </div>
        <div
          ref={arrowRef}
          style={{
            position: "absolute",
            left: errorTooltip.middlewareData.arrow?.x,
            top: errorTooltip.refs.floating.current?.clientHeight
              ? errorTooltip.refs.floating.current.clientHeight - 19
              : errorTooltip.middlewareData.arrow?.y,
          }}
        >
          <TooltipArrow className="-rotate-90" />
        </div>
      </div>
    </div>
  );
}
