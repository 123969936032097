import { isValidEmail } from "@hypertune/shared-internal";
import { plusSymbol } from "../../lib/constants";
import TextListInput from "./TextListInput";

export default function EmailListInput({
  emails,
  setEmails,
  isLoading,
  onEnter,
  inputStyle,
}: {
  emails: string[];
  setEmails: (newEmails: string[]) => void;
  isLoading: boolean;
  onEnter?: () => void;
  inputStyle?: React.CSSProperties;
}): React.ReactElement | null {
  return (
    <TextListInput
      textList={emails}
      setTextList={(newEmails) =>
        setEmails(newEmails.map((newEmail) => newEmail.toLowerCase()))
      }
      placeholder="Enter an email address"
      newLabel={`${plusSymbol} New invite`}
      onEnter={onEnter}
      isLoading={isLoading}
      focusLastOnMount
      allowEmpty={false}
      valueError={(email) =>
        !email ? null : !isValidEmail(email) ? "Invalid email address" : null
      }
      inputStyle={inputStyle}
    />
  );
}
