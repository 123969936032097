import {
  Permissions,
  Expression,
  Schema,
  ValueType,
  SplitMap,
} from "@hypertune/sdk/src/shared";
import getConstraintFromValueType from "@hypertune/shared-internal/src/expression/constraint/getConstraintFromValueType";
import getExpressionErrorMessage from "@hypertune/shared-internal/src/expression/getExpressionErrorMessage";
import getNewVariables from "@hypertune/shared-internal/src/expression/getNewVariables";
import isValueTypeValid from "@hypertune/shared-internal/src/expression/isValueTypeValid";
import getApplicationFunctionExpression from "@hypertune/shared-internal/src/expression/getApplicationFunctionExpression";
import dropArgument from "@hypertune/shared-internal/src/expression/dropArgument";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import getExpressionRecursiveErrorMessages from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import {
  getEmptyPermissions,
  resolvePermissions,
} from "@hypertune/shared-internal/src/permissions";
import { queryObjectTypeName } from "@hypertune/shared-internal";
import {
  getEnumSwitchFieldPath,
  getFieldNameFromPath,
  getListItemFieldPath,
  getObjectFieldPath,
  getVariableFieldPath,
} from "@hypertune/shared-internal/src/expression/fieldPath";
import getSetVariableNameFunction from "../../../../lib/expression/getSetVariableNameFunction";
import {
  IncludeExpressionOptionFunction,
  LiftFunction,
  SelectedItem,
  VariableContext,
} from "../../../../lib/types";
import { getObjectExpressionFieldLiftFunction } from "./ObjectExpressionControl";
import { getFunctionExpressionBodyLiftFunction } from "./FunctionExpressionControl";
import {
  getApplicationExpressionArgumentLiftFunction,
  getApplicationExpressionFunctionLiftFunction,
} from "./ApplicationExpressionControl";
import {
  getListExpressionLiftFunction,
  getNewListItemValueFunction,
} from "./ListExpressionControl";
import { ObjectAddFieldModalState } from "../../projectSlice";
import getListFieldLabelExpression from "../../../../lib/expression/getListFieldLabelExpression";
import {
  getEnumSwitchCaseIncludeExpressionOptionFunction,
  getEnumSwitchCaseExpressionFunction,
  getEnumSwitchExpressionSchemaValuesAndCasePositions,
} from "./EnumSwitchExpressionControl";

export type ExpressionNodeMap = {
  [fieldPath: string]: ExpressionNode;
};

export type ExpressionNodeContext = {
  resolvedPermissions: Permissions;
  keepInObjectField?: boolean;
};

export type ExpressionNode = {
  context: ExpressionNodeContext;
  fieldLabel: string;
  selectedFieldLabel?: string;
  fullFieldPath: string;
  index: number | null;
  hasActions: boolean;
  hasError: boolean;
  childExpressions: ExpressionNodeMap | null;
  newChildExpression?: () => string | null;
  variables: VariableMap;
  setVariableName: {
    [variableId: string]: (newVariableName: string) => void;
  };
  valueTypeConstraint: ValueTypeConstraint;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  lift: LiftFunction;
  variableContext?: VariableContext;
  includeExpressionOption: IncludeExpressionOptionFunction;
};

export type ToTreeArgs = {
  schema: Schema;
  splits: SplitMap;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  setAddObjectFieldModalState: (newState: ObjectAddFieldModalState) => void;
  setExpressionEditorSelectedItem: (
    newSelectedItem: SelectedItem | null
  ) => void;
};

export default function toTree(args: ToTreeArgs): ExpressionNodeMap | null {
  return toNodeMapWithNew({
    ...args,
    contextFromParent: {
      resolvedPermissions: getEmptyPermissions(),
    },
    valueTypeConstraint: {
      type: "ObjectValueTypeConstraint",
      objectTypeName: queryObjectTypeName,
    },
    fullFieldPath: "",
    parentSelectedLabel: "",
    variables: {},
    setVariableName: {},
    lift: () => {
      // Dummy
    },
    includeExpressionOption: () => true,
    parentExpression: null,
  }).nodeMap;
}

function toNodeMapWithNew({
  schema,
  splits,
  contextFromParent,
  argumentsFromParent,
  fullFieldPath,
  parentSelectedLabel,
  variables,
  setVariableName,
  valueTypeConstraint,
  expression,
  setExpression,
  lift,
  setAddObjectFieldModalState,
  setExpressionEditorSelectedItem,
  includeExpressionOption,
  parentExpression,
}: ToTreeArgs & {
  schema: Schema;
  splits: SplitMap;
  contextFromParent: ExpressionNodeContext;
  argumentsFromParent?: (Expression | null)[];
  valueTypeConstraint: ValueTypeConstraint;
  fullFieldPath: string;
  parentSelectedLabel: string;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  lift: LiftFunction;
  includeExpressionOption: IncludeExpressionOptionFunction;
  parentExpression: Expression | null;
}): {
  nodeMap: ExpressionNodeMap | null;
  newChild?: () => string | null;
} {
  const context = {
    ...contextFromParent,
    resolvedPermissions: resolvePermissions(
      contextFromParent.resolvedPermissions,
      expression?.metadata?.permissions
    ),
  };

  if (
    !expression ||
    (fullFieldPath &&
      !!getExpressionErrorMessage(
        schema,
        splits,
        variables,
        valueTypeConstraint,
        parentExpression,
        expression
      ))
  ) {
    return { nodeMap: null };
  }

  if (expression.type === "EnumSwitchExpression") {
    const result: ExpressionNodeMap = {};
    const { casePosition } =
      getEnumSwitchExpressionSchemaValuesAndCasePositions(schema, expression);

    const enumSwitchCaseIncludeExpressionOption =
      getEnumSwitchCaseIncludeExpressionOptionFunction(
        expression,
        includeExpressionOption
      );

    Object.entries(expression.cases)
      .sort(([a], [b]) => casePosition[a] - casePosition[b])
      .forEach(([enumValue, caseExpression]) => {
        const childFullFieldPath = getEnumSwitchFieldPath(
          fullFieldPath,
          enumValue
        );

        // eslint-disable-next-line func-style
        const setValueExpression = (newExpression: Expression | null): void => {
          setExpression({
            ...expression,
            cases: {
              ...expression.cases,
              [enumValue]: newExpression,
            },
          });
        };
        const fieldLift = getEnumSwitchCaseExpressionFunction({
          variables,
          enumValue,
          expression,
          setExpression,
          setExpressionEditorSelectedItem,
        });

        const fieldLabel = toStartCase(enumValue.toLowerCase());
        const selectedFieldLabel = getEnumSwitchFieldPath(
          parentSelectedLabel,
          fieldLabel
        );

        const { nodeMap: childExpressions, newChild: newChildExpression } =
          toNodeMapWithNew({
            schema,
            splits,
            contextFromParent: context,
            fullFieldPath: childFullFieldPath,
            parentSelectedLabel: selectedFieldLabel,
            variables,
            setVariableName,
            valueTypeConstraint,
            expression: caseExpression,
            setExpression: setValueExpression,
            setAddObjectFieldModalState,
            setExpressionEditorSelectedItem,
            lift: fieldLift,
            includeExpressionOption: enumSwitchCaseIncludeExpressionOption,
            parentExpression: expression,
          });

        result[enumValue] = {
          context,
          fieldLabel,
          selectedFieldLabel,
          fullFieldPath: childFullFieldPath,
          index: null,
          hasActions: false,
          hasError:
            getExpressionRecursiveErrorMessages(
              schema,
              splits,
              variables,
              valueTypeConstraint,
              parentExpression,
              caseExpression
            ).length > 0,
          childExpressions,
          newChildExpression,
          variables,
          setVariableName,
          valueTypeConstraint,
          expression: caseExpression,
          setExpression: setValueExpression,
          lift: fieldLift,
          includeExpressionOption: enumSwitchCaseIncludeExpressionOption,
        };
      });
    return { nodeMap: result };
  }

  if (expression.type === "ObjectExpression") {
    const result: ExpressionNodeMap = {};

    const schemaObjectFields =
      schema.objects[expression.objectTypeName]?.fields || {};

    const extraFieldNames = Object.keys(expression.fields).filter(
      (fieldName) => !schemaObjectFields[fieldName]
    );
    const orderedFields = [
      ...extraFieldNames,
      ...Object.keys(schemaObjectFields),
    ];

    orderedFields.forEach((fieldName) => {
      const fieldExpression = expression.fields[fieldName] ?? null;
      const schemaFieldValueType = schemaObjectFields[fieldName]?.valueType;

      const fieldValueTypeConstraint: ValueTypeConstraint = schemaFieldValueType
        ? getConstraintFromValueType(schemaFieldValueType)
        : { type: "ErrorValueTypeConstraint" };

      const fieldLift = getObjectExpressionFieldLiftFunction({
        schema,
        variables,
        fieldName,
        expression,
        setExpression,
        setExpressionEditorSelectedItem,
      });
      // eslint-disable-next-line func-style
      const setFieldExpression = (newExpression: Expression | null): void => {
        setExpression({
          ...expression,
          fields: { ...expression.fields, [fieldName]: newExpression },
        });
      };
      const childFullFieldPath = getObjectFieldPath(fullFieldPath, fieldName);

      const fieldLabel = toStartCase(fieldName);

      const { nodeMap: childExpressions, newChild: newChildExpression } =
        toNodeMapWithNew({
          schema,
          splits,
          contextFromParent: context,
          fullFieldPath: childFullFieldPath,
          parentSelectedLabel: fieldLabel,
          variables,
          setVariableName,
          valueTypeConstraint: fieldValueTypeConstraint,
          expression: fieldExpression,
          setExpression: setFieldExpression,
          setAddObjectFieldModalState,
          setExpressionEditorSelectedItem,
          lift: fieldLift,
          includeExpressionOption,
          parentExpression: expression,
        });

      result[fieldName] = {
        context,
        fieldLabel,
        fullFieldPath: childFullFieldPath,
        index: null,
        hasActions: true,
        hasError:
          getExpressionRecursiveErrorMessages(
            schema,
            splits,
            variables,
            fieldValueTypeConstraint,
            parentExpression,
            fieldExpression
          ).length > 0,
        childExpressions,
        newChildExpression,
        variables,
        setVariableName,
        valueTypeConstraint: fieldValueTypeConstraint,
        expression: fieldExpression,
        setExpression: setFieldExpression,
        lift: fieldLift,
        includeExpressionOption,
      };
    });

    return {
      nodeMap: result,
      newChild: () => {
        setAddObjectFieldModalState({
          objectTypeName: expression.objectTypeName,
          fieldPosition: "first",
          entity: { name: "logicField", parentFieldPath: fullFieldPath },
        });
        return null;
      },
    };
  }

  if (expression.type === "ApplicationExpression") {
    const functionExpression = getApplicationFunctionExpression(expression);

    if (!functionExpression) {
      return { nodeMap: null };
    }

    const result: ExpressionNodeMap = {};
    expression.arguments.forEach((arg, index) => {
      const childFullFieldPath = getVariableFieldPath(
        fullFieldPath,
        functionExpression.parameters[index].name
      );
      const fieldName = getFieldNameFromPath(childFullFieldPath);

      const liftArg = getApplicationExpressionArgumentLiftFunction(
        expression,
        lift,
        index
      );
      // eslint-disable-next-line func-style
      const setArgExpression = (newExpression: Expression | null): void => {
        setExpression({
          ...expression,
          arguments: [
            ...expression.arguments.slice(0, index),
            newExpression,
            ...expression.arguments.slice(index + 1),
          ],
        });
      };
      const argValueTypeConstraint = getValueTypeConstraint(
        schema,
        functionExpression.valueType.parameterValueTypes[index]
      );
      const fieldLabel = toStartCase(functionExpression.parameters[index].name);

      const { nodeMap: childExpressions, newChild: newChildExpression } =
        toNodeMapWithNew({
          schema,
          splits,
          contextFromParent: { ...context, keepInObjectField: true },
          fullFieldPath: childFullFieldPath,
          parentSelectedLabel: fieldLabel,
          variables,
          setVariableName,
          valueTypeConstraint: argValueTypeConstraint,
          expression: arg,
          setExpression: setArgExpression,
          setAddObjectFieldModalState,
          setExpressionEditorSelectedItem,
          lift: liftArg,
          includeExpressionOption,
          parentExpression: expression,
        });

      result[fieldName] = {
        context: { ...context, keepInObjectField: true },
        fieldLabel,
        fullFieldPath: childFullFieldPath,
        index: null,
        variables,
        childExpressions,
        newChildExpression,
        setVariableName,
        valueTypeConstraint: argValueTypeConstraint,
        expression: arg,
        setExpression: setArgExpression,
        lift: liftArg,
        hasActions: true,
        hasError:
          getExpressionRecursiveErrorMessages(
            schema,
            splits,
            variables,
            argValueTypeConstraint,
            parentExpression,
            arg
          ).length > 0,
        variableContext: {
          name: functionExpression.parameters[index].name,
          rename: getSetVariableNameFunction(
            functionExpression,
            (newExpression) => {
              setExpression({ ...expression, function: newExpression });
            },
            index
          ),
          drop: () => {
            const newExpression = dropArgument(expression, index);
            if (!newExpression) {
              return;
            }
            setExpression(newExpression);
          },
        },
        includeExpressionOption,
      };
    });

    const functionWithReturnValueTypeConstraint: ValueTypeConstraint =
      isValueTypeValid(schema, expression.valueType)
        ? {
            type: "FunctionWithReturnValueTypeConstraint",
            returnValueTypeConstraint: getConstraintFromValueType(
              expression.valueType
            ),
          }
        : { type: "ErrorValueTypeConstraint" };

    const functionLift = getApplicationExpressionFunctionLiftFunction(
      expression,
      lift
    );

    const { nodeMap: functionMap, newChild } = toNodeMapWithNew({
      schema,
      splits,
      contextFromParent: context,
      argumentsFromParent: expression.arguments,
      parentSelectedLabel,
      fullFieldPath,
      variables,
      setVariableName,
      valueTypeConstraint: functionWithReturnValueTypeConstraint,
      expression: functionExpression,
      setExpression: (newExpression) => {
        setExpression({ ...expression, function: newExpression });
      },
      setAddObjectFieldModalState,
      setExpressionEditorSelectedItem,
      lift: functionLift,
      includeExpressionOption,
      parentExpression: expression,
    });

    if (functionMap) {
      Object.assign(result, functionMap);
    } else {
      const fieldName = getFieldNameFromPath(fullFieldPath);
      result[fieldName] = {
        context,
        fieldLabel: toStartCase(fieldName),
        fullFieldPath,
        index: null,
        hasActions: true,
        hasError:
          getExpressionRecursiveErrorMessages(
            schema,
            splits,
            variables,
            functionWithReturnValueTypeConstraint,
            parentExpression,
            functionExpression
          ).length > 0,
        childExpressions: null,
        variables,
        setVariableName,
        valueTypeConstraint: functionWithReturnValueTypeConstraint,
        expression: functionExpression,
        setExpression: (newExpression) => {
          setExpression({ ...expression, function: newExpression });
        },
        lift: functionLift,
        includeExpressionOption,
      };
    }

    return { nodeMap: result, newChild };
  }

  if (
    expression.type === "ListExpression" &&
    expression.valueType.itemValueType.type === "ObjectValueType"
  ) {
    if (expression.items.length === 0) {
      return {
        nodeMap: null,
        newChild: getNewListItemValueFunction(
          schema,
          variables,
          expression,
          setExpression,
          setExpressionEditorSelectedItem,
          /* disallowDuplicates */ false
        ),
      };
    }
    const result: ExpressionNodeMap = {};

    const itemValueTypeConstraint: ValueTypeConstraint = getValueTypeConstraint(
      schema,
      expression.valueType.itemValueType
    );

    expression.items.forEach((item, index) => {
      const childFullFieldPath = getListItemFieldPath(fullFieldPath, index);
      const fieldName = getFieldNameFromPath(childFullFieldPath);
      const fieldLabel =
        listFieldLabel(index, item) || `${fieldName}. List Item`;

      result[fieldName] = {
        context,
        fieldLabel,
        fullFieldPath: childFullFieldPath,
        index,
        hasActions: false,
        hasError:
          getExpressionRecursiveErrorMessages(
            schema,
            splits,
            variables,
            itemValueTypeConstraint,
            parentExpression,
            item
          ).length > 0,
        childExpressions: null,
        variables,
        setVariableName,
        valueTypeConstraint: itemValueTypeConstraint,
        expression: item,
        setExpression: (newExpression) => {
          setExpression({
            ...expression,
            items: [
              ...expression.items.slice(0, index),
              ...(newExpression !== null ? [newExpression] : []),
              ...expression.items.slice(index + 1),
            ],
          });
        },
        lift: getListExpressionLiftFunction({
          index,
          variables,
          expression,
          parentExpression,
          setExpression,
          lift,
          setExpressionEditorSelectedItem,
        }),
        includeExpressionOption,
      };
    });
    return {
      nodeMap: result,
      newChild: getNewListItemValueFunction(
        schema,
        variables,
        expression,
        setExpression,
        setExpressionEditorSelectedItem,
        /* disallowDuplicates */ false
      ),
    };
  }

  if (
    expression.type === "FunctionExpression" &&
    expression.body &&
    (expression.body.type === "FunctionExpression" ||
      expression.body.type === "ObjectExpression" ||
      expression.body.type === "ApplicationExpression" ||
      expression.body.type === "ListExpression" ||
      expression.body.type === "EnumSwitchExpression")
  ) {
    const newVariables = {
      ...variables,
      ...getNewVariables(
        expression.parameters,
        expression.valueType.parameterValueTypes,
        argumentsFromParent,
        fullFieldPath
      ),
    };

    const newSetVariableName = {
      ...setVariableName,
      ...Object.fromEntries(
        expression.parameters.map((parameter, index) => [
          parameter.id,
          getSetVariableNameFunction(expression, setExpression, index),
        ])
      ),
    };

    const bodyValueTypeConstraint: ValueTypeConstraint = isValueTypeValid(
      schema,
      expression.valueType
    )
      ? getConstraintFromValueType(expression.valueType.returnValueType)
      : { type: "ErrorValueTypeConstraint" };

    return toNodeMapWithNew({
      schema,
      splits,
      contextFromParent: context,
      parentSelectedLabel,
      fullFieldPath,
      variables: newVariables,
      setVariableName: newSetVariableName,
      valueTypeConstraint: bodyValueTypeConstraint,
      expression: expression.body,
      setExpression: (newExpression) => {
        setExpression({ ...expression, body: newExpression });
      },
      setAddObjectFieldModalState,
      setExpressionEditorSelectedItem,
      lift: getFunctionExpressionBodyLiftFunction(expression, lift),
      includeExpressionOption,
      parentExpression: expression,
    });
  }

  return { nodeMap: null };
}
export type Flag = {
  path: string;
  label: string;
  isVariable: boolean;
  hasChildren: boolean;
  valueTypeConstraint: ValueTypeConstraint;
};

export function getFlagsFromTree({
  tree,
  skipTopLevels,
}: {
  tree: ExpressionNodeMap | null;
  skipTopLevels?: number;
}): Flag[] {
  if (!tree) {
    return [];
  }
  return Object.values(tree).flatMap((expressionNode) => {
    return (
      skipTopLevels
        ? []
        : [
            {
              path: expressionNode.fullFieldPath,
              label: expressionNode.fieldLabel,
              isVariable: !!expressionNode.variableContext,
              hasChildren: false,
              valueTypeConstraint: expressionNode.valueTypeConstraint,
            },
          ]
    ).concat(
      getFlagsFromTree({
        tree: expressionNode.childExpressions,
        skipTopLevels: skipTopLevels ? skipTopLevels - 1 : undefined,
      })
    );
  });
}

export function listFieldLabel(
  index: number,
  expression: Expression | null
): string | null {
  const labelExpression = getListFieldLabelExpression(expression);
  if (!labelExpression) {
    return null;
  }
  return `${(index + 1).toString()}. ${labelExpression.value}`;
}

function getValueTypeConstraint(
  schema: Schema,
  valueType: ValueType | null
): ValueTypeConstraint {
  if (!valueType || !isValueTypeValid(schema, valueType)) {
    return { type: "ErrorValueTypeConstraint" };
  }
  return getConstraintFromValueType(valueType);
}

export type SelectedExpressionNode = {
  item: ExpressionNode | null;
  parent: ExpressionNode | null;
};

export function findItemInTree(
  tree: ExpressionNodeMap,
  parent: ExpressionNode | null,
  fieldPath: string[]
): SelectedExpressionNode {
  if (fieldPath.length === 0) {
    return { item: null, parent: null };
  }
  const [fieldName, ...newFieldPath] = fieldPath;
  const item = tree[fieldName] || null;

  if (newFieldPath.length === 0) {
    return { item, parent };
  }
  if (!item || !item.childExpressions) {
    return { item: null, parent: null };
  }
  return findItemInTree(item.childExpressions, item, newFieldPath);
}

export function findDeepestItemWithErrorInExpressionTree(
  tree: ExpressionNodeMap,
  depth = 0
): { item: ExpressionNode; depth: number } | null {
  return Object.values(tree)
    .map((expressionNode) => {
      if (!expressionNode.hasError) {
        return null;
      }
      if (!expressionNode.childExpressions) {
        return { item: expressionNode, depth: depth + 1 };
      }
      return findDeepestItemWithErrorInExpressionTree(
        expressionNode.childExpressions,
        depth + 1
      );
    })
    .reduce((current, value) => {
      if (!current || (value && value.depth < current.depth)) {
        return value;
      }
      return current;
    }, null);
}
