import React, { useCallback, useMemo, useState } from "react";
import { isValidEmail } from "@hypertune/shared-internal";
import { nanoid } from "nanoid";
import Modal from "../../../components/Modal";
import { switchBusinessRefetchQueries } from "../../../lib/query/refetchQueries";
import TextInput from "../../../components/input/TextInput";
import {
  CreateBusinessInput,
  Plan as GraphqlPlan,
  useBusinessesQuery,
  useCreateBusinessMutation,
} from "../../../generated/graphql";

import HeaderSteps from "../../../components/HeaderSteps";
import Button from "../../../components/buttons/Button";
import ConditionalText from "../../../components/ConditionalText";
import TopBarDropdown, {
  LabeledOption,
} from "../../../components/TopBarDropdown";
import EmailListInput from "../../../components/input/EmailListInput";
import Label from "../../../components/Label";
import DeleteButton from "../../../components/buttons/DeleteButton";
import CopyableText from "../../../components/CopyableText";
import { businessInviteLinkURL } from "./InviteLinkButton";
import { canEditBusiness } from "../../../lib/query/rolePermissions";
import { useHypertune } from "../../../generated/hypertune.react";
import DomainListInput, {
  getDomainError,
} from "../../../components/input/DomainListInput";
import { Plan } from "../../../generated/hypertune";

type StepId = "name" | "transfer" | "invite" | "review";

export default function NewBusinessModal({
  onClose,
  onCreateStart,
  skipSwitch,
  initialTransferProjects,
  upgradePlan,
}: {
  onClose: (newTeamId?: string) => void;
  onCreateStart?: () => void;
  skipSwitch?: boolean;
  initialTransferProjects?: { id: string; name: string }[];
  upgradePlan?: Plan;
}): React.ReactElement {
  const hypertune = useHypertune();
  const content = hypertune.content().homepage().newTeamModal();
  const disallowedDomains = useMemo(
    () =>
      new Set(
        hypertune
          .disallowedBusinessEmailDomains({ itemFallback: "" })
          .filter(Boolean)
      ),
    [hypertune]
  );
  const { data } = useBusinessesQuery();

  const defaultAutoJoinDomain = data?.me.email?.split("@")?.at(-1);
  const canTransferProjects = canEditBusiness(data?.primaryBusiness?.role);

  const [name, setName] = useState<string>("");
  const [transferProjects, setTransferProjects] = useState<
    (LabeledOption<string> | null)[]
  >(
    initialTransferProjects
      ?.map<LabeledOption<string> | null>((project) => ({
        value: project.id,
        label: project.name,
      }))
      .concat([null]) || [null]
  );
  const [businessInviteToken] = useState<string>(nanoid());
  const [autoJoinDomains, setAutoJoinDomains] = useState<string[]>([
    !defaultAutoJoinDomain || disallowedDomains.has(defaultAutoJoinDomain)
      ? ""
      : defaultAutoJoinDomain,
  ]);
  const containsInvalidDomain = autoJoinDomains.some((domain) =>
    getDomainError(disallowedDomains, domain)
  );

  const [inviteEmails, setInviteEmails] = useState<string[]>([""]);
  const containsInvalidEmail = inviteEmails.some(
    (email) => email && !isValidEmail(email)
  );

  const [loading, setLoading] = useState(false);
  const [createBusiness] = useCreateBusinessMutation({
    refetchQueries: switchBusinessRefetchQueries,
    awaitRefetchQueries: true,
  });

  function onCreateButtonClick(): void {
    const trimmedName = name.trim();
    if (loading || !trimmedName) {
      return;
    }
    const input: CreateBusinessInput = {
      upgradePlan: upgradePlan as GraphqlPlan,
      name: trimmedName,
      skipSwitch: !!skipSwitch,
      transferProjectIds: (
        transferProjects.filter(Boolean) as LabeledOption<string>[]
      ).map((option) => option.value),
      domains: autoJoinDomains.filter(Boolean),
      inviteEmails: inviteEmails.filter(Boolean),
      inviteToken: businessInviteToken,
    };
    if (onCreateStart) {
      onCreateStart();
    }
    setLoading(true);
    createBusiness({ variables: { input } })
      .then((result) => {
        if (result.data?.createBusiness.upgradePlanUrl) {
          window.location.href = result.data.createBusiness.upgradePlanUrl;
          return;
        }
        setLoading(false);
        onClose(result.data?.createBusiness.newBusinessId);
      })
      .catch((error) => {
        setLoading(false);
        console.error("createBusiness error:", error);
      });
  }

  const [stepId, _setStepId] = useState<StepId>("name");
  const [visitedSteps, setVisitedSteps] = useState<Set<StepId>>(new Set());
  const stepDefinitions: {
    id: StepId;
    text: string;
    isCompleted: boolean;
  }[] = [
    {
      id: "name",
      text: "Choose a name",
      isCompleted: !!name,
    },
    ...(canTransferProjects
      ? [
          {
            id: "transfer" as StepId,
            text: "Transfer projects",
            isCompleted: visitedSteps.has("transfer"),
          },
        ]
      : []),
    {
      id: "invite",
      text: "Send invites",
      isCompleted:
        !containsInvalidDomain &&
        !containsInvalidEmail &&
        visitedSteps.has("invite"),
    },
    {
      id: "review",
      text: "Review",
      isCompleted: false,
    },
  ];
  const stepIndex = stepDefinitions.findIndex((step) => step.id === stepId);
  const isLastStep = stepId === stepDefinitions.at(-1)?.id;

  const setStep = useCallback((newStepId: StepId) => {
    _setStepId(newStepId);
    setVisitedSteps(
      (currentVisited) => new Set([newStepId, ...currentVisited])
    );
  }, []);

  return (
    <Modal
      onClose={() => onClose()}
      confirmClose={!!name}
      modalStyle="large"
      title={
        <HeaderSteps
          selectedStep={stepIndex + 1}
          setSelectedStep={(newStepIndex) =>
            setStep(stepDefinitions[newStepIndex - 1].id)
          }
          steps={stepDefinitions}
        />
      }
      style={{ maxHeight: 650 }}
      childrenStyle={{ padding: 0, overflow: "hidden" }}
    >
      <div className="min-h-[300px] w-[720px] overflow-auto p-6">
        {stepId === "name" && (
          <Step1ChooseName
            name={name}
            setName={setName}
            message={content.step1SelectNameMessage({ fallback: "" })}
            onNext={() => setStep(stepDefinitions[stepIndex + 1].id)}
          />
        )}
        {stepId === "transfer" && (
          <Step2TransferProjects
            transferProjects={transferProjects}
            setTransferProjects={setTransferProjects}
            message={content.step2TransferProjectsMessage({ fallback: "" })}
          />
        )}
        {stepId === "invite" && (
          <Step3SendInvites
            isLoading={loading}
            businessInviteToken={businessInviteToken}
            disallowedDomains={disallowedDomains}
            autoJoinDomains={autoJoinDomains}
            setAutoJoinDomains={setAutoJoinDomains}
            inviteEmails={inviteEmails}
            setInviteEmails={setInviteEmails}
            message={content.step3SendInvitesMessage({ fallback: "" })}
            onNext={() => setStep(stepDefinitions[stepIndex + 1].id)}
          />
        )}
        {stepId === "review" && (
          <Step4Review
            name={name}
            transferProjects={
              transferProjects.filter(Boolean) as LabeledOption<string>[]
            }
            autoJoinDomains={autoJoinDomains.filter(Boolean)}
            inviteEmails={inviteEmails.filter(Boolean)}
            canTransferProjects={canTransferProjects}
          />
        )}
      </div>
      <div className="flex flex-row-reverse gap-4 border-t px-6 py-3">
        <Button
          text={
            isLastStep
              ? upgradePlan
                ? "Create and upgrade"
                : "Create"
              : "Next"
          }
          onClick={() => {
            if (isLastStep) {
              onCreateButtonClick();
              return;
            }
            setStep(stepDefinitions[stepIndex + 1].id);
          }}
          disabled={!name}
          loading={loading}
          intent="primary"
          weight="filled"
          size="large"
        />
        {stepIndex > 0 && (
          <Button
            text="Back"
            size="large"
            onClick={() => setStep(stepDefinitions[stepIndex - 1].id)}
          />
        )}
      </div>
    </Modal>
  );
}

function Step1ChooseName({
  name,
  message,
  setName,
  onNext,
}: {
  name: string;
  setName: (newName: string) => void;
  message: string;
  onNext?: () => void;
}): React.ReactElement | null {
  return (
    <>
      <ConditionalText text={message} className="mb-6" />
      <div className="mb-2 text-sm font-semibold text-tx-default">
        Team name
      </div>
      <TextInput
        style={{ marginBottom: 12, maxWidth: 450 }}
        value={name}
        trimOnBlur
        focusOnMount
        placeholder="Enter a name for this team"
        readOnly={false}
        onChange={setName}
        onEnter={onNext}
      />
    </>
  );
}

function Step2TransferProjects({
  transferProjects,
  setTransferProjects,
  message,
}: {
  transferProjects: (LabeledOption<string> | null)[];
  setTransferProjects: (
    newTransferProjects: (LabeledOption<string> | null)[]
  ) => void;
  message: string;
}): React.ReactElement | null {
  const { data, loading } = useBusinessesQuery();
  const options: LabeledOption<string>[] =
    data && data.primaryBusiness
      ? data.primaryBusiness.business.projects.map((project) => ({
          value: project.id,
          label: project.name,
        }))
      : [];
  const numProjects = data?.primaryBusiness?.business.projects.length ?? 0;

  return (
    <>
      <ConditionalText text={message} className="mb-6" />
      {transferProjects.map((selectedOption, index) => (
        <div className="mb-3 flex flex-row items-center gap-2">
          <TopBarDropdown<string>
            value={selectedOption}
            placeholder={
              index === 0 ? "Select a project" : "Select another project"
            }
            options={{
              type: "options",
              options: options.filter(
                (option) =>
                  !transferProjects.some(
                    (opt) =>
                      opt?.value !== selectedOption?.value &&
                      opt?.value === option.value
                  )
              ),
            }}
            isLoading={loading}
            onChange={(option) => {
              if (!option) {
                return;
              }
              setTransferProjects([
                ...transferProjects.slice(0, index),
                option,
                ...transferProjects.slice(index + 1),
                ...(index === transferProjects.length - 1 &&
                numProjects > transferProjects.length
                  ? [null]
                  : []),
              ]);
            }}
            dropdownStyle={{
              minWidth: 450,
              sectionMaxHeight: 151,
              buttonClassName: "border max-w-[450px]",
            }}
          />
          {selectedOption !== null && (
            <DeleteButton
              size="large"
              onClick={() =>
                setTransferProjects([
                  ...transferProjects.slice(0, index),
                  ...transferProjects.slice(index + 1),
                  ...(transferProjects.filter(Boolean).length === numProjects
                    ? [null]
                    : []),
                ])
              }
            />
          )}
        </div>
      ))}
    </>
  );
}

function Step3SendInvites({
  businessInviteToken,
  disallowedDomains,
  autoJoinDomains,
  setAutoJoinDomains,
  inviteEmails,
  setInviteEmails,
  isLoading,
  message,
  onNext,
}: {
  businessInviteToken: string;
  disallowedDomains: Set<string>;
  autoJoinDomains: string[];
  setAutoJoinDomains: (newDomains: string[]) => void;
  inviteEmails: string[];
  setInviteEmails: (newInviteEmails: string[]) => void;
  isLoading: boolean;
  message: string;
  onNext?: () => void;
}): React.ReactElement | null {
  return (
    <>
      <ConditionalText text={message} className="mb-6" />
      <Label type="title3" className="mb-2 text-tx-default">
        Invite link
      </Label>
      <div className="mb-6">
        <CopyableText text={businessInviteLinkURL(businessInviteToken)} />
      </div>
      <Label type="title3" className="mb-2 text-tx-default">
        Email addresses
      </Label>
      <EmailListInput
        emails={inviteEmails}
        setEmails={setInviteEmails}
        isLoading={isLoading}
        inputStyle={{ maxWidth: 450 }}
        onEnter={onNext}
      />
      <Label type="title3" className="mb-2 mt-6 text-tx-default">
        Auto-join domains
      </Label>
      <DomainListInput
        disallowedDomains={disallowedDomains}
        domains={autoJoinDomains}
        setDomains={setAutoJoinDomains}
        isLoading={isLoading}
        allowEmpty={false}
        inputStyle={{ maxWidth: 450 }}
        onEnter={onNext}
      />
    </>
  );
}

function Step4Review({
  name,
  transferProjects,
  autoJoinDomains,
  inviteEmails,
  canTransferProjects,
}: {
  name: string;
  transferProjects: LabeledOption<string>[];
  autoJoinDomains: string[];
  inviteEmails: string[];
  canTransferProjects: boolean;
}): React.ReactElement | null {
  const labelClassName = "text-tx-default mb-2";
  const mutedLabelClassName = "text-tx-muted";
  const listItemClassName = "text-base text-tx-default ml-4 mb-2 last:mb-0";

  return (
    <div className="flex flex-col gap-4">
      <div>
        <Label type="title3" className="mb-2 text-tx-default">
          Team name
        </Label>
        <p className="text-base text-tx-default">{name}</p>
      </div>
      {canTransferProjects && (
        <div>
          {transferProjects.length > 0 ? (
            <>
              <Label type="title3" className={labelClassName}>
                Projects to transfer
              </Label>
              <ul className="list-disc">
                {transferProjects.map((projectOption) => (
                  <li className={listItemClassName}>{projectOption.label}</li>
                ))}
              </ul>
            </>
          ) : (
            <Label type="title3" className={mutedLabelClassName}>
              No projects to transfer
            </Label>
          )}
        </div>
      )}
      <div>
        {autoJoinDomains.length > 0 ? (
          <>
            <Label type="title3" className={labelClassName}>
              Auto-join domains
            </Label>
            <ul className="list-disc">
              {autoJoinDomains.map((domain) => (
                <li className={listItemClassName}>{domain}</li>
              ))}
            </ul>
          </>
        ) : (
          <Label type="title3" className={mutedLabelClassName}>
            No auto-join domains
          </Label>
        )}
      </div>
      <div>
        {inviteEmails.length > 0 ? (
          <>
            <Label type="title3" className={labelClassName}>
              Invites to send
            </Label>
            <ul className="list-disc">
              {inviteEmails.map((email) => (
                <li className={listItemClassName}>{email}</li>
              ))}
            </ul>
          </>
        ) : (
          <Label type="title3" className={mutedLabelClassName}>
            No email invites to send
          </Label>
        )}
      </div>
    </div>
  );
}
