import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { isValidEmail } from "@hypertune/shared-internal";
import toStartCase from "@hypertune/sdk/src/shared/helpers/toStartCase";
import { onUpdateProfile } from "../../../lib/query/auth";
import trackAnalyticsEvent from "../../../lib/trackAnalyticsEvent";
import ContainerWithLogo from "../../../components/container/ContainerWithLogo";
import {
  getEmailFromLocation,
  locationStateWithEmail,
} from "../../../lib/query/locationState";
import Button from "../../../components/buttons/Button";
import TextInput from "../../../components/input/TextInput";
import {
  navyBlueHex,
  privacyPolicyUrl,
  termsAndConditionsUrl,
} from "../../../lib/constants";
import GoogleLogin from "../GoogleLogin";
import Divider from "../Divider";
import useLoginRedirectQuery from "../../../lib/hooks/useLoginRedirectQuery";
import useFirebaseErrorMessage from "../useFirebaseErrorMessage";
import { useHypertune } from "../../../generated/hypertune.react";

export default function RegisterPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Register - Hypertune";
  }, []);

  const auth = getAuth();
  const location = useLocation();
  const hypertune = useHypertune();
  const redirect = useLoginRedirectQuery();

  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const { errorMessage, setError, resetError } = useFirebaseErrorMessage();
  const [fullName, setFullName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>(
    getEmailFromLocation(location)
  );
  const [password, setPassword] = React.useState<string>("");

  useEffect(() => {
    if (auth.currentUser && !inProgress) {
      redirect().catch((error) =>
        console.error("Login redirect failed", error)
      );
    }
  });

  useEffect(() => {
    trackAnalyticsEvent("register_page_view");
  }, []);

  const isValid =
    isValidEmail(email) && fullName.trim() !== "" && password !== "";

  const fullNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  async function onSubmit(): Promise<void> {
    if (inProgress || !isValid) {
      return;
    }
    setInProgress(true);
    resetError();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      hypertune.events().signedUp();

      const { user } = userCredential;
      await updateProfile(user, { displayName: toStartCase(fullName.trim()) });
      await onUpdateProfile();
      await redirect();
    } catch (error) {
      setError("createUserWithEmailAndPassword / updateProfile", error);
      setInProgress(false);
    }
  }

  return (
    <ContainerWithLogo errorMessage={errorMessage}>
      <h1 className="mb-7 text-center text-h1 font-semibold text-base-dark-grey">
        Let's get you set up
      </h1>
      <GoogleLogin
        auth={auth}
        setError={setError}
        resetError={resetError}
        isRegister
      />
      <Divider />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
        onSubmit={async (event) => {
          event.preventDefault();
          if (!fullName) {
            fullNameRef.current?.focus();
            return;
          }
          if (!password) {
            passwordRef.current?.focus();
            return;
          }
          await onSubmit();
        }}
      >
        <TextInput
          size="large"
          style={{ marginBottom: 24, fontSize: 14 }}
          value={email}
          trimOnBlur
          focusOnMount
          readOnly={inProgress}
          label="Email address"
          labelVariant="large"
          placeholder="name@company.com"
          autoComplete="email"
          onChange={(newValue: string) => {
            setEmail(newValue.toLowerCase());
          }}
        />
        <TextInput
          elementRef={fullNameRef}
          size="large"
          textTransform="capitalize"
          style={{ marginBottom: 24, fontSize: 14 }}
          label="Full name"
          labelVariant="large"
          value={fullName}
          trimOnBlur
          focusOnMount={getEmailFromLocation(location) !== ""}
          readOnly={inProgress}
          autoComplete="name"
          onChange={(newValue: string) => {
            setFullName(newValue);
          }}
        />
        <TextInput
          elementRef={passwordRef}
          size="large"
          style={{ marginBottom: 24, fontSize: 14 }}
          label="Password"
          labelVariant="large"
          value={password}
          trimOnBlur
          isPassword
          readOnly={inProgress}
          autoComplete="new-password"
          onChange={(newValue: string) => {
            setPassword(newValue);
          }}
        />
        <input type="submit" hidden />
        <Button
          weight="filled"
          size="2x-large"
          intent="primary"
          disabled={!isValid}
          loading={inProgress}
          onClick={onSubmit}
          text="Get started"
        />
        <div className="mt-6 text-center">
          Have an account?{" "}
          <Link
            style={{ color: navyBlueHex }}
            to="/login"
            state={locationStateWithEmail(location, email)}
            replace
          >
            Sign in
          </Link>
          .
        </div>
        <p className="mt-6 text-center text-sm text-tx-disabled">
          By signing up, you agree to our{" "}
          <Link
            className="hover:underline"
            to={termsAndConditionsUrl}
            target="_blank"
          >
            terms of service
          </Link>{" "}
          and{" "}
          <Link
            className="hover:underline"
            to={privacyPolicyUrl}
            target="_blank"
          >
            privacy policy
          </Link>
          .
        </p>
      </form>
    </ContainerWithLogo>
  );
}
