import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Expression,
  SplitMap,
  EventTypeMap,
  Schema,
  stableStringify,
  hash as hashFn,
  asError,
  ValueType,
  ListValueType,
} from "@hypertune/sdk/src/shared";
import {
  FieldPosition,
  getFullSchemaCode,
  getSchemaWithDescriptions,
  reconcileSchemaAndEventTypeMap,
  splitSchemaCode,
} from "@hypertune/shared-internal";
import fixAndSimplify from "@hypertune/shared-internal/src/expression/fixAndSimplify";
import { getLogicErrorMessage } from "@hypertune/shared-internal/src/expression/getExpressionRecursiveErrorMessages";
import { getSplitsErrorMessage } from "@hypertune/shared-internal/src/expression/getSplitErrorMessage";
import {
  CommitContext,
  ExpressionEditorState,
  SelectedItem,
  TopLevelEnum,
} from "../../lib/types";
import getCommitData from "../../lib/query/getCommitData";
import { ActiveCommitQuery, ProjectBranchQuery } from "../../generated/graphql";
import { TypeOption } from "./schema/schemaHooks";
import { SplitTypeOption } from "./split/splitHooks";

export type ProjectState = {
  activeCommitHash?: string;
  activeCommitId?: string;
  draftCommit?: DraftCommit;
  draftCommitDerived: DraftCommitDerivedData;

  // Is saving is set to true when isActivating is set to true
  // and then it's set to false once new active commit is set.
  // This prevents the new commit available modal from appearing
  // after making a change.
  isSaving: boolean;
  isActivating: boolean;
  rebase?: RebaseState;

  showDetails: boolean;
  logicEditor: LogicEditorState;
  newTypeModal?: NewTypeModalState;
  newSplitModal?: NewSplitModalState;
  objectAddFieldModal?: ObjectAddFieldModalState;
  newVariableModal?: NewVariableModalState;
  listImportModalState?: ListImportModalState;
};

export type LogicEditorState = {
  expressionEditorState: ExpressionEditorState;
};

export type RebaseState = {
  branchName: string;
  commitMessage: string;
};

export type NewTypeModalState = {
  addFieldToObject?: {
    objectTypeName: string;
    valueTypes: ValueType[];
  };
  defaultTypeName?: string;
  defaultTypeOption?: TypeOption;
  sourceObjectTypeName?: string;
  onCreate?: (typeOption: TypeOption, newTypeName: string) => void;
};

export type NewSplitModalState = {
  defaultSplitTypeOption?: SplitTypeOption;
  onCreate?: (splitOption: SplitTypeOption, newSplitId: string) => void;
};

export type ObjectAddFieldModalState = {
  objectTypeName: string;
  fieldPosition?: FieldPosition;
  entity:
    | { name: "field" }
    | { name: "logicField"; parentFieldPath: string }
    | { name: "flag"; parentFieldPath: string }
    | { name: "test"; parentFieldPath: string };
};

export type NewVariableModalState = {
  defaultFieldPath: string[];
};

export type ListImportModalState = {
  listValueType: ListValueType;
  addItems: (newItems: (Expression | null)[]) => void;
  meId: string;
  commitContext: CommitContext;
};

export type DraftCommit = {
  schema: Schema;
  schemaCode: string;
  readOnlySchemaCode: string;
  editableSchemaCode: string;

  expression: Expression;
  splits: SplitMap;
  eventTypes: EventTypeMap;
};

export type DraftCommitDerivedData = {
  hasChanges: boolean;
  logicError?: string;
  splitsError?: string;
  schemaCodeError?: string;
};

export type FullDraftCommitDerivedData = DraftCommitDerivedData & {
  topLevelEnum: TopLevelEnum;
  defaultFieldPath: string[];
};

// Define the initial state using that type
const initialState: ProjectState = {
  draftCommitDerived: {
    hasChanges: false,
  },
  isActivating: false,
  isSaving: false,
  showDetails: true,
  logicEditor: {
    expressionEditorState: {
      selectedItem: null,
      collapsedExpressionIds: {},
    },
  },
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    toggleShowDetails: (draftState) => {
      draftState.showDetails = !draftState.showDetails;
    },
    setObjectAddFieldModalState: (
      draftState,
      action: PayloadAction<ObjectAddFieldModalState | undefined>
    ) => {
      draftState.objectAddFieldModal = action.payload;
    },
    setNewTypeModalState: (
      draftState,
      action: PayloadAction<NewTypeModalState | undefined>
    ) => {
      draftState.newTypeModal = action.payload;
    },
    setNewSplitModalState: (
      draftState,
      action: PayloadAction<NewSplitModalState | undefined>
    ) => {
      draftState.newSplitModal = action.payload;
    },
    setNewVariableModalState: (
      draftState,
      action: PayloadAction<NewVariableModalState | undefined>
    ) => {
      draftState.newVariableModal = action.payload;
    },
    setListImportModalState: (
      draftState,
      action: PayloadAction<ListImportModalState | undefined>
    ) => {
      draftState.listImportModalState = action.payload;
    },
    setIsActivating: (draftState, action: PayloadAction<boolean>) => {
      draftState.isActivating = action.payload;
      if (action.payload) {
        draftState.isSaving = true;
      }
    },
    setActiveCommit: (
      draftState,
      action: PayloadAction<
        | ProjectBranchQuery["projectBranch"]["activeCommit"]
        | ActiveCommitQuery["projectBranch"]["activeCommit"]
        | undefined
      >
    ) => {
      draftState.activeCommitId = action.payload?.id;
      draftState.isSaving = false;
      draftState.rebase = undefined;
      const commitData = action.payload ? getCommitData(action.payload) : null;
      draftState.activeCommitHash = commitData
        ? commitHash(commitData)
        : undefined;

      if (!action.payload) {
        draftState.draftCommit = undefined;
        draftState.draftCommitDerived = { hasChanges: false };
        return;
      }
      if (commitData && !draftState.draftCommit) {
        // Getting TS2589 for some reason.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draftState.draftCommit = commitData;
        updateErrorMessages(draftState as ProjectState);
      }
      updateHasChanges(draftState as ProjectState);
    },
    setDraftCommit: (
      draftState,
      action: PayloadAction<DraftCommit | undefined>
    ) => {
      draftState.draftCommit = action.payload;
      draftState.rebase = undefined;
      draftState.draftCommitDerived.schemaCodeError = undefined;
      if (draftState.draftCommit) {
        try {
          getSchemaWithDescriptions(draftState.draftCommit.schemaCode);
        } catch (error) {
          draftState.draftCommitDerived.schemaCodeError =
            formatSchemaError(error);
        }
      }
      updateHasChanges(draftState as ProjectState);
      updateErrorMessages(draftState as ProjectState);
    },
    setDraftCommitSchemaAndExpression: (
      draftState,
      action: PayloadAction<{ schema: Schema; expression: Expression }>
    ) => {
      const { schema, expression } = action.payload;
      // Set expression first so that we call fix and simplify with the new schema.
      setDraftCommitField("expression", draftState as ProjectState, expression);
      setDraftCommitField("schema", draftState as ProjectState, schema);
    },
    setDraftCommitSplitsAndExpression: (
      draftState,
      action: PayloadAction<{ splits: SplitMap; expression: Expression }>
    ) => {
      const { splits, expression } = action.payload;
      setDraftCommitField("expression", draftState as ProjectState, expression);
      setDraftCommitField("splits", draftState as ProjectState, splits);
    },
    setDraftCommitSchemaSplitsAndEventTypes: (
      draftState,
      action: PayloadAction<{
        schema: Schema;
        splits: SplitMap;
        eventTypes: EventTypeMap;
      }>
    ) => {
      const { schema, splits, eventTypes } = action.payload;
      // Set event types first, so that we correctly reconcile schema events with the event type map.
      setDraftCommitField("eventTypes", draftState as ProjectState, eventTypes);
      setDraftCommitField("schema", draftState as ProjectState, schema);
      setDraftCommitField("splits", draftState as ProjectState, splits);
    },
    setDraftCommitSchemaExpressionAndEventTypes: (
      draftState,
      action: PayloadAction<{
        schema: Schema;
        expression: Expression;
        eventTypes: EventTypeMap;
      }>
    ) => {
      const { schema, expression, eventTypes } = action.payload;
      // Set expression first so that we call fix and simplify with the new schema.
      setDraftCommitField("eventTypes", draftState as ProjectState, eventTypes);
      setDraftCommitField("expression", draftState as ProjectState, expression);
      setDraftCommitField("schema", draftState as ProjectState, schema);
    },
    setDraftCommitSchema: (draftState, action: PayloadAction<Schema>) =>
      setDraftCommitField("schema", draftState as ProjectState, action.payload),
    formatDraftCommitSchemaCode: (draftState) => {
      if (!draftState.draftCommit) {
        return;
      }
      const { readOnlySchemaCode, editableSchemaCode } = splitSchemaCode(
        draftState.draftCommit.schema
      );
      draftState.draftCommit.readOnlySchemaCode = readOnlySchemaCode;
      draftState.draftCommit.editableSchemaCode = editableSchemaCode;
      draftState.draftCommitDerived.schemaCodeError = undefined;
    },
    setDraftCommitReadonlySchemaCode: (
      draftState,
      action: PayloadAction<string>
    ): void =>
      updateDraftCommitSchemaCode(
        "readOnlySchemaCode",
        draftState,
        action.payload
      ),
    setDraftCommitEditableSchemaCode: (
      draftState,
      action: PayloadAction<string>
    ): void =>
      updateDraftCommitSchemaCode(
        "editableSchemaCode",
        draftState,
        action.payload
      ),
    setDraftCommitExpression: (
      draftState,
      action: PayloadAction<Expression>
    ) => {
      const { newExpression } = fixAndSimplify(
        draftState.draftCommit?.schema as Schema,
        draftState.draftCommit?.splits as SplitMap,
        draftState.draftCommit?.eventTypes as EventTypeMap,
        null,
        action.payload
      );
      setDraftCommitField(
        "expression",
        draftState as ProjectState,
        newExpression
      );
    },
    setDraftCommitSplits: (draftState, action: PayloadAction<SplitMap>) =>
      setDraftCommitField("splits", draftState as ProjectState, action.payload),

    resetLogicEditorState: (draftState) => {
      draftState.logicEditor = initialState.logicEditor;
    },
    setLogicEditorExpressionEditorState: (
      draftState,
      action: PayloadAction<ExpressionEditorState>
    ) => {
      draftState.logicEditor.expressionEditorState = action.payload;
    },
    setLogicEditorExpressionEditorSelectedItem: (
      draftState,
      action: PayloadAction<SelectedItem | null>
    ) => {
      draftState.logicEditor.expressionEditorState.selectedItem =
        action.payload;
    },
    setRebaseState: (
      draftState,
      action: PayloadAction<RebaseState | undefined>
    ) => {
      draftState.rebase = action.payload;
    },
  },
});

export const {
  toggleShowDetails,
  setObjectAddFieldModalState,
  setNewTypeModalState,
  setNewSplitModalState,
  setNewVariableModalState,
  setListImportModalState,
  setIsActivating,
  setActiveCommit,
  setDraftCommit,
  setDraftCommitSchemaAndExpression,
  setDraftCommitSplitsAndExpression,
  setDraftCommitSchemaSplitsAndEventTypes,
  setDraftCommitSchemaExpressionAndEventTypes,
  setDraftCommitSchema,
  formatDraftCommitSchemaCode,
  setDraftCommitEditableSchemaCode,
  setDraftCommitReadonlySchemaCode,
  setDraftCommitExpression,
  setDraftCommitSplits,
  resetLogicEditorState,
  setLogicEditorExpressionEditorState,
  setLogicEditorExpressionEditorSelectedItem,
  setRebaseState,
} = projectSlice.actions;

export default projectSlice.reducer;

function updateDraftCommitSchemaCode<
  K extends "readOnlySchemaCode" | "editableSchemaCode",
>(key: K, draftState: ProjectState, value: DraftCommit[K]): void {
  if (!draftState.draftCommit) {
    return;
  }
  draftState.draftCommit[key] = value;

  const fullSchemaCode = getFullSchemaCode(
    draftState.draftCommit.readOnlySchemaCode,
    draftState.draftCommit.editableSchemaCode
  );
  setDraftCommitField("schemaCode", draftState as ProjectState, fullSchemaCode);
  try {
    const schema = getSchemaWithDescriptions(fullSchemaCode);
    setDraftCommitField("schema", draftState as ProjectState, schema, {
      updateSchemaCode: false,
    });
    draftState.draftCommitDerived.schemaCodeError = undefined;
  } catch (error) {
    draftState.draftCommitDerived.schemaCodeError = formatSchemaError(error);
  }
}

function setDraftCommitField<K extends keyof DraftCommit>(
  key: K,
  draftState: ProjectState,
  value: DraftCommit[K],
  { updateSchemaCode = true }: { updateSchemaCode?: boolean } = {}
): void {
  if (!draftState.draftCommit) {
    return;
  }
  draftState.draftCommit[key] = value;
  updateHasChanges(draftState);
  updateErrorMessages(draftState);

  if (key === "schema") {
    const { newSchema, newEventTypeMap } = reconcileSchemaAndEventTypeMap(
      draftState.draftCommit.schema,
      draftState.draftCommit.eventTypes,
      // This prevents adding extra event types when manually editing schema.
      { discardExtraEventTypesInTypeMap: true }
    );
    draftState.draftCommit.schema = newSchema;
    draftState.draftCommit.eventTypes = newEventTypeMap;

    if (updateSchemaCode) {
      // Update schema code when schema changes.
      draftState.draftCommitDerived.schemaCodeError = undefined;
      try {
        const { readOnlySchemaCode, editableSchemaCode } = splitSchemaCode(
          draftState.draftCommit.schema
        );
        draftState.draftCommit.readOnlySchemaCode = readOnlySchemaCode;
        draftState.draftCommit.editableSchemaCode = editableSchemaCode;
        draftState.draftCommit.schemaCode = getFullSchemaCode(
          readOnlySchemaCode,
          editableSchemaCode
        );
      } catch (error) {
        draftState.draftCommitDerived.schemaCodeError =
          formatSchemaError(error);
      }
    }
  }
  if (key === "schema" || key === "splits") {
    // Try to fix an simplify expression if possible when schema changes.
    const { newExpression, hasChanged } = fixAndSimplify(
      draftState.draftCommit.schema,
      draftState.draftCommit.splits,
      draftState.draftCommit.eventTypes,
      {
        boolean: false,
        int: 0,
        float: 0,
        string: "",
        complexTypes: true,
      },
      draftState.draftCommit.expression
    );
    if (hasChanged) {
      draftState.draftCommit.expression = newExpression;
      updateErrorMessages(draftState);
    }
    updateHasChanges(draftState);
  }
}

function updateErrorMessages(draftState: ProjectState): void {
  if (!draftState.draftCommit) {
    draftState.draftCommitDerived.logicError = undefined;
    draftState.draftCommitDerived.splitsError = undefined;
    draftState.draftCommitDerived.schemaCodeError = undefined;
    return;
  }
  draftState.draftCommitDerived.logicError =
    getLogicErrorMessage(
      draftState.draftCommit.schema,
      draftState.draftCommit.expression,
      draftState.draftCommit.splits
    ) ?? undefined;
  draftState.draftCommitDerived.splitsError =
    getSplitsErrorMessage(
      draftState.draftCommit.schema,
      draftState.draftCommit.splits
    ) ?? undefined;
}

function updateHasChanges(draftState: ProjectState): void {
  if (!draftState.activeCommitHash || !draftState.draftCommit) {
    draftState.draftCommitDerived.hasChanges = false;
    return;
  }
  const newHash = commitHash(draftState.draftCommit);

  draftState.draftCommitDerived.hasChanges =
    draftState.activeCommitHash !== newHash;
}

function commitHash(draftCommit: DraftCommit): string {
  return String(
    hashFn(
      stableStringify({
        // Only compare schema code so that we detect changes to field ordering.
        schemaCode: draftCommit.schemaCode,
        expression: draftCommit.expression,
        splits: draftCommit.splits,
        eventTypes: draftCommit.eventTypes,
      })
    )
  );
}

function formatSchemaError(error: any): string {
  const { message } = asError(error);
  return `Schema Error: ${message}`;
}
