import React from "react";
import { Intent } from "../intent";
import Spinner from "../icons/Spinner";
import twMerge from "../../lib/twMerge";

export type Size =
  | "x-small"
  | "small"
  | "default"
  | "large"
  | "x-large"
  | "2x-large";

export type Weight = "default" | "minimal" | "filled" | "outlined" | "elevated";

export default function Button({
  text,
  title,
  icon,
  iconEnd,
  intent = "neutral",
  weight = "default",
  size = "default",
  loading,
  loadingText,
  disabled,
  onClick,
  className,
  gap,
  children,
  isFocused,
}: {
  text?: string;
  title?: string;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
  intent?: Intent;
  weight?: Weight;
  size?: Size;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  gap?: string;
  children?: React.ReactNode | React.ReactNode[];
  isFocused?: boolean;
}): React.ReactElement {
  const isDisabled = disabled || loading;
  const showIcon = loading ? <Spinner intent={intent} weight={weight} /> : icon;
  const showText = loading && loadingText !== undefined ? loadingText : text;

  return (
    <button
      title={title || text}
      disabled={isDisabled}
      onClick={
        !isDisabled && onClick
          ? (event) => {
              event.stopPropagation();
              onClick();
            }
          : undefined
      }
      type="button"
      className={twMerge(
        `flex select-none items-center justify-center text-base font-medium leading-3 ${isDisabled ? "cursor-default opacity-50" : `cursor-pointer ${intentAndWeightEnabledClassName[intent][weight]}`} ${intentAndWeightClassName[intent][weight]} ${sizeToClassName(size)} ${isFocused ? "bg-bg-pressed" : ""} ${sizeToGap(size)}`,
        className || ""
      )}
    >
      <div
        className={`flex items-center justify-center ${gap ?? sizeToGap(size)}`}
      >
        {showIcon && <span>{showIcon}</span>}
        {showText && (
          <span className="font-inherit whitespace-nowrap">{showText}</span>
        )}
      </div>
      {children}
      {iconEnd && <span>{iconEnd}</span>}
    </button>
  );
}

export function sizeToIconSize(size: Size): number {
  switch (size) {
    case "x-small":
      return 12;
    case "small":
      return 12;
    case "large":
      return 16;
    case "x-large":
      return 16;
    case "2x-large":
      return 16;
    default:
      return 16;
  }
}

function sizeToGap(size: Size): string {
  switch (size) {
    case "x-small":
      return "gap-[4px]";
    case "small":
      return "gap-[5px]";
    case "large":
      return "gap-[7px]";
    case "x-large":
      return "gap-[9px]";
    case "2x-large":
      return "gap-[9px]";
    default:
      return "gap-[5px]";
  }
}

function sizeToClassName(size: Size): string {
  switch (size) {
    case "x-small":
      return "h-[20px] px-[3px] py-[4px] rounded-md";
    case "small":
      return "h-[26px] px-[5px] py-[7px] rounded-lg ";
    case "large":
      return "h-[32px] px-[8px] py-[8px] rounded-lg";
    case "x-large":
      return "h-[36px] px-[9px] py-[8px] rounded-lg";
    case "2x-large":
      return "h-[40px] px-[9px] py-[8px] rounded-lg";
    default:
      return "h-[30px] px-[7px] py-[7px] rounded-lg";
  }
}

const intentAndWeightClassName = {
  primary: {
    default: `text-intent-primary bg-transparent border border-transparent`,
    minimal: `text-intent-primary bg-intent-primary/5 border border-transparent`,
    filled: `text-white bg-intent-primary border border-transparent`,
    outlined: `text-intent-primary bg-white border border-intent-primary/10`,
    elevated: `text-intent-primary bg-white border border-intent-primary/20 shadow-button`,
  },
  neutral: {
    default: `text-intent-neutral bg-transparent border border-transparent`,
    minimal: `text-intent-neutral bg-intent-neutral/5 border border-transparent`,
    filled: `text-white bg-intent-neutral border border-transparent`,
    outlined: `text-intent-neutral bg-white border border-intent-neutral/10`,
    elevated: `text-intent-neutral bg-white border border-intent-neutral/20 shadow-button`,
  },
  warning: {
    default: `text-intent-warning bg-transparent border border-transparent`,
    minimal: `text-intent-warning bg-intent-warning/5 border border-transparent`,
    filled: `text-white bg-intent-warning border border-transparent`,
    outlined: `text-intent-warning bg-white border border-intent-warning/10`,
    elevated: `text-intent-warning bg-white border border-intent-warning/20 shadow-button`,
  },
  danger: {
    default: `text-intent-danger bg-transparent border border-transparent`,
    minimal: `text-intent-danger bg-intent-danger/5 border border-transparent`,
    filled: `text-white bg-intent-danger border border-transparent`,
    outlined: `text-intent-danger bg-white border border-intent-danger/10`,
    elevated: `text-intent-danger bg-white border border-intent-danger/20 shadow-button`,
  },
  success: {
    default: `text-intent-success bg-transparent border border-transparent`,
    minimal: `text-intent-success bg-intent-success/5 border border-transparent`,
    filled: `text-white bg-intent-success border border-transparent`,
    outlined: `text-intent-success bg-white border border-intent-success/10`,
    elevated: `text-intent-success bg-white border border-intent-success/20 shadow-button`,
  },
};

const intentAndWeightEnabledClassName = {
  primary: {
    default: `hover:bg-intent-primary/5 active:bg-intent-primary/10`,
    minimal: `hover:bg-intent-primary/10 active:bg-intent-primary/15`,
    filled: `hover:bg-intent-primary/90 active:bg-intent-primary/80`,
    outlined: `hover:bg-intent-primary/5 active:bg-intent-primary/10 active:border-intent-primary/40`,
    elevated: `hover:bg-intent-primary/5 active:bg-intent-primary/10 active:border-intent-primary/40`,
  },
  neutral: {
    default: `hover:bg-intent-neutral/5 active:bg-intent-neutral/10`,
    minimal: `hover:bg-intent-neutral/10 active:bg-intent-neutral/15`,
    filled: `hover:bg-intent-neutral/90 active:bg-intent-neutral/80`,
    outlined: `hover:bg-intent-neutral/5 active:bg-intent-neutral/10 active:border-intent-neutral/40`,
    elevated: `hover:bg-intent-neutral/5 active:bg-intent-neutral/10 active:border-intent-neutral/40`,
  },
  warning: {
    default: `hover:bg-intent-warning/5 active:bg-intent-warning/10`,
    minimal: `hover:bg-intent-warning/10 active:bg-intent-warning/15`,
    filled: `hover:bg-intent-warning/90 active:bg-intent-warning/80`,
    outlined: `hover:bg-intent-warning/5 active:bg-intent-warning/10 active:border-intent-warning/40`,
    elevated: `hover:bg-intent-warning/5 active:bg-intent-warning/10 active:border-intent-warning/40`,
  },
  danger: {
    default: `hover:bg-intent-danger/5 active:bg-intent-danger/10`,
    minimal: `hover:bg-intent-danger/10 active:bg-intent-danger/15`,
    filled: `hover:bg-intent-danger/90 active:bg-intent-danger/80`,
    outlined: `hover:bg-intent-danger/5 active:bg-intent-danger/10 active:border-intent-danger/40`,
    elevated: `hover:bg-intent-danger/5 active:bg-intent-danger/10 active:border-intent-danger/40`,
  },
  success: {
    default: `hover:bg-intent-success/5 active:bg-intent-success/10`,
    minimal: `hover:bg-intent-success/10 active:bg-intent-success/15`,
    filled: `hover:bg-intent-success/90 active:bg-intent-success/80`,
    outlined: `hover:bg-intent-success/5 active:bg-intent-success/10 active:border-intent-success/40`,
    elevated: `hover:bg-intent-success/5 active:bg-intent-success/10 active:border-intent-success/40`,
  },
};
