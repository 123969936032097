import { interFontFamily, mediumFontSize } from "../lib/constants";
import getTextWidth from "../lib/generic/getTextWidth";
import ErrorCircle from "./icons/ErrorCircle";
import {
  Intent,
  intentToBorderClassName,
  intentToTextColorName,
} from "./intent";

export default function Selector<T>({
  options,
  values,
  selectedValue,
  errorValues,
  style,
  buttonStyle,
  onSelectValue,
  intent = "primary",
}: {
  options: readonly React.ReactNode[];
  values: readonly T[];
  selectedValue: T;
  errorValues?: readonly T[];
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  onSelectValue: (value: T) => void;
  intent?: Intent;
}): React.ReactElement {
  return (
    <div
      className="flex min-w-0 flex-row border-b border-base-grey-1-medium"
      style={style}
    >
      {options.map((option, index) => {
        const isSelected = values[index] === selectedValue;
        const hasError = errorValues?.includes(values[index]) ?? false;
        return (
          <button
            type="button"
            className={`-mb-px flex flex-row items-center justify-center bg-transparent px-[12px] py-[8px] ${isSelected ? "flex-grow-2" : "flex-shrink-2 overflow-hidden"} ${isSelected ? intentToTextColorName(intent) : `text-base-grey-1-dark`} ${isSelected ? `border-b-[2px] ${intentToBorderClassName(intent)}` : `border-b-[1px] border-base-grey-1-medium`} `}
            style={buttonStyle}
            onClick={(): void => onSelectValue(values[index])}
          >
            <div
              className={`flex flex-row ${isSelected ? "pb-0" : "pb-px"} min-w-0`}
            >
              {hasError && (
                <ErrorCircle style={{ marginRight: 6, marginLeft: -5 }} />
              )}
              <div
                className={`block whitespace-nowrap ${
                  isSelected
                    ? ""
                    : "float-left overflow-hidden text-ellipsis break-words"
                }`}
                style={{
                  maxWidth: isSelected
                    ? "100%"
                    : getTextWidth(
                        interFontFamily,
                        mediumFontSize,
                        option?.toString() || ""
                      ),
                }}
              >
                {option}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
}
