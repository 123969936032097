import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { isValidEmail } from "@hypertune/shared-internal";
import { navyBlueHex } from "../../lib/constants";
import TextInput from "../../components/input/TextInput";
import ContainerWithLogo from "../../components/container/ContainerWithLogo";
import {
  locationStateWithEmail,
  getEmailFromLocation,
} from "../../lib/query/locationState";
import Button from "../../components/buttons/Button";
import { useHypertune } from "../../generated/hypertune.react";
import ModalWithContent from "../../components/ModalWithContent";

export default function PasswordResetPage(): React.ReactElement {
  useEffect(() => {
    document.title = "Forgot password - Hypertune";
  }, []);

  const auth = getAuth();
  const location = useLocation();
  const content = useHypertune().content().login().passwordReset();

  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState<string>(
    getEmailFromLocation(location)
  );

  const isValid = isValidEmail(email);

  async function onSubmit(): Promise<void> {
    if (!isValid || inProgress) {
      return;
    }
    setInProgress(true);
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error("sendPasswordResetEmail failed", error);
    }
    setInProgress(false);
    setShowModal(true);
  }

  return (
    <ContainerWithLogo>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
        onSubmit={async (event) => {
          event.preventDefault();
          await onSubmit();
        }}
      >
        <h1 className="text-center text-h1 font-semibold text-base-dark-grey">
          {content.title({ fallback: "Reset your password" })}
        </h1>
        <h5 className="mb-7 mt-3 text-center text-h5 font-[400] text-tx-muted">
          {content.subtitle({
            fallback:
              "Enter your user account's email address and we will send you a password reset link.",
          })}
        </h5>
        <TextInput
          size="large"
          style={{ marginBottom: 24, fontSize: 14 }}
          value={email}
          trimOnBlur
          focusOnMount
          placeholder="Email address"
          readOnly={inProgress}
          autoComplete="email"
          onChange={(newValue: string) => {
            setEmail(newValue.toLowerCase());
          }}
        />
        {/* Allows for submitting the form on hitting enter (because then it triggers the form's onSubmit). This is better than listening to keydown events ourselves as it supports accessibility tools better and simplifies our input logic. */}
        <input type="submit" hidden />
        <Button
          weight="filled"
          size="2x-large"
          intent="primary"
          disabled={!isValid}
          text="Send password reset email"
          loading={inProgress}
          onClick={onSubmit}
        />
        <div className="mt-6 text-center">
          <Link
            style={{ color: navyBlueHex }}
            to="/register"
            state={locationStateWithEmail(location, email)}
            replace
          >
            Register
          </Link>
          {" · "}
          <Link
            style={{ color: navyBlueHex }}
            to="/login"
            state={locationStateWithEmail(location, email)}
            replace
          >
            Sign in
          </Link>
        </div>
      </form>
      {showModal && (
        <ModalWithContent
          content={content.successModal().get()}
          onSave={() => {
            setShowModal(false);
            setEmail("");
          }}
        />
      )}
    </ContainerWithLogo>
  );
}
