import { useEffect, useRef } from "react";
import { twJoin } from "tailwind-merge";
import { Expression, IntExpression } from "@hypertune/sdk/src/shared";

import {
  optionsButtonRight,
  optionsButtonTop,
} from "../../../../lib/constants";
import { ExpressionControlContext } from "../../../../lib/types";
import isReadOnly from "../../../../lib/expression/isReadOnly";
import {
  intentToBorderClassName,
  intentToLightBgClassName,
} from "../../../../components/intent";

export default function IntExpressionControl({
  context,
  expression,
  setExpression,
  shouldStack,
  optionsButton,
  disablePanelOnSelect,
  dragHandle,
}: {
  context: ExpressionControlContext;
  expression: IntExpression;
  setExpression: (newExpression: Expression | null) => void;
  shouldStack: boolean;
  optionsButton: React.ReactNode;
  disablePanelOnSelect?: boolean;
  dragHandle?: React.ReactNode;
}): React.ReactElement {
  const readOnly = isReadOnly(context);
  const intent = context.expressionIdToIntent?.[expression.id] ?? "neutral";

  const input = useRef<HTMLInputElement>(null);

  function select(): void {
    const el = input.current;
    if (!el) {
      return;
    }
    el.select();
  }

  const { expressionEditorState } = context;

  const isExpressionSelected =
    expressionEditorState.selectedItem &&
    expressionEditorState.selectedItem.type === "expression" &&
    expressionEditorState.selectedItem.id === expression.id;
  useEffect(() => {
    if (isExpressionSelected) {
      select();
    }
  }, [isExpressionSelected]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        position: "relative",
      }}
      onMouseDown={(event) => {
        context.setExpressionEditorState({
          ...context.expressionEditorState,
          selectedItem: { type: "expression", id: expression.id },
        });
        event.stopPropagation();
      }}
    >
      <input
        ref={input}
        type="number"
        className={twJoin(
          "m-0 flex-grow border py-1 pl-2 pr-8 outline-none",
          shouldStack ? "rounded-b-md" : "rounded-md",
          intent !== "neutral"
            ? [
                intentToBorderClassName(intent),
                intentToLightBgClassName(intent),
              ]
            : [
                !disablePanelOnSelect ? "hover:border-intent-primary" : "",
                isExpressionSelected && !disablePanelOnSelect
                  ? "border-intent-primary shadow-inputs"
                  : "border-bd-darker",
                readOnly ? "bg-bg-light" : "bg-white",
                dragHandle && "pl-[27.5px]",
              ]
        )}
        placeholder="Enter number here"
        value={Number.isNaN(expression.value) ? "" : expression.value}
        readOnly={readOnly}
        onKeyDown={(event) => {
          if ([".", "e", " "].includes(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={(event) =>
          setExpression({
            ...expression,
            value: parseInt(event.target.value, 10),
          })
        }
        onClick={() => {
          if (readOnly) {
            select();
          }
        }}
        onWheelCapture={(event) => event.currentTarget.blur()}
      />
      {dragHandle ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: optionsButtonRight / 2,
          }}
        >
          <div className="-my-[12.5px]">{dragHandle}</div>
        </div>
      ) : null}
      {optionsButton ? (
        <div
          style={{
            position: "absolute",
            top: optionsButtonTop,
            right: optionsButtonRight,
          }}
        >
          {optionsButton}
        </div>
      ) : null}
    </div>
  );
}
