import React, { useEffect, useState } from "react";
import { DownloadSimple, UserPlus } from "@phosphor-icons/react";
import { ProjectTokenMapWithMeta, toInt } from "@hypertune/shared-internal";
import { defaultBranchName, nullThrows } from "@hypertune/sdk/src/shared";
import CopyableText from "../../../components/CopyableText";
import WebhookSettingsEditor from "./WebhookSettingsEditor";
import MutableText from "../../../components/input/MutableText";
import {
  BusinessesDocument,
  ProjectDocument,
  useBusinessesQuery,
  useProjectBranchQuery,
  useProjectExportUrlMutation,
  useUpdateProjectMutation,
} from "../../../generated/graphql";
import env from "../../../environment";
import VercelEdgeConfig from "../../business/vercel/VercelEdgeConfig";
import CenteredContainer, {
  CenteredContainerHeader,
} from "../../../components/container/CenteredContainer";
import CardGroup from "../../../components/CardGroup";
import Button from "../../../components/buttons/Button";
import { intentPrimaryHex, whiteHex } from "../../../lib/constants";
import NewBusinessModal from "../../business/team/NewBusinessModal";
import { getAllToken } from "../../../lib/tokens";
import TokensEditor from "./TokensEditor";
import { useHypertune } from "../../../generated/hypertune.react";
import CommitSelector from "../controls/CommitSelector";
import Label from "../../../components/Label";

export default function SettingsEditor({
  isPersonal,
  projectName,
  isVisible,
  projectId,
  projectTokens,
  canEdit,
}: {
  isPersonal: boolean;
  projectName: string;
  isVisible: boolean;
  projectId: string;
  projectTokens: ProjectTokenMapWithMeta;
  canEdit: boolean;
}): React.ReactElement {
  const hypertune = useHypertune();
  const [updateProject] = useUpdateProjectMutation({
    refetchQueries: [BusinessesDocument, ProjectDocument],
    awaitRefetchQueries: true,
  });
  const { data } = useBusinessesQuery();
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);

  const allToken = getAllToken(projectTokens) ?? "YOUR_ADMIN_PROJECT_TOKEN";

  return (
    <CenteredContainer hidden={!isVisible}>
      <CenteredContainerHeader title="Project settings">
        {isPersonal && canEdit && (
          <>
            <Button
              intent="primary"
              weight="filled"
              icon={<UserPlus color={whiteHex} weight="regular" />}
              text="Share"
              onClick={() => setShowNewBusinessModal(true)}
            />
            {showNewBusinessModal && (
              <NewBusinessModal
                initialTransferProjects={[{ id: projectId, name: projectName }]}
                onClose={() => setShowNewBusinessModal(false)}
              />
            )}
          </>
        )}
      </CenteredContainerHeader>

      <CardGroup
        layout="list"
        cardLayout="horizontal"
        cards={[
          {
            key: "name",
            className: "items-start",
            children: (
              <>
                <div style={{ fontWeight: "bold" }}>Project name</div>
                <div className="flex flex-row justify-between">
                  <MutableText
                    text={projectName}
                    setText={async (newName) => {
                      try {
                        await updateProject({
                          variables: {
                            input: { id: projectId, name: newName },
                          },
                        });
                      } catch (error) {
                        console.error("updateProject error:", error);
                      }
                    }}
                    showPencil
                  />
                </div>
              </>
            ),
          },
          {
            key: "endpoint",
            className: "items-start",
            children: (
              <>
                <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                  GraphQL endpoint
                </div>
                <div>
                  <CopyableText
                    style={{ width: 390 }}
                    text={`${env.EDGE_CDN_BASE_URL}/graphql`}
                  />
                </div>
              </>
            ),
          },
          {
            key: "schema",
            className: "items-start",
            children: (
              <>
                <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                  GraphQL query schema
                </div>
                <div>
                  <CopyableText
                    style={{ width: 390 }}
                    text={`${
                      env.EDGE_CDN_BASE_URL
                    }/schema?token=${encodeURIComponent(allToken)}`}
                  />
                </div>
              </>
            ),
          },
          {
            key: "initialization",
            className: "items-start",
            children: (
              <>
                <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                  GraphQL initialization query schema
                </div>
                <div>
                  <CopyableText
                    style={{ width: 390 }}
                    text={`${
                      env.EDGE_CDN_BASE_URL
                    }/schema?body={"optionalInputTypes":true}&token=${encodeURIComponent(
                      allToken
                    )}`}
                  />
                </div>
              </>
            ),
          },
          ...(hypertune.enableProjectCommitExportAndDeletion({
            fallback: false,
          })
            ? [
                {
                  key: "initialization",
                  className: "items-start",
                  children: (
                    <>
                      <div style={{ marginBottom: 8, fontWeight: "bold" }}>
                        Data export
                      </div>
                      <div>
                        <ExportButton projectId={projectId} />
                      </div>
                    </>
                  ),
                },
              ]
            : []),
        ]}
      />

      <TokensEditor
        projectId={projectId}
        projectTokens={projectTokens}
        canEdit={canEdit}
      />

      <WebhookSettingsEditor projectId={projectId} canEdit={canEdit} />

      {data && data.primaryBusiness && (
        <VercelEdgeConfig projectId={projectId} data={data.primaryBusiness} />
      )}
    </CenteredContainer>
  );
}

SettingsEditor.LoadingSkeleton = function LoadingSkeleton({
  projectId,
}: {
  projectId: string;
}): React.ReactElement {
  return (
    <CenteredContainer>
      <CenteredContainerHeader title="Project settings" />
      <CardGroup
        layout="list"
        cardLayout="horizontal"
        loadingCount={5}
        cards={[]}
      />
      <TokensEditor.LoadingSkeleton />
      <WebhookSettingsEditor.LoadingSkeleton />
      <VercelEdgeConfig.LoadingSkeleton projectId={projectId} />
    </CenteredContainer>
  );
};

function ExportButton({
  projectId,
}: {
  projectId: string;
}): React.ReactElement | null {
  const { data } = useProjectBranchQuery({
    variables: { projectId, branchName: defaultBranchName },
  });
  const [getUrl] = useProjectExportUrlMutation();
  const [fromCommitId, setFromCommitId] = useState<number | null>(null);
  const [toCommitId, setToCommitId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const minCommitId = data
    ? toInt(
        data.projectBranch.commits[data.projectBranch.commits.length - 1].id
      )
    : null;
  const maxCommitId = data ? toInt(data.projectBranch.commits[0].id) : null;

  useEffect(() => {
    if (!fromCommitId) {
      setFromCommitId(minCommitId);
    }
    if (!toCommitId) {
      setToCommitId(maxCommitId);
    }
  }, [minCommitId, maxCommitId, fromCommitId, toCommitId]);

  if (!data || !minCommitId || !maxCommitId) {
    return null;
  }

  return (
    <div className="flex flex-col items-end gap-3">
      <div className="flex flex-row gap-3">
        <div>
          <Label type="title3" className="mb-1">
            From commit
          </Label>
          <CommitSelector
            muted={false}
            includeDraft={false}
            selectedCommitId={fromCommitId?.toString() ?? null}
            setSelectedCommitId={(newCommitId: string) => {
              const newFromCommitId = toInt(newCommitId);
              if (toCommitId && newFromCommitId > toCommitId) {
                setToCommitId(maxCommitId);
              }
              setFromCommitId(newFromCommitId);
            }}
            branch={data.projectBranch}
            buttonClassName="border"
          />
        </div>
        <div>
          <Label type="title3" className="mb-1">
            To commit
          </Label>
          <CommitSelector
            muted={false}
            includeDraft={false}
            selectedCommitId={toCommitId?.toString() ?? null}
            setSelectedCommitId={(newCommitId: string) => {
              const newToCommitId = toInt(newCommitId);
              if (fromCommitId && newToCommitId < fromCommitId) {
                setFromCommitId(minCommitId);
              }
              setToCommitId(newToCommitId);
            }}
            branch={data.projectBranch}
            buttonClassName="border"
          />
        </div>
      </div>
      <Button
        icon={<DownloadSimple color={intentPrimaryHex} weight="regular" />}
        text="Export commits"
        intent="primary"
        weight="outlined"
        className="h-[35px]"
        loading={loading}
        onClick={async () => {
          setLoading(true);

          try {
            const response = await getUrl({
              variables: {
                input: {
                  projectId,
                  fromCommitId: fromCommitId ?? minCommitId,
                  toCommitId: toCommitId ?? maxCommitId,
                },
              },
            });
            const href = nullThrows(
              response.data?.projectExportUrl,
              "No export URL."
            );

            const link = document.createElement("a");
            link.href = href;
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
          } catch (error) {
            console.error("Failed to export project data.", { error });
          } finally {
            setTimeout(() => setLoading(false), 1_000);
          }
        }}
      />
    </div>
  );
}
