import { ReactElement, useCallback, useMemo, useState } from "react";
import { X, Plus } from "@phosphor-icons/react";
import { Expression, Schema } from "@hypertune/sdk/src/shared";
import { useNavigate } from "react-router-dom";
import TopBarDropdown, { LabeledOption } from "./TopBarDropdown";
import Button from "./buttons/Button";
import getLabeledOption from "../lib/getLabeledOption";
import ModalWithContent from "./ModalWithContent";
import { useHypertune } from "../generated/hypertune.react";
import { mutedGreyHex } from "../lib/constants";
import { DetailContainer } from "./Details";
import { useAppDispatch } from "../app/hooks";
import { setDraftCommitSchema } from "../features/project/projectSlice";

export default function Tags({
  readOnly,
  schema,
  expression,
  setExpression,
}: {
  readOnly: boolean;
  schema: Schema;
  expression?: Expression;
  setExpression?: (newExpression: Expression | null) => void;
}): ReactElement {
  const { tags } = schema;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hypertune = useHypertune();
  const tagsEnabled = hypertune.features().tagsEnabled({ fallback: false });
  const plans = hypertune.content().plans();

  const createTagDefinition = useCallback(
    (newTagName: string, newTagColor: string) => {
      const color =
        Object.values(hypertune.colorToHexMap())
          .find(
            (colorNode) => colorNode.name({ fallback: "grey" }) === newTagColor
          )
          ?.hex({ fallback: mutedGreyHex }) ?? mutedGreyHex;

      dispatch(
        setDraftCommitSchema({
          ...schema,
          tags: {
            ...schema.tags,
            [newTagName]: {
              color,
              name: newTagName,
            },
          },
        })
      );
    },
    [dispatch, hypertune, schema]
  );
  const setExpressionTags = useCallback(
    (newTags: { [tagName: string]: true }) => {
      if (setExpression && expression) {
        setExpression({
          ...expression,
          metadata: {
            ...expression.metadata,
            tags: newTags,
          },
        });
      }
    },
    [expression, setExpression]
  );

  const selectedTags = useMemo(() => {
    if (expression?.metadata?.tags && tags) {
      return Object.keys(expression?.metadata.tags).reduce(
        (acc: { [tagName: string]: LabeledOption<string> }, tagName) => {
          acc[tagName] = getLabeledOption(
            tagName,
            tags[tagName]?.color ?? mutedGreyHex,
            true,
            true
          );
          return acc;
        },
        {}
      );
    }
    return {};
  }, [expression?.metadata?.tags, tags]);

  const allOptions = useMemo(() => {
    if (tags) {
      return Object.values(tags).map((tag) => {
        return getLabeledOption(
          tag.name,
          tag.color,
          true,
          !!selectedTags[tag.name]
        );
      });
    }

    return [];
  }, [selectedTags, tags]);

  const tagColorOptions: LabeledOption<string>[] = useMemo(
    () =>
      hypertune.colorToHexMap().map((colorNode) => {
        return getLabeledOption(
          colorNode.name({ fallback: "grey" }),
          colorNode.hex({ fallback: mutedGreyHex }),
          true,
          false
        );
      }),
    [hypertune]
  );

  const [newTagName, setNewTagName] = useState<string>();
  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState<boolean>();

  return (
    <DetailContainer title="Labels">
      <div className="!hover:bg-white flex w-full flex-wrap gap-[6px]">
        {!tagsEnabled ? (
          <>
            <Button
              className="-ml-[7px] h-[26px] min-w-[26px] max-w-fit px-[5px] font-medium text-tx-muted"
              onClick={() => setShowPlanUpgradeModal(true)}
              icon={<Plus className="h-4 w-4" weight="regular" />}
              disabled={readOnly}
            >
              Add labels...
            </Button>
            {showPlanUpgradeModal && (
              <ModalWithContent
                content={plans.createTagsUpgradeModal().get()}
                onSave={() => navigate("/plans")}
                onClose={() => setShowPlanUpgradeModal(false)}
                saveWeight="filled"
              />
            )}
          </>
        ) : (
          <>
            {Object.values(selectedTags).map((selectedTag) => {
              return (
                <div className="!hover:bg-white group">
                  <Button
                    intent="neutral"
                    weight="minimal"
                    text={selectedTag.label}
                    icon={selectedTag.icon}
                    className="border: solid border-bd-darker; !hover:bg-white group relative h-[26px] max-w-fit rounded-[10px] !bg-white p-[5px] pl-2 pr-2 text-tx-muted"
                    iconEnd={
                      !readOnly ? (
                        <div className="absolute -ml-6 -mt-[10px] hidden h-5 w-7 rounded-[5px] bg-light-gradient p-[5px] pl-[11px] group-hover:inline-block">
                          <div className="absolute m-auto -mt-[3px] rounded-[5px] bg-base-grey-1-medium bg-opacity-50 p-[2px]">
                            <X weight="bold" className="h-3 w-3" />
                          </div>
                        </div>
                      ) : undefined
                    }
                    gap="gap-[4.16px]"
                    onClick={
                      !readOnly
                        ? () => {
                            if (
                              expression?.metadata?.tags &&
                              selectedTag.value in expression.metadata.tags
                            ) {
                              const newTags = {
                                ...expression.metadata?.tags,
                              };
                              delete newTags[selectedTag.value];
                              setExpressionTags(newTags ?? {});
                            }
                          }
                        : undefined
                    }
                  />
                </div>
              );
            })}
            <div
              className={`h-[26px] min-w-[26px] max-w-fit ${Object.keys(selectedTags).length === 0 ? "-ml-[7px]" : ""}`}
            >
              {newTagName ? (
                <TopBarDropdown
                  readOnly={readOnly}
                  value={{
                    label: "",
                    value: "add-label-primary",
                    icon: <Plus className="h-4 w-4" weight="regular" />,
                    showIconWhenSelected: true,
                    isSelected: false,
                  }}
                  placeholder=""
                  options={{ type: "options", options: tagColorOptions }}
                  onChange={(newColorOption) => {
                    if (newColorOption !== null) {
                      createTagDefinition(newTagName, newColorOption.value);
                      setNewTagName("");
                      setExpressionTags({
                        ...expression?.metadata?.tags,
                        [newTagName]: true,
                      });
                    }
                  }}
                  onClose={() => {
                    setNewTagName("");
                  }}
                  dropdownStyle={{
                    hideSearch: false,
                    caret: null,
                    buttonClassName: `px-[5px] leading-none wrap-content disableBgWhenSelected ${Object.keys(selectedTags).length === 0 ? "-mb-[4px] py-[5px]" : ""}`,
                    panelClassName: "pt-1 data-top:pb-1 wrap-content",
                    muted: "all",
                  }}
                  searchInputPlaceholder="Pick a color..."
                  autoFocus
                  disableDebouncedSearch
                  multiSelect
                />
              ) : !readOnly ? (
                <TopBarDropdown
                  readOnly={readOnly}
                  value={
                    Object.keys(selectedTags).length > 0
                      ? {
                          label: "",
                          value: "add-label-secondary",
                          icon: <Plus className="h-4 w-4" weight="regular" />,
                          showIconWhenSelected: true,
                          isSelected: false,
                        }
                      : {
                          label: "Add labels...",
                          icon: (
                            <Plus
                              className="mr-[5px] h-4 w-4"
                              weight="regular"
                            />
                          ),
                          value: "add-label-primary",
                          showIconWhenSelected: true,
                          isSelected: false,
                        }
                  }
                  placeholder=""
                  options={{ type: "options", options: allOptions }}
                  onChange={(newOption) => {
                    if (newOption !== null) {
                      if (!(newOption.value in selectedTags)) {
                        setExpressionTags({
                          ...expression?.metadata?.tags,
                          [newOption.value]: true,
                        });
                      } else if (
                        expression?.metadata?.tags &&
                        newOption.value in expression.metadata.tags
                      ) {
                        const newTags = {
                          ...expression.metadata?.tags,
                        };
                        delete newTags[newOption.value];
                        setExpressionTags(newTags ?? {});
                      }
                    }
                  }}
                  onNoItemFound={(args) => {
                    if (args && args.length > 0) {
                      setNewTagName(args[0]);
                    }
                  }}
                  dropdownStyle={{
                    hideSearch: false,
                    caret: null,
                    buttonClassName: `px-[5px] leading-none font-medium disableBgWhenSelected text-tx-muted ${Object.keys(selectedTags).length === 0 ? "-mb-[4px] py-[5px]" : ""}`,
                    panelClassName:
                      "pt-1 data-top:pb-1 whitespace-nowrap min-w-fit",
                    muted: "all",
                  }}
                  searchInputPlaceholder="Add labels..."
                  autoFocus
                  disableDebouncedSearch
                />
              ) : null}
            </div>
          </>
        )}
      </div>
    </DetailContainer>
  );
}
