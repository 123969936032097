import React from "react";
import { Expression } from "@hypertune/sdk/src/shared";
import {
  ValueTypeConstraint,
  VariableMap,
} from "@hypertune/shared-internal/src/expression/types";
import {
  ExpressionControlContext,
  IncludeExpressionOptionFunction,
  LiftFunction,
  VariableContext,
} from "../../../../lib/types";
import ExpressionControl from "./ExpressionControl";
import twMerge from "../../../../lib/twMerge";

export default function ExpressionTree({
  context,
  variables,
  setVariableName,
  valueTypeConstraint,
  expression,
  setExpression,
  parentExpression,
  setParentExpression,
  lift,
  variableContext,
  includeExpressionOption,
  className,
}: {
  context: ExpressionControlContext;
  variables: VariableMap;
  setVariableName: { [variableId: string]: (newVariableName: string) => void };
  valueTypeConstraint: ValueTypeConstraint;
  expression: Expression | null;
  setExpression: (newExpression: Expression | null) => void;
  parentExpression: Expression | null;
  setParentExpression?: (newParentExpression: Expression | null) => void;
  lift: LiftFunction;
  variableContext?: VariableContext;
  includeExpressionOption: IncludeExpressionOptionFunction;
  className?: string;
}): React.ReactElement {
  return (
    <div
      className="flex justify-around"
      onMouseDown={(): void => {
        if (!context.expressionEditorState.selectedItem) {
          return;
        }
        context.setExpressionEditorState({
          ...context.expressionEditorState,
          selectedItem: null,
        });
      }}
    >
      <div
        // Nested element required for right-padding to work:
        // https://stackoverflow.com/questions/26888428/display-flex-loses-right-padding-when-overflowing
        className={twMerge("min-w-[350px] p-4", className)}
      >
        <ExpressionControl
          context={context}
          variables={variables}
          setVariableName={setVariableName}
          valueTypeConstraint={valueTypeConstraint}
          expression={expression}
          setExpression={setExpression}
          lift={lift}
          parentExpression={parentExpression}
          setParentExpression={setParentExpression}
          variableContext={variableContext}
          hideTopLevelFunctionName
          includeExpressionOption={includeExpressionOption}
          isLeafExpression
        />
      </div>
    </div>
  );
}
