import React from "react";
import { useNavigate } from "react-router-dom";
import { Plus } from "@phosphor-icons/react";
import NewProjectModal from "./NewProjectModal";
import Button from "../../../components/buttons/Button";
import { getProjectUrl } from "../../project/projectHooks";
import useSearchParamsState from "../../../app/useSearchParamsState";

const modalVisibleKey = "new_project";

export default function NewProjectButton({
  text,
  businessId,
}: {
  text?: string;
  businessId: string;
}): React.ReactElement {
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useSearchParamsState(
    modalVisibleKey,
    false
  );

  return (
    <>
      {isModalVisible ? (
        <NewProjectModal
          businessId={businessId}
          onCreate={(newProjectId) =>
            navigate(
              getProjectUrl({ projectId: newProjectId, selectedView: "logic" })
            )
          }
          onClose={() => {
            setIsModalVisible(false);
          }}
        />
      ) : null}
      <Button
        onClick={() => setIsModalVisible(true)}
        icon={<Plus weight="bold" color="white" />}
        text={text || "New"}
        intent="primary"
        weight="filled"
        size="large"
      />
    </>
  );
}
