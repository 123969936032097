import { rootObjectTypeName } from "@hypertune/shared-internal";
import { fieldPathSeparator } from "@hypertune/sdk/src/shared";
import { Plus } from "@phosphor-icons/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setObjectAddFieldModalState,
  setNewVariableModalState,
} from "../projectSlice";
import Tooltip from "../../../components/tooltips/Tooltip";
import { useHypertune } from "../../../generated/hypertune.react";
import Menu from "../../../components/Menu";
import Flag from "../../../components/icons/Flag";
import Variable from "../../../components/icons/Variable";
import TypeIcon from "../../../components/icons/TypeIcon";
import { Intent } from "../../../components/intent";

export default function NewFlagButton({
  defaultFieldPath,
}: {
  defaultFieldPath: string[];
}): React.ReactElement | null {
  const content = useHypertune().content();
  const dispatch = useAppDispatch();
  const showNewFlagModal = useShowNewFlagModal(defaultFieldPath);

  if (!showNewFlagModal) {
    return null;
  }

  return (
    <Tooltip id="logic" step={1} allSteps={content.logic().tooltips()}>
      <Menu
        button={<Plus weight="bold" size={12} />}
        items={[
          {
            title: "Flag",
            icon: <Flag size={12} />,
            intent: "neutral",
            onClick: () => showNewFlagModal(),
          },
          {
            title: "Variable",
            icon: <Variable size={12} />,
            intent: "neutral",
            onClick: () =>
              dispatch(setNewVariableModalState({ defaultFieldPath })),
          },
          {
            title: "Test",
            icon: <TypeIcon type="test" size="x-small" />,
            intent: "neutral" as Intent,
            onClick: () => showNewFlagModal(/* isTest */ true),
          },
        ]}
        className="h-[26px] w-[26px] border hover:bg-intent-neutral/10"
      />
    </Tooltip>
  );
}

export function useShowNewFlagModal(
  defaultFieldPath: string[]
): null | ((isTest?: boolean) => void) {
  const dispatch = useAppDispatch();
  const expression = useAppSelector(
    (state) => state.project.draftCommit?.expression
  );

  if (!expression) {
    return null;
  }
  return (isTest = false) =>
    dispatch(
      setObjectAddFieldModalState({
        objectTypeName: rootObjectTypeName,
        fieldPosition: "first",
        entity: {
          name: isTest ? "test" : "flag",
          parentFieldPath: defaultFieldPath.join(fieldPathSeparator),
        },
      })
    );
}
