import React from "react";

export default function ShortcutDefinition({
  textLeft,
  textRight,
  keys,
}: {
  textLeft?: string;
  textRight?: string;
  keys: React.ReactNode[];
}): React.ReactElement {
  return (
    <div className="flex flex-row items-center gap-1">
      {textLeft && <p className="mr-1">{textLeft}</p>}
      {keys.map((key, index) => (
        <KeyContainer key={`shortcut-def:${index.toString()}`}>
          {key as React.ReactElement}
        </KeyContainer>
      ))}
      {textRight && <p className="ml-1">{textRight}</p>}
    </div>
  );
}

export function KeyContainer({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  return (
    <div className="flex flex-row items-center rounded-md bg-base-grey-1-medium/50 px-[6px] py-1 text-[11px] leading-3 text-tx-muted">
      {children}
    </div>
  );
}
