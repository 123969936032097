import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { ApolloError } from "@apollo/client";
import {
  rootFieldName,
  vercelFlagPathSeparator,
} from "@hypertune/shared-internal";
import ContainerWithLogo from "../../components/container/ContainerWithLogo";
import { useVercelSsoMutation } from "../../generated/graphql";
import getErrorMessage from "../../lib/query/getErrorMessage";
import useLoginRedirectQuery from "../../lib/hooks/useLoginRedirectQuery";
import {
  selectedFieldPathParamKey,
  urlFieldPathSeparator,
} from "../project/logicHooks";

export default function VercelSsoPage(): React.ReactElement {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const projectId = searchParams.get("resource_id") || undefined;
  const experimentationItemId = searchParams.get("experimentation_item_id");
  const redirectSearchParams = getRedirectSearchParams(experimentationItemId);

  const [vercelSsoMutation, { error: apolloError }] = useVercelSsoMutation();

  const auth = getAuth();

  const redirect = useLoginRedirectQuery({
    projectId,
    searchParams: redirectSearchParams,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const login = useCallback(
    async function login(): Promise<void> {
      if (!code) {
        throw new Error("No code.");
      }

      const response = await vercelSsoMutation({
        variables: { input: { code } },
      });

      if (!response.data) {
        throw new Error("No response.");
      }

      const firebaseCustomToken = response.data?.vercelSso.firebaseCustomToken;

      await signInWithCustomToken(auth, firebaseCustomToken);
      await redirect();
    },
    [code, vercelSsoMutation, auth, redirect]
  );

  useEffect(() => {
    document.title = "Logging in with Vercel...";

    login().catch((loginError) => {
      setErrorMessage(getErrorMessage(loginError));
    });
  }, [login]);

  return (
    <ContainerWithLogo>
      <div style={{ textAlign: "center" }}>
        {getMessage(apolloError, errorMessage)}
      </div>
    </ContainerWithLogo>
  );
}

function getRedirectSearchParams(
  experimentationItemId: string | null
): URLSearchParams | undefined {
  if (!experimentationItemId) {
    return undefined;
  }

  const selectedFieldPath = `${rootFieldName}${urlFieldPathSeparator}${experimentationItemId.replaceAll(vercelFlagPathSeparator, urlFieldPathSeparator)}`;

  return new URLSearchParams({
    [selectedFieldPathParamKey]: selectedFieldPath,
  });
}

function getMessage(
  apolloError: ApolloError | undefined,
  errorMessage: string | null
): string {
  if (apolloError) {
    return getErrorMessage(apolloError);
  }

  if (errorMessage) {
    return errorMessage;
  }

  return "Logging in with Vercel...";
}
